import styled from 'styled-components';

import { theme } from '@gympass/yoga';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  margin-bottom: ${theme.spacing.medium}px;
  padding: ${theme.spacing.xxsmall}px ${theme.spacing.medium}px;
  background: #ffc382;
  border-radius: ${theme.radii.small}px;
  width: 100%;
`;
