import styled from 'styled-components';

import { theme } from '@gympass/yoga';
import media from 'theme/media';

import { Button } from 'components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--primary);

  ${media.desktop`
    max-width: 840px;
    max-height: 636px;
    height: 80vh;
    margin: ${theme.spacing.xlarge}px auto;
    overflow: hidden;
    border-radius: ${theme.spacing.small}px;
    box-shadow: ${theme.elevations.medium};
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 85vh;
  background-color: var(--primary);

  ${media.desktop`
    flex-direction: row;
    height: 100%;
  `}
`;

export const WrapperHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing.zero} ${theme.spacing.xlarge}px;

  ${media.desktop`
    justify-content: center;
  `}
`;

export const WrapperNavbar = styled.div<{ heightFull: boolean }>`
  align-self: flex-start;
  background-color: var(--primary);
  padding: ${theme.spacing.zero} ${theme.spacing.xlarge}px;
  width: 100%;
  ${props => props.heightFull && `height: 100vh;`}
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--primary);
  margin-top: ${theme.spacing.small}px;

  ${media.desktop`
    justify-content: flex-end;
    margin: ${theme.spacing.zero} ${theme.spacing.xsmall}px;
    padding: ${theme.spacing.large}px;
  `}
`;

export const FooterContainer = styled.div`
  background-color: var(--primary);
  position: fixed;
  left: ${theme.spacing.zero};
  bottom: ${theme.spacing.zero};
  width: 100%;

  ${media.desktop`
    position: relative;
  `}
`;

export const ButtonContent = styled(Button)`
  border-radius: ${theme.radii.circle}px;
  background-color: ${theme.colors.white};
  color: ${theme.colors.deepPurple};
  min-width: 200px;
  max-height: 48px;
`;
