export const COLETIVAMENTE_BANNER_URL =
  'https://saudeemocional.wellzcare.com/coletivamente/?utm_source=wellzcare_website&utm_medium=banner&utm_campaign=Coletivamente_release';
export const COLETIVAMENTE_BANNER_KEY = 'coletivamenteBannerCollapsed';
export const EMERGENCY_BANNER_KEY = 'emergencyBannerCollapsed';
export const TC_HOURS_CHAT_MODAL = 'tcHoursChatModal';
export const REASSESSMENT_MODAL_HOME_DISABLED = 'reassessmentModalHomeDisabled';
export const REASSESSMENT_NUDGE_HOME_DISABLED = 'reassessmentNudgeHomeDisabled';
export const VIMEO_URL = 'player.vimeo.com';
export const GET_STARTED_INFORMATION_BANNER = 'startedInformationBannerDate';
export const PATIENT_INTAKE_PHONE_MODAL = 'patientIntakePhoneCollectionModal';
export const MINIMUN_PASSWORD_STRENGTH_SCORE = 3;
export const ACCOUNT_DELETED = 'accountDeleted';
export const PUBLICS_URLS = [
  '/privacy-policy',
  '/terms-conditions',
  '/terms-payroll-deduction',
];
export const RESCUE_ME_CURRENT_STEP = 'reacueMeCurrentStep';
