import styled from 'styled-components';

import { Text, theme } from '@gympass/yoga';

export const Resend = styled(Text.Small)`
  color: var(--text-secondary);

  margin-top: ${theme.spacing.xsmall}px;
  margin-bottom: ${theme.spacing.small}px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: ${theme.spacing.xsmall}px;
`;
