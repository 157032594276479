import { CMS } from 'services/cms/types';

import { AudioTypes } from 'atoms/audio/types';

export function contentToAudio(content: CMS.Content): AudioTypes {
  return {
    ...content,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    audio_file: { url: content.medias![0].url },
    // @ts-expect-error content.id actually exists but we won't use anywhere else
    id: content.id,
    content: content.subtitle ?? '',
    recurring_task_execution_id: '',
    transcription_page: content.body
      ? {
          active: true,
          content: content.body,
          cover: content.cover,
          id: content.documentId,
        }
      : null,
    duration: content.duration ?? '',
    cover: { url: content.cover?.url ?? content.icon?.url ?? '' },
    page_type: (() => {
      if (content.type === 'meditation') {
        return 'meditation';
      }
      if (content.type === 'audio') {
        return 'audio';
      }
      // eslint-disable-next-line no-console
      console.error(
        'content.type should be audio or meditation but is',
        content.type,
      );
      return 'audio';
    })(),
  };
}
