import React from 'react';

import * as Styles from './style';

type LinkProps = {
  id?: string;
  tiny?: boolean;
  disabled?: boolean;
  onClick?: Function;
  as?:
    | keyof JSX.IntrinsicElements
    | React.ComponentType<Record<string, unknown>>;
};

const Link: React.FC<LinkProps> = props => <Styles.Link {...props} />;

export default Link;
