import styled from 'styled-components';

import { theme } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';

export const TcDischargeWarning = styled.div`
  min-height: 36px;
  width: auto;
  max-width: 808px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: ${theme.radii.small}px;
  background-color: var(--background-mellow);
  padding: ${theme.spacing.xxsmall}px ${theme.spacing.medium}px;
  margin: ${theme.spacing.small}px auto ${theme.spacing.xxlarge}px;
  ${media.not.md`
    padding: ${theme.spacing.xxsmall}px ${theme.spacing.small}px;
  `}
`;

export const WarningText = styled.p`
  font-size: ${theme.fontSizes.small}px;
  margin: 0px;
  line-height: ${theme.spacing.medium}px;
  ${media.not.md`
    text-align: center;
  `}
`;
