import styled from 'styled-components';

import { theme } from '@gympass/yoga';

import media from '../../theme/media';

interface ContainerProps {
  limitSpace?: boolean;
}

export const Container = styled.div<ContainerProps>`
  padding: ${theme.spacing.xxsmall}px 0
    ${props =>
      !props.limitSpace ? theme.spacing.xsmall : theme.spacing.xxxsmall}px
    0;

  ${({ limitSpace }) => media.desktop`
    padding: ${theme.spacing.xxsmall}px 0
    ${!limitSpace ? theme.spacing.large : theme.spacing.xxxsmall}px 0;
  `};

  & fieldset {
    background: var(--input-background);
  }

  & > div[role='combobox'] {
    ${media.desktop`
      & > div {
        height: 52px;
      }
    `}
  }
`;
