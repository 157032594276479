/* eslint-disable @typescript-eslint/no-explicit-any -- day zero of no errors, remove and fix me when you see me in a code review */
import styled from 'styled-components';

import { Progress, theme } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';

export const ProgressBar = styled(Progress)`
  margin-top: ${theme.spacing.xxsmall}px;

  ${media.not.md`
    ${({ responsive }: any) =>
      responsive && responsive.toString().replace(',', '')}}
  `}

  progress {
    width: ${({ sizeBar }) => (sizeBar ? sizeBar.width : 83)}px;
    height: ${({ sizeBar }) => (sizeBar ? sizeBar.height : 4)}px;

    ::-webkit-progress-value {
      background-color: ${({ isCompleted }) =>
        isCompleted ? 'var(--success)' : 'var(--primary-light)'};
    }

    ::-webkit-progress-bar {
      background-color: '#f7f3eb';
    }
    ::-moz-progress-bar {
      background-color: '#f7f3eb';
    }
  }

  label {
    font-size: ${theme.fontSizes.xxsmall}px;
    color: ${({ isCompleted }) =>
      isCompleted ? 'var(--success)' : theme.colors.deep};
  }
`;
