import React, { ReactNode, useMemo } from 'react';

import { QueryClientProvider } from '@tanstack/react-query';
import { createQueryClient } from 'utils/createQueryClient';

export const AppProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const queryClient = useMemo(() => createQueryClient(), []);

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
