import { css } from 'styled-components';

/**
 * width >= DESKTOP_BREAKPOINT ? desktop : notDesktop
 */
export const DESKTOP_BREAKPOINT = 1024;

const media = {
  // @ts-expect-error -- day zero of no errors, remove and fix me when you see me in a code review
  desktop: (...args) => css`
    @media (min-width: ${DESKTOP_BREAKPOINT}px) {
      ${
        // @ts-expect-error -- day zero of no errors, remove and fix me when you see me in a code review
        css(...args)
      };
    }
  `,
  // @ts-expect-error -- day zero of no errors, remove and fix me when you see me in a code review
  tablet: (...args) => css`
    @media (min-width: 768px) and (max-width: ${DESKTOP_BREAKPOINT}px) {
      ${
        // @ts-expect-error -- day zero of no errors, remove and fix me when you see me in a code review
        css(...args)
      };
    }
  `,
  // @ts-expect-error -- day zero of no errors, remove and fix me when you see me in a code review
  mobile: (...args) => css`
    @media (max-width: 768px) {
      ${
        // @ts-expect-error -- day zero of no errors, remove and fix me when you see me in a code review
        css(...args)
      };
    }
  `,
};

export default media;
