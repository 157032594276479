import styled from 'styled-components';

import { Text, theme } from '@gympass/yoga';

export const Container = styled.div`
  align-content: center;
  background-color: var(--background-darker);
  border-radius: 4px;
  color: var(--text-secondary);
  display: inline-flex;
  justify-content: center;
  padding: ${theme.spacing.xxsmall}px ${theme.spacing.small}px;
  text-align: center;
  align-items: center;
`;

/** @knipignore */ // TODO: day zero adding knip to this project, remove me
export const StyledText = styled(Text.Small)`
  color: var(--text-secondary);
`;
