import styled, { css } from 'styled-components';

import { Button as YogaButton } from '@gympass/yoga';

import media from '../../theme/media';

interface ButtonProps {
  md?: boolean;
  sm?: boolean;
}

const gutterIcon = css<{ disabled: boolean }>`
  ${props => props.disabled && '&:disabled svg { margin-right: initial }'}
`;

export const Button = styled(YogaButton)<ButtonProps>`
  min-width: ${({ md }) => md && '164px'};
  min-width: ${({ sm }) => sm && '92px'};

  background-color: var(--primary);
  border-radius: 10px;
  border: none;

  :active,
  :not([disabled]):hover,
  :not([disabled]):focus {
    background-color: var(--primary);
    box-shadow: 0 4px 8px rgb(113 2 82 / 45%);
  }

  :disabled {
    background-color: var(--btn-disabled-bg);
    color: var(--btn-disabled-color);
  }

  ${media.desktop`
    width: auto;
  `};

  ${gutterIcon};
`;

export const OutlineButton = styled(YogaButton.Outline)<ButtonProps>`
  min-width: ${({ md }) => md && '164px'};
  min-width: ${({ sm }) => sm && '92px'};

  background-color: #ffffff;
  border-radius: 10px;
  border: 2px solid var(--primary);
  color: var(--primary);

  :active,
  :not([disabled]):hover,
  :not([disabled]):focus {
    box-shadow: 0 4px 8px rgb(113 2 82 / 45%);
  }

  :not([disabled]):active {
    border: 2px solid var(--primary);
    color: var(--primary);
  }

  ${media.desktop`
    width: auto;
  `};

  ${gutterIcon};
`;
