import styled from 'styled-components';

import { theme, Card, Text } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';

export const EmptyJourneyCard = styled(Card)`
  display: flex;
  flex-direction: row;
  border: 1px solid var(--secondary-mellow-apricot);
  background: var(--secondary-peach);

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  ${media.not.md`
    flex-direction: column;
  `};
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EmptyImage = styled.div`
  margin-right: ${theme.spacing.large}px;
  max-width: 120px;
  height: 80px;

  img {
    max-width: 120px;
    height: 80px;
  }

  ${media.not.md`
    flex-direction: column;
    margin-bottom: ${theme.spacing.small}px;
  `};
`;

export const EmptyJourneyCardImage = styled.img`
  margin: ${theme.spacing.zero};
`;

export const EmptyTitle = styled(Text.SectionTitle)`
  color: var(--text-disabled);
  margin-bottom: ${theme.spacing.xxsmall}px;
  text-transform: uppercase;
`;
