import styled from 'styled-components';

import { Card, theme } from '@gympass/yoga';

import media from '../../theme/media';

export const Overlay = styled.div`
  position: absolute;
  inset: 0;
  z-index: 99999 !important;
  width: 100%;
  height: 100%;
  background-color: rgba(35, 27, 34, 0.48);
`;

interface RowProps {
  contentWidth?: number;
}

export const Row = styled.div<RowProps>`
  bottom: 0;
  position: absolute;
  width: 100%;

  ${({ contentWidth }) => media.desktop`
    left: 50%;
    position: relative;
    top: 50%;
    transform: translate(-50%, -50%);
    width: ${contentWidth || 500}px;
  `}
`;

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${theme.spacing.small}px;
  border-radius: ${theme.radii.regular}px ${theme.radii.regular}px
    ${theme.radii.sharp} ${theme.radii.sharp};

  ${media.desktop`
    border-radius: ${theme.radii.small}px;
  `}
`;

interface CloseProps {
  closePos: string;
}

export const CloseIcon = styled.i<CloseProps>`
  position: absolute;
  font-size: ${theme.fontSizes.xlarge}px;
  color: var(--primary);
  top: 16px;

  left: ${({ closePos }) => closePos === 'left' && '16px'};
  right: ${({ closePos }) => closePos === 'right' && '16px'};

  cursor: pointer;

  :hover,
  :focus {
    opacity: 0.7;
  }
`;
