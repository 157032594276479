import styled, { keyframes } from 'styled-components';

import { theme } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';

export const Carousel = styled.div`
  width: 100%;
  max-width: 808px;
  margin: 0 auto;
  padding: ${theme.spacing.medium}px 0px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  ${media.not.md`
    width: 320px;
    height: 340px;
    margin-top: ${theme.spacing.xsmall}px;
  `}
`;

export const CarouselItems = styled.div`
  width: 100%;
  max-width: 840px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  ${media.not.md`
    width: 100%;
    height: 280px;
    gap: 10px;
    display: flex;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    scroll-behavior: smooth;
  `}
  ::-webkit-scrollbar {
    display: none;
  }
`;

const fade = keyframes`
  from {opacity: .4}
  to {opacity: 1}
`;

export const Item = styled.div<{
  active?: boolean;
}>`
  width: 100%;
  display: ${({ active }) => (active ? 'block' : 'none')};
  animation-name: ${fade};
  animation-duration: 1.5s;
  ${media.not.md`
    display: block;
    flex-shrink: 0;
    scroll-snap-align: start;
  `}
`;

export const CarouselControls = styled.div`
  margin-top: ${theme.spacing.large}px;
  ${media.not.md`
    margin-top: 0px;
  `}
`;

export const CarouselInner = styled.button<{
  active?: boolean;
}>`
  width: ${theme.spacing.xsmall}px;
  height: ${theme.spacing.xsmall}px;
  margin: ${theme.spacing.xxxsmall}px;
  border-radius: 100%;
  background-color: ${({ active }) =>
    active ? 'var(--secondary)' : 'var(--secondary-mellow-apricot)'};
`;
