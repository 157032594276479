import { ExploreAudioClick } from 'utils/eventsGA';

import { AudioTypes } from '../../atoms/audio/types';

export const pathNameSearch = (pathname: string) => {
  return pathname.replace('/', '').split('/')[0];
};

/*
  This function should return maximum 40 caracters with TAG when the audio title is more
  than 25 caracters
*/
export const formatTitleToEvent = (title: string, type: 'play' | 'end') => {
  const formattedTitle = title.replaceAll(' ', '').toLowerCase().slice(0, 25);

  return `EU_audios_${formattedTitle}_${type}` as ExploreAudioClick;
};

export const a11yPlaybackRateLabel = (playbackRate: number, rate: number) => {
  return playbackRate === rate
    ? 'CONTENT.ACTIVITY.PAGE.AUDIO.PLAYER.A11Y.GRS.AUDIO.SPEED.SELECTED'
    : 'CONTENT.ACTIVITY.PAGE.AUDIO.PLAYER.A11Y.GRS.AUDIO.SPEED.NOT.SELECTED';
};

export const isAudioOrMeditation = (audioPlayer: AudioTypes | undefined) => {
  return (
    audioPlayer?.page_type === 'audio' ||
    audioPlayer?.page_type === 'meditation' ||
    audioPlayer?.activity_type === 'audio' ||
    audioPlayer?.activity_type === 'meditation'
  );
};
