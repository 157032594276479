import styled from 'styled-components';

import { theme } from '@gympass/yoga';

export const Container = styled.div`
  max-width: 820px;
  margin: ${theme.spacing.zero} auto;
  background: ${theme.colors.white};
  padding: ${theme.spacing.medium}px;
  border-radius: ${theme.radii.small}px;
`;
