import styled from 'styled-components';

import { Text, theme } from '@gympass/yoga';

export const PrimaryContentText = styled(Text)`
  color: var(--text-primary);
  font-weight: ${theme.fontWeights.regular};
  margin-bottom: ${theme.spacing.large}px;

  &:last-of-type {
    margin-bottom: ${theme.spacing.xlarge}px;
  }

  &.section-title {
    color: var(--primary);
    text-transform: uppercase;
    font-weight: ${theme.fontWeights.medium};
    font-size: ${theme.fontSizes.medium}px;
    margin-bottom: ${theme.spacing.xxsmall}px;
  }

  &.subtitle {
    color: var(--text-primary);
    font-weight: ${theme.fontWeights.medium};
    font-size: ${theme.fontSizes.medium}px;
    margin-bottom: ${theme.spacing.xxsmall}px;
  }

  &.disclaimer {
    color: var(--text-secondary);
  }
`;

export const PrimaryContentSubtitle = styled(Text.H5)`
  color: var(--primary);
`;

export const PrimaryContentHighlight = styled.strong`
  color: var(--text-primary);
  display: inline-block;
  font-weight: ${theme.fontWeights.medium}px;
`;

export const PrimaryContentLink = styled.a`
  color: var(--primary);
  display: inline-block;
  font-size: ${theme.fontSizes.medium}px;
  font-weight: ${theme.fontWeights.medium};
  line-height: ${theme.lineHeights.medium}px;
  text-decoration: none;
  transition: color 0.35s ease-in-out;

  &:hover,
  &:focus {
    color: var(--secondary);
  }
`;

export const PrimaryContentList = styled.ul`
  color: var(--text-primary);
  display: flex;
  flex-direction: column;
  line-height: ${theme.lineHeights.small}px;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const PrimaryContentOrderedList = styled.ol`
  color: var(--text-primary);
  counter-reset: item;
  display: flex;
  flex-direction: column;
  line-height: ${theme.lineHeights.small}px;
  list-style: none;
  margin: 0;
  padding: 0;

  li:before {
    background: none;
    content: counter(item) ' . ' !important;
    counter-increment: item;
    color: var(--primary);
    font-weight: ${theme.fontWeights.medium};
    height: auto;
    margin-left: -${theme.spacing.large}px;
    top: 0;
    transform: translateY(0);
    width: auto;
  }
`;

export const PrimaryContentListItem = styled.li`
  font-size: ${theme.fontSizes.medium}px;
  line-height: ${theme.lineHeights.medium}px;
  margin-bottom: ${theme.spacing.large}px;
  margin-left: ${theme.spacing.xlarge}px;
  position: relative;

  &::before {
    background-color: var(--primary);
    border-radius: ${theme.radii.circle}px;
    content: '';
    display: block;
    height: 6px;
    margin-left: -${theme.spacing.medium}px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 6px;
  }
`;

export const PrimaryContentImage = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 100%;

  border: 0;

  &.left {
    margin-left: 0;
    margin-right: auto;
  }

  &.right {
    margin-left: auto;
    margin-right: 0;
  }
`;

export const PrimaryContentIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0px 8px 20px rgb(152 152 166 / 25%);
  border-radius: ${theme.radii.small}px;
  border: none;
`;

export const PrimaryContentDiv = styled.div`
  &.video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    margin-bottom: ${theme.spacing.large}px;
    text-align: center;
  }
`;

export const SecondaryContentText = styled(Text.Small)`
  color: var(--text-secondary);
  font-weight: ${theme.fontWeights.regular};
  margin-bottom: ${theme.spacing.large}px;

  &:last-of-type {
    margin-bottom: ${theme.spacing.xlarge}px;
  }
`;

export const SecondaryContentSubtitle = styled(Text.H5)`
  color: var(--text-primary);
  font-size: ${theme.fontSizes.medium}px;
  font-weight: ${theme.fontWeights.medium};
  line-height: ${theme.lineHeights.medium}px;
  margin-top: 0;
`;

export const SecondaryContentHighlight = styled.strong`
  color: var(--text-primary);
  display: inline-block;
  font-weight: ${theme.fontWeights.medium};
`;

export const SecondaryContentLink = styled.a`
  color: var(--primary);
  display: inline-block;
  font-weight: ${theme.fontWeights.medium};
  text-decoration: none;
  transition: color 0.35s ease-in-out;

  &:hover,
  &:focus {
    color: var(--secondary);
  }
`;

export const SecondaryContentList = styled.ul`
  color: var(--text-secondary);
  display: flex;
  flex-direction: column;
  line-height: ${theme.lineHeights.xsmall}px;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const SecondaryContentListItem = styled.li`
  font-size: ${theme.fontSizes.small}px;
  line-height: ${theme.lineHeights.small}px;
  padding-left: ${theme.spacing.large}px;
  position: relative;

  &::before {
    background-color: var(--primary);
    border-radius: ${theme.radii.circle}px;
    content: '';
    display: block;
    height: 6px;
    left: ${theme.spacing.xxsmall}px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 6px;
  }
`;
