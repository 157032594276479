import styled from 'styled-components';

export const Content = styled.div<{ backgroundColor?: string }>`
  background-color: ${props => props.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img<{ fullWidth?: boolean }>`
  max-width: 360px;
  max-height: 275px;
  ${props => props.fullWidth && `width: 100%; height: 100%`}
`;
