import { createContext } from 'react';

import { UserInfo } from 'services/user';

interface ContextProps {
  user: UserInfo | null;
  loadingUser?: boolean;
  setUser: Function;
  isLoadingUser: Function;
}

const UserContext = createContext<ContextProps>({
  user: {},
  loadingUser: true,
  setUser: () => {},
  isLoadingUser: () => {},
});

const UserProvider = UserContext.Provider;
const UserConsumer = UserContext.Consumer;

/**
 * @public
 */
export { UserContext, UserProvider, UserConsumer };
