import { cms } from 'services/api';
import { CMS } from 'services/cms/types';

export const getContentOptions = (id: string) =>
  ({
    queryKey: ['cms', 'content', id],
    queryFn: async () => {
      const resp = await cms.get<{ data: CMS.Content }>(`/contents/${id}`, {
        params: {
          populate: '*',
        },
      });
      return resp.data.data;
    },
  } as const);

if (!import.meta.env.PROD) {
  (async () => {
    Object.assign(window, {
      // eslint-disable-next-line import/no-self-import
      cmsQueryOptions: { ...(await import('./queryOptions')) },
    });
  })();
}
