import { createContext } from 'react';

export type AlertType = 'failure' | 'success' | 'info';

export type AlertEvent = {
  message?: string;
  type?: AlertType;
  duration?: number;
};

export interface AlertContextProps {
  alert: AlertEvent | null;
  setAlert: (Alert: AlertEvent | null) => void;
}

const INITIAL_STATE: AlertContextProps = {
  alert: null,
  setAlert: () => {},
};

const AlertContext = createContext<AlertContextProps>(INITIAL_STATE);
const AlertConsumer = AlertContext.Consumer;

const AlertProvider = AlertContext.Provider;

/**
 * @public
 */
export { AlertContext, AlertConsumer, AlertProvider };
