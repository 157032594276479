import styled from 'styled-components';

import { theme } from '@gympass/yoga';
import media from 'theme/media';

import { Button } from 'components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.white};

  ${media.desktop`
    justify-content: space-between;
    align-items: center;
    max-width: 840px;
    max-height: 636px;
    margin: ${theme.spacing.xlarge}px auto;
    overflow: hidden;
    border-radius: ${theme.spacing.small}px;
    box-shadow: ${theme.elevations.medium};
  `}
`;

export const Content = styled.div`
  width: 100%;
  padding: ${theme.spacing.zero}px ${theme.spacing.medium}px
    ${theme.spacing.zero}px;

  ${media.desktop`
    padding: ${theme.spacing.xsmall}px ${theme.spacing.xlarge}px
      ${theme.spacing.zero}px;
  `}
`;

export const WrapperForm = styled.div`
  overflow: hidden;
  height: 80vh;
  width: 100%;

  ${media.desktop`
    height: auto;
    max-height: 464px;
  `}

  .MuiFormControl-root {
    margin-top: ${theme.spacing.xxsmall}px;
  }

  #form-control-label {
    margin-bottom: ${theme.spacing.small}px;
  }

  && Label {
    color: var(--text-primary);
    font-size: ${theme.fontSizes.small}px;
    line-height: ${theme.lineHeights.small}px;
  }

  && Label Span {
    font-size: ${theme.fontSizes.small}px;
  }

  opacity: 0;
  transition: opacity 0.2s linear;

  &.animation {
    opacity: 1;
  }
`;

export const WrapperButton = styled.div`
  position: fixed;
  left: ${theme.spacing.zero};
  bottom: ${theme.spacing.zero};
  width: 100%;
  box-shadow: ${theme.spacing.zero} -5px 5px -5px rgba(152, 152, 166, 0.25);

  ${media.desktop`
    position: relative;
    bottom: ${theme.spacing.zero};
    padding: ${theme.spacing.zero}px ${theme.spacing.medium}px;
  `}
`;

export const ButtonContent = styled(Button)`
  border-radius: ${theme.radii.circle}px;
  min-width: 200px;
  max-height: 48px;
`;

export const WrapperSpinner = styled.div`
  margin-right: 28px;
`;
