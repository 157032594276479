import React, { useEffect, useRef } from 'react';

import { DESKTOP_BREAKPOINT } from 'theme/media';

import useWindowDimensions from 'hooks/useWindowDimensions';

import * as S from './style';

import { ModalProps } from './types';

const Modal: React.FC<ModalProps> = ({
  children,
  onClose,
  closePos = 'left',
  contentWidth,
  ariaHiddenModal,
  enableAutoFocus,
  shouldHideCloseOnMobile = false,
  ...props
}) => {
  const modalRef = useRef<HTMLElement>(null);
  const { width } = useWindowDimensions();
  const isDesktop = width >= DESKTOP_BREAKPOINT;
  const canShowClose = (() => {
    if (isDesktop) {
      return true;
    }
    if (shouldHideCloseOnMobile) {
      return false;
    }
    return true;
  })();

  useEffect(() => {
    if (enableAutoFocus && modalRef) {
      setTimeout(() => {
        modalRef.current?.focus();
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- day zero of no errors, remove and fix me when you see me in a code review
  }, []);

  return (
    <S.Overlay data-testid="modal">
      <S.Row
        role="dialog"
        aria-modal="true"
        aria-live="assertive"
        contentWidth={contentWidth}
        aria-hidden={ariaHiddenModal}
      >
        <S.StyledCard ref={modalRef} {...props}>
          {onClose && canShowClose && (
            <S.CloseIcon
              data-testid="modal-button"
              className="icon-close"
              onClick={onClose}
              closePos={closePos}
            />
          )}
          {children}
        </S.StyledCard>
      </S.Row>
    </S.Overlay>
  );
};

export default Modal;
