import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { audioPlayerAtom } from 'atoms/audio';
import { useSetAtom, useStore } from 'jotai';

import useQueryUrl from 'hooks/useQueryUrl';
import { contentToAudio } from 'services/cms/contentToAudio';
import { getContentOptions } from 'services/cms/queryOptions';
import { getAudios } from 'services/strapi';

function isStrapiV5(id: string) {
  return !id.match(/^\d+$/);
}

export function useAudioDeepLink() {
  const setAudioPlayer = useSetAtom(audioPlayerAtom);
  const queryClient = useQueryClient();
  const store = useStore();

  const audioId = useQueryUrl().get('audioId');

  useEffect(() => {
    (async () => {
      if (!audioId) return;

      // deal with strapi v3 here
      if (isStrapiV5(audioId)) {
        return;
      }

      const data = await queryClient.fetchQuery({
        queryKey: ['getAudios'],
        queryFn: getAudios,
        staleTime: Infinity,
      });
      if (!data) return;
      const currentAudioPlayer = store.get(audioPlayerAtom);
      // @ts-expect-error
      // eslint-disable-next-line eqeqeq
      if (currentAudioPlayer?.id == audioId) return;
      // @ts-expect-error
      // eslint-disable-next-line eqeqeq
      const audio = data.find(({ id }) => id == audioId);
      setAudioPlayer(audio);
    })();
  }, [audioId, queryClient, setAudioPlayer, store]);

  useEffect(() => {
    (async () => {
      if (!audioId) return;

      // deal with strapi v5 here
      if (!isStrapiV5(audioId)) {
        return;
      }

      try {
        const content = await queryClient.fetchQuery(
          getContentOptions(audioId),
        );
        const audio = contentToAudio(content);
        const currentAudioPlayer = store.get(audioPlayerAtom);
        // eslint-disable-next-line eqeqeq
        if (currentAudioPlayer?.id == audio.id) return;
        setAudioPlayer(audio);
      } catch (e) {
        // if id is not found
        setAudioPlayer(undefined);
        throw e;
      }
    })();
  }, [audioId, queryClient, setAudioPlayer, store]);
}
