import styled from 'styled-components';

import { theme } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';

export const Footer = styled.footer`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  width: 100%;
`;

export const LinkContainer = styled.div`
  height: ${theme.spacing.xxlarge}px;
  background-color: white;
  display: none;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  ${media.md`
    display: flex;
  `};
`;

export const Link = styled.a`
  max-width: 807px;
  width: 90%;

  color: var(--primary);
  font-weight: ${theme.fontWeights.medium};
  font-size: ${theme.fontSizes.xsmall}px;
  text-decoration: none;
  word-break: break-all;
  margin: ${theme.spacing.zero}px auto;

  &:hover {
    cursor: pointer;
  }
`;
