import { MessageFormatElement } from 'react-intl';

export type AcceptedLanguagesShort = 'pt' | 'en';

export enum AcceptedLanguages {
  /** @knipignore */
  en = 'en',
  /** @knipignore */
  pt = 'pt-PT',
}

export interface UseTranslationsState {
  getDefaultTranslations: () => Promise<Record<string, MessageFormatElement[]>>;
  getTranslation: () => Promise<Record<string, MessageFormatElement[]>>;
  language: AcceptedLanguagesShort;
}
