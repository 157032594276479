import { createContext } from 'react';

import { Session, Credits } from '../services/user/types';

type ContextProps = {
  session: (Session & Credits) | null;
  setSession: Function;
};

const SessionContext = createContext<ContextProps>({
  session: {
    timekit_id: '',
    name: '',
    avatar: '',
    start: '',
    end: '',
    booking_id: '',
    number: '',
    error: false,
    new_booking_allowed: false,
    next_booking_from: '',
    status: '',
    credit_left: 0,
    total_credits: 0,
    credits_used: 0,
  },
  setSession: () => {},
});

const SessionProvider = SessionContext.Provider;
const SessionConsumer = SessionContext.Consumer;

/**
 * @public
 */
export { SessionContext, SessionProvider, SessionConsumer };
