import styled from 'styled-components';

import { theme } from '@gympass/yoga';
import { MenuItem as MuiMenuItem } from '@mui/material';

export const MenuItem = styled(MuiMenuItem)`
  height: 48px;

  &:before {
    border-right: 2px solid;
    content: '';
    display: block;
    height: ${theme.spacing.xxsmall}px;
    margin-top: -6px;
    position: absolute;
    transform: rotate(135deg);
    right: ${theme.spacing.large}px;
    top: 50%;
    width: ${theme.spacing.zero}px;
    color: var(--primary);
  }

  &:after {
    border-right: 2px solid;
    content: '';
    display: block;
    height: ${theme.spacing.xxsmall}px;
    position: absolute;
    transform: rotate(45deg);
    right: ${theme.spacing.large}px;
    top: 50%;
    width: ${theme.spacing.zero}px;
    margin-top: -1px;
    color: var(--primary);
  }

  a {
    text-decoration: none;
    color: var(--text-primary);
    width: 100%;
  }

  & + li {
    border-top: 1px solid var(--divider);
  }
`;

export const MenuItemDisabled = styled(MenuItem)`
  height: 72px;

  p {
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 12px;
  }
`;
export const Bullet = styled.div`
  height: ${theme.spacing.xxsmall}px;
  width: ${theme.spacing.xxsmall}px;
  margin-left: ${theme.spacing.xxsmall}px;
  border-radius: ${theme.radii.circle}px;
  background-color: #ff956b;
`;
