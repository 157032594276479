import styled from 'styled-components';

import { theme } from '@gympass/yoga';
import media from 'theme/media';

export const DownloadCardActionButton = styled.button`
  padding: ${theme.spacing.zero};
  background-color: transparent;
`;

export const StoreContainer = styled.div`
  & + & {
    ${media.mobile`
      margin-left: ${theme.spacing.xxsmall}px;
    `}
  }
`;

export const StoreImage = styled.img`
  margin: ${theme.spacing.zero};
`;
