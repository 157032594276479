import styled from 'styled-components';

import { Text, theme } from '@gympass/yoga';

import { Button } from '../..';

import Modal from '..';

import media from '../../../theme/media';

export const StyledModal = styled(Modal)`
  width: 100vw;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;
  ${media.desktop`

    width: 600px;
    i{
      right: auto;
    }

  `}
`;

export const SuccessContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
`;
export const SuccessText = styled.p`
  margin-bottom: ${theme.spacing.large}px;

  ${media.desktop`
    margin-bottom: ${theme.spacing.xlarge}px;
  `}
`;
export const Icon = styled.i`
  font-size: 85px;
  color: var(--success);

  margin: ${theme.spacing.large}px 0;

  ${media.desktop`
    margin-bottom: ${theme.spacing.xlarge}px;
  `}
`;

export const NPSRatingBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding-bottom: ${theme.spacing.large}px;

  ${media.desktop`
    padding-bottom: ${theme.spacing.xxlarge}px;
  `}
`;

export const NPSRatingContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  text-align: center;

  align-items: center;
  justify-content: center;

  padding: ${theme.spacing.xxxlarge}px ${theme.spacing.zero}px
    ${theme.spacing.xxlarge}px ${theme.spacing.zero}px;
`;

export const SectionTitle = styled(Text)`
  padding: 0 ${theme.spacing.small}% 0 ${theme.spacing.small}%;
  color: var(--text-primary);
  font-weight: ${theme.fontWeights.medium};
  margin-bottom: ${theme.spacing.xsmall}px;
`;

export const SectionInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: ${theme.spacing.small}px;
`;

export const InfoText = styled(Text)`
  font-size: ${theme.fontSizes.small}px;
  color: var(--text-secondary);
`;

export const StyledButton = styled(Button)`
  align-self: center;
  margin-bottom: ${theme.spacing.xsmall}px;
`;
