import styled from 'styled-components';

import { theme, Text } from '@gympass/yoga';
import media from 'theme/media';

export const Content = styled.div<{ backgroundColor: string }>`
  padding: ${theme.spacing.small}px ${theme.spacing.medium}px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const Title = styled(Text.H5)<{ titleColor: string }>`
  padding-bottom: ${theme.spacing.small}px;
  color: ${({ titleColor }) => titleColor};

  ${media.desktop`
    font-size: ${theme.fontSizes.xlarge}px;
  `}
`;

export const DurationContainer = styled.div`
  padding-bottom: ${theme.spacing.small}px;
`;

export const Description = styled(Text.Small)<{ descriptionColor: string }>`
  color: ${({ descriptionColor }) => descriptionColor};
  white-space: pre-wrap;

  ${media.desktop`
    font-size: ${theme.fontSizes.medium}px;
    font-style: normal;
    line-height: ${theme.lineHeights.medium}px;
  `}
`;
