import { useMemo } from 'react';

import { useLocation } from 'react-router';

function useQueryUrl() {
  const location = useLocation();
  const search = location.search ?? location.location.search;
  const hash = location.hash ?? location.location.hash;

  const query = useMemo(
    () =>
      [search?.replace(/^\?/, ''), hash?.replace(/^#/, '')]
        .filter(Boolean)
        .join('&'),
    [hash, search],
  );

  return useMemo(() => new URLSearchParams(query), [query]);
}

export default useQueryUrl;
