import styled from 'styled-components';

import { theme } from '@gympass/yoga';

import media from '../../../theme/media';

export const Container = styled.div`
  width: 100%;
  height: 36px;
  position: relative;
  max-width: 310px;
  margin: ${theme.spacing.zero} auto;

  ${media.desktop`
    max-width: 600px;
  `}
`;

export const Input = styled.input`
  width: 100%;
  height: 36px;
  border: ${theme.borders.small}px solid var(--divider);
  border-radius: ${theme.radii.circle}px;
  padding: ${theme.spacing.xxsmall}px ${theme.spacing.xhuge}px
    ${theme.spacing.xxsmall}px ${theme.spacing.medium}px;
  line-height: 1;
  box-sizing: border-box;
  outline: none;
  font-size: ${theme.fontSizes.small}px;
  color: var(--text-secondary);

  ::-webkit-input-placeholder {
    color: var(--text-secondary);
    font-size: ${theme.fontSizes.small}px;
  }
  ::-moz-placeholder {
    color: var(--text-secondary);
    font-size: ${theme.fontSizes.small}px;
  }
  :-ms-input-placeholder {
    color: var(--text-secondary);
    font-size: ${theme.fontSizes.small}px;
  }
  :-moz-placeholder {
    color: var(--text-secondary);
    font-size: ${theme.fontSizes.small}px;
  }
`;

export const SendButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;

  position: absolute;
  right: 3px;
  top: 3px;
  bottom: 3px;
  border: 0;
  background: var(--primary);
  color: white;
  outline: none;
  margin: 0;
  padding: 0 ${theme.spacing.xxsmall}px;
  border-radius: ${theme.radii.circle}px;
  z-index: 2;
  transform: rotate(180deg);

  &:disabled {
    background: #8383a3;
  }
`;

export const Icon = styled.i`
  font-size: ${theme.fontSizes.small}px;
  color: white;
`;
