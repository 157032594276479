import styled, { css } from 'styled-components';

import { theme } from '@gympass/yoga';

import { WrapperProps } from 'components/Button/types';

const wrapperModifiers = {
  default: () => css`
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};

    transition: box-shadow 0.2s, opacity 0.2s;

    &:hover,
    &:focus {
      box-shadow: 0px 4px 8px rgba(152, 152, 166, 0.45);
    }

    &:active {
      opacity: 0.75;
    }

    &:disabled {
      background-color: var(--progress-base-color);
      color: ${theme.colors.light};
      pointer-events: none;
    }
  `,
  outline: () => css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.primary};

    border: 2px solid ${theme.colors.primary};

    transition: box-shadow 0.2s, opacity 0.2s;

    &:hover,
    &:focus {
      box-shadow: var(--button-hover-shadow);
    }

    &:active {
      opacity: 0.75;
    }

    &:disabled {
      background-color: var(--progress-base-color);
      color: ${theme.colors.light};
      border-color: var(--divider);
      pointer-events: none;
    }
  `,
  text: () => css`
    color: ${theme.colors.primary};

    transition: opacity 0.2s;

    &:hover {
      opacity: 0.5;
    }

    &:active,
    &:focus {
      opacity: 0.75;
    }

    &:disabled {
      color: ${theme.colors.light};
    }
  `,
  link: () => css`
    color: ${theme.colors.primary};

    padding: ${theme.spacing.zero}px;
    line-height: ${theme.lineHeights.medium}px;

    transition: opacity 0.2s;

    &:hover {
      opacity: 0.5;
    }

    &:active,
    &:focus {
      opacity: 0.75;
    }

    &:disabled {
      color: ${theme.colors.light};
    }
  `,
  medium: () => css`
    padding: ${theme.spacing.xsmall}px ${theme.spacing.large}px;
    font-size: ${theme.fontSizes.medium}px;
    line-height: ${theme.lineHeights.medium}px;

    border-radius: ${theme.spacing.xsmall}px;
  `,
  small: () => css`
    padding: ${theme.spacing.xxsmall}px ${theme.spacing.small}px;
    font-size: ${theme.fontSizes.small}px;
    line-height: ${theme.lineHeights.xsmall}px;

    border-radius: ${theme.spacing.xsmall}px;
  `,
  full: () => css`
    width: 100%;
  `,
};

export const Wrapper = styled.button<WrapperProps>`
  ${({ variant, size, full }) => css`
    background: none;
    color: inherit;
    border: none;
    margin: ${theme.spacing.zero}px;
    padding: ${theme.spacing.zero}px;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    font-style: normal;
    font-weight: ${theme.fontWeights.medium};
    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: center;

    ${!!size && wrapperModifiers[size]}
    ${!!variant && wrapperModifiers[variant]}
    ${!!full && wrapperModifiers.full}
  `}
`;
