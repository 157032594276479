import styled from 'styled-components';

import { theme, Card, Text } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';
import { ChevronRight } from '@gympass/yoga-icons';

const DisplayFlex = styled.div`
  display: flex;
`;

export const ProgramItem = styled(Card.Content)`
  position: relative;

  border-radius: ${theme.radii.small}px;
  border: ${theme.borders.small}px solid var(--whisper);
  height: 100px;
  margin-bottom: ${theme.spacing.small}px;
  overflow: hidden;

  :last-child {
    margin-bottom: ${theme.spacing.zero}px;
  }

  background: linear-gradient(
    to top right,
    var(--primary),
    var(--primary-light)
  );
  cursor: pointer;
`;

export const BackgroundImage = styled.div`
  position: absolute;

  width: 100%;
  height: 100px;

  background-image: url('assets/icon/pin.svg');
  background-position-x: 100%;
  background-position-y: 66%;
  background-repeat: no-repeat;
  background-size: 136px;
`;

export const ProgramItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: ${theme.spacing.medium}px;

  ${media.not.md`
    padding: ${theme.spacing.small}px;
  `}
`;

export const ArrowIcon = styled(ChevronRight)`
  position: absolute;
  right: 16px;
  top: 0;
  bottom: 0;
  transform: translateY(100%);

  ${media.not.md`
    right: 12px;
  `}

  width: ${theme.fontSizes.xxlarge}px;
  height: ${theme.fontSizes.xxlarge}px;
  fill: var(--white);
`;

export const ProgramItemContent = styled(DisplayFlex)``;

export const ProgramTitle = styled(Text)`
  overflow: hidden;
  padding-bottom: ${theme.spacing.xxsmall}px;
  white-space: nowrap;
  width: ${({ maxWidth }) => maxWidth - 92}px;

  color: var(--white);
  text-overflow: ellipsis;
`;
