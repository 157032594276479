import styled from 'styled-components';

import { Text, theme } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';

import { OutlineButton } from '../ButtonLegacy';

export const Banner = styled.div`
  border-radius: ${theme.radii.small}px;
  box-shadow: 0px 4px 12px rgba(152, 152, 166, 0.25);
  background-color: var(--primary-light);
  transition: all 0.3s ease-in-out;
  width: 320px;
  height: 264px;

  ${media.not.max('md')`
    width: 100%;
    max-width: var(--content-page-width);
    height: 120px;
  `};
`;

export const Content = styled.div<{ isCollapsed: boolean }>`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${({ isCollapsed }) => (isCollapsed ? 'flex-start' : 'center')};
  margin: 0 auto;
  height: 100%;
  overflow-x: hidden;
  padding: ${theme.spacing.medium}px;

  ${media.not.max('md')`
    flex-direction: row;
    max-width: var(--content-page-width);
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacing.medium}px;
  `};
`;

export const Comma = styled.div`
  position: absolute;
  right: -10px;
  top: 0;
  background-repeat: no-repeat;
  background-image: url(/assets/icon/comma.svg);
  background-size: 164px 240px;
  width: 164px;
  height: 264px;

  ${media.not.max('md')`
    height: 118px;
    background-size: 160px 140px;
    right: 143px;
  `};
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 468px;
  height: 100%;
`;

export const Title = styled(Text.SectionTitle)`
  color: var(--white);
  z-index: 1;
`;

export const Description = styled(Text.Regular)`
  color: var(--white);
  z-index: 1;
  padding-top: ${theme.spacing.xxsmall}px;
  line-height: ${theme.lineHeights.small}px;
  ${media.not.max('md')`
    display: block;
  `};
`;

export const ActionContent = styled.div`
  align-self: flex-start;
  button {
    position: relative;
    z-index: 2;
    width: 280px;
    font-size: ${theme.fontSizes.small}px;
  }
  ${media.not.max('md')`
    display: block;
    align-self: inherit;
    padding-bottom: 0px;
    margin: 0px;
    button{
      width: auto;
    }
  `};
`;

/** @knipignore */ // TODO: day zero adding knip to this project, remove me
export const Chevron = styled.div`
  display: block;
  position: absolute;
  right: 32px;
  top: 16px;
  z-index: 10;
  transform: rotate(
    ${(
      // @ts-expect-error -- day zero of no errors, remove and fix me when you see me in a code review
      { isCollapsed },
    ) => (isCollapsed ? 270 : 90)}deg
  );

  ${media.not.max('md')`
    display: none;
  `};
`;

/** @knipignore */ // TODO: day zero adding knip to this project, remove me
export const Icon = styled.i`
  font-size: ${theme.fontSizes.small}px;
  color: white;
  width: 14px;
  height: 14px;
`;

export const BannerOutlineButton = styled(OutlineButton)`
  height: ${theme.spacing.xlarge}px;
  padding: ${theme.spacing.small}px;
`;
