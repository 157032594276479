// properties from https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement#instance_properties
// currentSrc, src and srcObject are got from a separete atom
export const properties = [
  'autoplay',
  'buffered',
  'controls',
  'crossOrigin',
  'currentTime',
  'defaultMuted',
  'defaultPlaybackRate',
  'disableRemotePlayback',
  'duration',
  'ended',
  'error',
  'loop',
  'muted',
  'networkState',
  'paused',
  'playbackRate',
  'preservesPitch',
  'readyState',
  'seekable',
  'textTracks',
  'volume',
] as const;

// events from https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement#events
export const events = [
  'abort',
  'canplay',
  'canplaythrough',
  'durationchange',
  'emptied',
  'ended',
  'error',
  'loadeddata',
  'loadedmetadata',
  'loadstart',
  'pause',
  'play',
  'playing',
  'progress',
  'ratechange',
  'seeked',
  'seeking',
  'stalled',
  'suspend',
  'timeupdate',
  'volumechange',
  'waiting',
] as const;

// src and srcObject are is got from a separate atom
export const writableProperties = new Set([
  'controls',
  'crossOrigin',
  'currentTime',
  'defaultMuted',
  'defaultPlaybackRate',
  'disableRemotePlayback',
  'loop',
  'muted',
  'playbackRate',
  'preload',
  'preservesPitch',
  'volume',
]);
