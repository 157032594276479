import { useEffect, useState } from 'react';

function useStateWithLocalStorage(key: string) {
  const [value, setValue] = useState(localStorage.getItem(key) || '');

  useEffect(() => {
    localStorage.setItem(key, value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return { value, setValue };
}

export default useStateWithLocalStorage;
