// eslint-disable-next-line @typescript-eslint/no-explicit-any -- day zero of no errors, remove and fix me when you see me in a code review
function triggerWebviewEvent(eventType: string, data: any) {
  const payload = {
    event: eventType,
    data: data || {},
  };

  window.ReactNativeWebView?.postMessage(JSON.stringify(payload));
}

export default triggerWebviewEvent;
