import styled from 'styled-components';

import { theme } from '@gympass/yoga';

import Badge from '../Badge';

interface TabButtonContentProps {
  active: boolean;
}

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  height: var(--tab-menu-height);
  box-shadow: var(--shadow-tab-menu);
  z-index: 10;

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: block;
    cursor: pointer;
  }
`;

export const TabItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;

  &.active {
    background-color: #e6e6e6;
    color: #333;
  }
`;

export const Icon = styled.i<TabButtonContentProps>`
  &::before {
    font-size: ${theme.fontSizes.xlarge}px;
  }

  color: ${({ active }) =>
    active ? 'var(--primary)' : 'var(--text-disabled)'};
`;

export const Label = styled.label<TabButtonContentProps>`
  font-size: ${theme.fontSizes.xxsmall}px;
`;

export const IconWrapper = styled.div`
  position: relative;
  height: 24px;
`;

export const StyledBadge = styled(Badge)`
  top: 0;
  right: 0;
`;
