import styled from 'styled-components';

import { Text, theme } from '@gympass/yoga';

import media from '../../theme/media';

import {
  ContainerProps,
  ContentProps,
  HeaderContainerProps,
  LogoProps,
  MenuIconProps,
  TitleProps,
} from './types';

export const Container = styled.header<ContainerProps>`
  ${props =>
    !props.disabledShadowTitle
      ? `
box-shadow: 0px 4px 12px rgba(152, 152, 167, 0.25);
`
      : ``}
  z-index: 10;
  height: var(--header-height);
  padding: 0 20px;
  display: flex;

  background-color: ${props =>
    props.onlyTitleBackground ? 'var(--background-light)' : theme.colors.white};

  &::after {
    height: ${props => !props.reverse && '0px'};
  }

  ${({ hideMobile }) =>
    hideMobile
      ? `
    display: none;

    @media (min-width: 768px) {
      display: flex;
    }`
      : ``}
`;

export const HeaderContainer = styled.div<HeaderContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  ${({ reverse }) => media.desktop`
    width: var(--content-width);
    margin: 0 auto;
    flex-flow: ${reverse && 'row-reverse'};
    flex: unset;
  `}
`;

export const Content = styled.div<ContentProps>`
  display: ${props => (props.visible ? 'flex' : 'none')};
  flex-flow: ${props => !props.visible && 'row-reverse'};
  width: 100%;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: row;
  }

  ${media.desktop`
    display: flex;
    flex-flow: row;
    align-items: center;
  `}
`;

export const Logo = styled.img<LogoProps>`
  display: ${props => props.hide && 'none'};
  width: 90px;
  margin-right: ${theme.spacing.xxlarge}px;

  ${media.desktop`
    display: block;
  `}
`;

export const Title = styled(Text.H4)<TitleProps>`
  margin: 0;
  color: ${props => (props.small ? 'var(--text-primary)' : 'var(--primary)')};
  font-weight: ${props =>
    props.small ? theme.fontWeights.medium : theme.fontWeights.light};
  font-size: ${props =>
    props.small ? theme.fontSizes.small : theme.fontSizes.xlarge}px;
  text-align: center;

  ${media.desktop`
    font-size: 20px;
  `}
`;

export const Icon = styled.i`
  color: var(--primary);

  ${media.desktop`
    display: none;
  `}
`;

export const BackButton = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const BackButtonIcon = styled.i`
  color: var(--primary);
  position: relative;
  font-size: 18px;
  cursor: pointer;
  margin-right: ${theme.spacing.medium}px;

  ${media.desktop`
    font-size: 18px;
  `}
`;

export const MenuButton = styled.button`
  width: 32px;
  height: 32px;
  all: unset;

  position: relative;
  border-radius: 50%;
  margin-left: 24px;

  cursor: pointer;
`;

export const MenuIcon = styled.i<MenuIconProps>`
  ${props => props.hideIcon && 'display: none;'};
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 24px;
  color: #8383a3;

  transform: translate(-50%, -50%);
`;

export const StyledContent = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
`;

export const HeaderSimple = styled.div`
  padding: ${theme.spacing.zero};
  background-color: var(--background-light);
`;

export const HeaderContentSimple = styled.div`
  padding: ${theme.spacing.large}px ${theme.spacing.zero}
    ${theme.spacing.xxlarge}px ${theme.spacing.zero};
`;

export const ContentSimple = styled.div`
  max-width: 808px;
  margin: ${theme.spacing.zero} auto;
  padding: ${theme.spacing.zero};

  ${media.mobile`
    padding: ${theme.spacing.zero} ${theme.spacing.medium}px;
  `}
`;

export const LogoSimple = styled.img`
  padding: ${theme.spacing.zero};
`;
