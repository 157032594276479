import React, { forwardRef } from 'react';

import ReactCountdown from 'react-countdown';
import { FormattedMessage } from 'react-intl';

import * as Styles from './style';

interface CountdownProps {
  zeroPadTime: number;
  seconds: number;
  onComplete?: // @ts-expect-error -- day zero of no errors, remove and fix me when you see me in a code review
  | ((timeDelta: CountdownTimeDelta, completedOnStart: boolean) => void)
    | (() => void);
}

const Countdown: React.FC<CountdownProps> = ({
  seconds,
  onComplete,
  // @ts-expect-error -- day zero of no errors, remove and fix me when you see me in a code review
  ref,
  ...props
}) => {
  // @ts-expect-error -- day zero of no errors, remove and fix me when you see me in a code review
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return null;
    }
    const formattedHours = hours >= 10 ? hours : `0${hours}`;
    const formattedMin = minutes >= 10 ? minutes : `0${minutes}`;
    const formattedSec = seconds >= 10 ? seconds : `0${seconds}`;
    return (
      <Styles.CountText>
        <FormattedMessage id="COUNTDOWN.DIDNTRECEIVED" />{' '}
        <FormattedMessage id="COUNTDOWN.SENDEMAIL" />{' '}
        <FormattedMessage id="COUNTDOWN.IN" /> [{formattedHours}:{formattedMin}:
        {formattedSec}]
      </Styles.CountText>
    );
  };

  return (
    // @ts-expect-error -- day zero of no errors, remove and fix me when you see me in a code review
    <ReactCountdown
      ref={ref}
      daysInHours
      date={Date.now() + seconds * 1000}
      renderer={renderer}
      onComplete={onComplete}
      {...props}
    />
  );
};

// eslint-disable-next-line react/display-name
export default forwardRef((props: CountdownProps, ref) => {
  // @ts-expect-error -- day zero of no errors, remove and fix me when you see me in a code review
  return <Countdown ref={ref} {...props} />;
});
