import styled from 'styled-components';

import { Card, Text, theme } from '@gympass/yoga';

import Modal from '..';

import media from '../../../theme/media';
import { Button } from '../../ButtonLegacy';

export const StyledModal = styled(Modal)`
  align-items: center;
  text-align: center;
  padding-bottom: ${theme.spacing.xxxlarge}px;

  ${media.desktop`
    padding-bottom: ${theme.spacing.medium}px;
  `}
`;

/** @knipignore */ // TODO: day zero adding knip to this project, remove me
export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${theme.spacing.small}px ${theme.spacing.medium}px
    ${theme.spacing.large}px;
  border-radius: ${theme.radii.regular}px ${theme.radii.regular}px
    ${theme.radii.sharp}px ${theme.radii.sharp}px;

  ${media.desktop`
    border-radius: ${theme.radii.small}px;
  `}
`;

export const Title = styled(Text.H4)`
  font-weight: ${theme.fontWeights.medium};
  margin: ${theme.spacing.small}px ${theme.spacing.zero}px
    ${theme.spacing.large}px ${theme.spacing.zero}px;
`;

export const Description = styled(Text.Medium)`
  color: var(--text-secondary);
  margin-bottom: ${theme.spacing.xxlarge}px;
  font-weight: ${theme.fontWeights.regular};
`;

export const StyledButton = styled(Button)`
  margin-bottom: ${theme.spacing.xsmall}px;
`;

export const Icon = styled.i`
  font-size: 64px;
  color: var(--secondary);
  margin: ${theme.spacing.large}px ${theme.spacing.zero}px;
`;

export const Bold = styled.b`
  color: var(--text-primary);
  font-weight: ${theme.fontWeights.medium};
`;
