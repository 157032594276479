import otaClient, { ClientConfig } from '@crowdin/ota-client';
import logger from 'libraries/logger';

import CustomHttpClient from 'hooks/useTranslation/customHttpClient';

async function crowdinClient(
  locale: string,
  hash: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- day zero of no errors, remove and fix me when you see me in a code review
): Promise<any | otaClient> {
  try {
    const crowdinConfig: ClientConfig = {
      httpClient: new CustomHttpClient(),
      disableManifestCache: true,
    };

    if (hash) {
      // eslint-disable-next-line new-cap
      const crowdin: otaClient = new otaClient(hash, crowdinConfig);

      if (crowdin) {
        crowdin.setCurrentLocale(locale);
      }

      return crowdin;
    }
  } catch (err) {
    logger.error(`[SERVICE][CROWDIN][ERROR]: ${err}`);
  }
  return null;
}

export default crowdinClient;
