import React, {
  useEffect,
  useState,
  forwardRef,
  ForwardRefRenderFunction,
  IframeHTMLAttributes,
} from 'react';

import { VIMEO_URL } from 'utils/constants';
import { isHttpsUri } from 'valid-url';

const securedUrls = [window.location.host, VIMEO_URL];

interface SecureIframeProps extends IframeHTMLAttributes<HTMLIFrameElement> {
  src: string;
  refElement?: HTMLIFrameElement;
}

const SecureIframe: ForwardRefRenderFunction<
  HTMLIFrameElement,
  SecureIframeProps
> = ({ src, ...attrs }, refElement) => {
  const [safeSrc, setSafeSrc] = useState('');

  useEffect(() => {
    function validateUrl(url: string) {
      const urlSrc = new URL(url);
      const urlWithoutQueryParams = `${urlSrc.origin}${urlSrc.pathname}`;
      if (!isHttpsUri(urlWithoutQueryParams)) return '';
      for (let i = 0; i <= securedUrls.length; i += 1) {
        if (urlWithoutQueryParams.indexOf(securedUrls[i]) !== -1)
          return urlSrc.href;
      }
      return '';
    }

    if (src) {
      setSafeSrc(validateUrl(src));
    }
  }, [src]);

  return (
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    <iframe data-testid="iframe" src={safeSrc} ref={refElement} {...attrs} />
  );
};

export default forwardRef(SecureIframe);
