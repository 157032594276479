import React from 'react';

import Lottie from 'lottie-react';

import animation from './animation.json';
import * as Styles from './style';

import { LoadingProps } from './types';

const Loading: React.FC<LoadingProps> = ({ containerOpacity }) => (
  <Styles.Center
    data-testid="loadingLottie"
    containerOpacity={containerOpacity}
    tabIndex={-1}
  >
    <Styles.WrapperLottie>
      <Lottie animationData={animation} />
    </Styles.WrapperLottie>
  </Styles.Center>
);

export default Loading;
