import styled from 'styled-components';

import { theme, Text } from '@gympass/yoga';

export const Col = styled.div`
  background: var(--background-light);
`;

export const SectionContent = styled.div`
  max-width: 807px;
  margin: 0 auto;
`;

export const SectionTitle = styled(Text.SectionTitle)`
  margin-bottom: ${theme.spacing.xxxsmall}px;
`;

export const SectionBody = styled.div`
  margin-top: ${theme.spacing.small}px;
`;
