import styled from 'styled-components';

import { theme, Card, Text } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';

export const AccordionContainer = styled(Card.Content)<{
  isCard?: boolean;
}>`
  border-radius: ${theme.radii.small}px;
  border: ${theme.borders.small}px solid var(--whisper);
  margin-bottom: ${theme.spacing.small}px;
  overflow: hidden;
  box-shadow: ${({ isCard }) => isCard && theme.elevations.small};
  :last-child {
    margin-bottom: ${theme.spacing.zero}px;
  }
`;

export const AccordionContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  outline: none;
  padding: ${theme.spacing.medium}px;
  transition: all 0.4s ease-in-out;
  background-color: ${theme.colors.white};
  ${media.not.md`
    padding: 16px;
  `};
`;

export const AccordionHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export const AccordionHeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const HeaderIcon = styled.img`
  width: ${theme.fontSizes.xxxlarge}px;
  margin-right: ${theme.spacing.medium}px;
`;

export const AccordionTitle = styled(Text.Small)`
  font-weight: ${theme.fontWeights.medium};
`;

export const ShimmerAccordion = styled.div`
  height: 64px;
  width: 100%;
`;

export const Accordion = styled.div<{
  isCollapsed: boolean;
}>`
  display: ${({ isCollapsed }) => (isCollapsed ? 'block' : 'none')};
`;

export const CollapseAccordion = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  svg {
    margin-left: ${theme.spacing.xxxsmall}px;
    height: ${theme.spacing.large}px;
    width: ${theme.spacing.large}px;

    fill: ${theme.colors.primary};
  }
`;
