import { ReactNode, useEffect } from 'react';

import isWebview from 'utils/isWebview';
import triggerWebviewEvent from 'utils/triggerWebviewEvent';

import { EventWebview } from 'types/webview';

export function WebviewAware({ children }: { children: ReactNode }) {
  const isFromWebview = isWebview();

  useEffect(() => {
    function onMessage(e: EventWebview) {
      if (e === 'WebviewBack') return triggerWebviewEvent('close', {});

      if (!('eventName' in e)) return triggerWebviewEvent('close', {});
      if (e.eventName === 'WebviewBack')
        return triggerWebviewEvent('close', {});
    }

    if (isFromWebview) {
      window.WebViewBridge = {
        onMessage,
      };
      window.addEventListener('WebviewBack', onMessage);
    }

    return () => {
      if (isFromWebview) {
        window.removeEventListener('WebviewBack', onMessage);
      }
    };
  }, [isFromWebview]);

  return children as JSX.Element;
}
