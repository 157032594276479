import styled from 'styled-components';

import { Card, Text, theme, Button } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';

export const Container = styled.div`
  margin-bottom: ${theme.spacing.small}px;
`;

export const CardItem = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--background);
  border-top-left-radius: ${({ isJourney }) =>
    isJourney && theme.spacing.zero}px;
  border-top-right-radius: ${({ isJourney }) =>
    isJourney && theme.spacing.zero}px;
`;

export const JourneyHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${theme.spacing.xsmall}px ${theme.spacing.small}px;
  border-top-right-radius: ${theme.spacing.xxsmall}px;
  border-top-left-radius: ${theme.spacing.xxsmall}px;
  background: var(--primary);
`;

export const JouneyTextHeader = styled(Text.Small)`
  color: var(--background);
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: ${theme.spacing.small}px;

  ${media.not.md`
    flex-direction: column-reverse;
    align-content: flex-start;
  `};
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 52px;

  ${media.not.md`
    flex-direction: column;
    height: auto;
  `}
`;

export const ActivityContent = styled.div``;

export const TopicTitle = styled(Text.Small)`
  color: var(--primary);
  font-weight: ${theme.fontWeights.medium};
`;

export const ActivityTitle = styled(Text.Small)`
  font-weight: ${theme.fontWeights.medium};
  margin-bottom: ${theme.spacing.xsmall}px;
`;

export const ActivityType = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

export const TypeText = styled(Text.Tiny)`
  font-weight: ${theme.fontWeights.regular};
  color: var(--text-disabled);
`;

export const TypeIcon = styled.div`
  display: flex;

  svg {
    margin-right: ${theme.spacing.xxxsmall}px;
    width: ${theme.fontSizes.medium}px;
    height: ${theme.fontSizes.medium}px;
    fill: var(--text-disabled);
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  ${media.not.md`
    margin-top: ${theme.spacing.small}px;
  `}
`;

export const CustomButton = styled(Button)`
  width: 100px;
  height: 32px;

  ${media.not.md`
    width: 100%;
  `}
`;

export const ButtonText = styled(Text.Small)`
  color: var(--background);
  font-weight: ${theme.fontWeights.medium};
  line-height: ${theme.lineHeights.xsmall}px;
`;
