import styled, { css } from 'styled-components';

import { theme } from '@gympass/yoga';

import { AvatarProps } from '.';

export const Avatar = styled.img<AvatarProps>`
  ${({ width, height }) => css`
    width: ${width}px;
    height: ${height}px;
    border-radius: ${theme.radii.circle}px;
    border: 2px solid white;
    object-fit: cover;
    box-shadow: 0px 4px 12px rgba(152, 152, 166, 0.25);
  `}
`;
