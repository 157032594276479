import { createContext } from 'react';

import { Config, defaultExportsConfig } from '../services/api';

type ContextProps = {
  exportsConfig: Config | null;
  setExportsConfig: Function;
};

const ExportsConfigContext = createContext<ContextProps>({
  exportsConfig: defaultExportsConfig,
  setExportsConfig: () => {},
});

const ExportsConfigProvider = ExportsConfigContext.Provider;
const ExportsConfigConsumer = ExportsConfigContext.Consumer;

/**
 * @public
 */
export { ExportsConfigContext, ExportsConfigProvider, ExportsConfigConsumer };
