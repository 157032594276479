import styled from 'styled-components';

import { theme, Text } from '@gympass/yoga';
import media from 'theme/media';

export const Container = styled.div`
  padding: ${theme.spacing.small}px;
  margin-top: ${theme.spacing.medium}px;
`;

export const Title = styled(Text.H4)`
  ${media.mobile`
    font-size: ${theme.fontSizes.medium}px;  
  `}
`;

export const Subtitle = styled(Text.Small)`
  margin: ${theme.spacing.small}px ${theme.spacing.zero}px;
  max-width: 410px;
  ${media.mobile`
    font-size: ${theme.fontSizes.medium}px;  
  `}
`;

export const Image = styled.img`
  width: 400px;
  height: 400px;
  ${media.mobile`
    width: 288px;
    height: 288px;
  `}
`;

export const DownloadButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 190px;
  border-radius: ${theme.radii.regular}px;
  margin: ${theme.spacing.zero}px auto;
  margin-top: ${theme.spacing.small}px;
  background-color: var(--primary);
  padding: ${theme.spacing.small}px ${theme.spacing.medium}px;
  text-decoration: none;
  font-weight: ${theme.fontWeights.medium};
  color: var(--white);
  :not([disabled]):hover {
    background-color: var(--primary);
    color: var(--white);
  }
`;
