import React from 'react';

import * as S from 'components/Button/styles';
import { ButtonProps } from 'components/Button/types';
import Spinner from 'components/Spinner';

export function Button({
  variant = 'default',
  size = 'medium',
  disabled = false,
  full = false,
  loading = false,
  children,
  ...props
}: ButtonProps) {
  return (
    <S.Wrapper
      variant={variant}
      size={size}
      disabled={disabled}
      full={full}
      aria-label={props.ariaLabel}
      {...props}
    >
      {loading ? <Spinner aria-label="Loading" /> : children}
    </S.Wrapper>
  );
}
