import styled from 'styled-components';

import { Button, theme } from '@gympass/yoga';

import media from '../../theme/media';

export const Link = styled(Button.Link)`
  color: var(--primary);
  font-weight: ${theme.fontWeights.medium};

  :active,
  :not([disabled]):focus {
    color: var(--primary);
    opacity: 0.75;
  }

  :not([disabled]):target {
    color: var(--primary);
    opacity: 0.5;
  }

  :not([disabled]):hover {
    color: var(--primary-light);
  }

  ${({ tiny }) => `
    font-size: ${tiny && '12px'};
  `}

  ${media.desktop`
    text-align: left;
  `};
`;
