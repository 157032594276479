import styled from 'styled-components';

import { theme, Button, Card, Text } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';

export const Container = styled.div<{
  isShow?: boolean;
}>`
  display: ${({ isShow }) => (isShow ? 'block' : 'none')};
  max-width: 807px;
  margin: 0 auto;
  width: 100%;
  padding-top: ${theme.spacing.xxlarge}px;
  ${media.not.md`
    display: none;
  `};
`;

export const ProgramBody = styled.div`
  max-width: 807px;
  margin: 0 auto;
  padding-top: ${theme.spacing.xxlarge}px;
  padding-bottom: ${theme.spacing.xxlarge}px;
  width: 100%;
  z-index: 2;
  ${media.not.md`
    padding-bottom: ${theme.spacing.small}px;
    padding-top:0px;
  `}
`;

export const BackButton = styled(Button.Link)`
  color: ${theme.colors.white};
  font-size: ${theme.fontSizes.small}px;
  font-weight: ${theme.fontWeights.normal};
  :hover {
    text-decoration-line: underline;
    color: ${theme.colors.white} !important;
  }
`;

/** @knipignore */ // TODO: day zero adding knip to this project, remove me
export const ContentJourney = styled.div<{
  isEmpty?: boolean;
}>`
  width: 100%;
  min-height: 25vh;
  height: auto;
  overflow: hidden;
  background-image: ${({ isEmpty }) =>
    isEmpty
      ? 'linear-gradient(76.4deg, #FFF4E1 0%, #FFC382 100%)'
      : 'linear-gradient(to top right, #710252, #b15991)'};
  position: relative;
  z-index: 1;
`;

export const CardProgram = styled(Card)`
  width: 100%;
  padding: ${theme.spacing.medium}px;
  ${media.not.md`
    padding: ${theme.spacing.small}px;
  `}
`;

export const ProgramHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${theme.spacing.xxsmall}px;
  ${media.not.md`
    flex-direction: column-reverse;
    align-items: start;
  `}
`;

export const Program = styled.div``;

export const ProgramTitle = styled(Text.SectionTitle)`
  padding: ${theme.spacing.small}px 0px;
  font-weight: ${theme.fontWeights.medium};
  text-transform: uppercase;
`;

export const ToggleButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: ${theme.spacing.zero}px ${theme.spacing.medium}px
    ${theme.spacing.zero}px ${theme.spacing.medium}px;
`;
