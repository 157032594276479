import styled from 'styled-components';

import { Input, theme } from '@gympass/yoga';

import media from '../../theme/media';

interface ContainerProps {
  limitSpace?: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;

  padding: ${theme.spacing.xxsmall}px 0
    ${props =>
      !props.limitSpace ? theme.spacing.xsmall : theme.spacing.xxxsmall}px
    0;

  ${({ limitSpace }) => media.desktop`
    padding: ${theme.spacing.xxsmall}px 0
    ${!limitSpace ? theme.spacing.large : theme.spacing.xxxsmall}px 0;
  `};

  & > * > fieldset {
    background: var(--input-background);
  }

  input {
    top: 0px;
  }
`;

export const StyledInput = styled(Input)`
  fieldset {
    background: var(--input-background);
  }

  :disabled {
    color: var(--text-disabled);
  }
`;

export const StyledPassword = styled(Input.Password)`
  fieldset {
    background: var(--input-background);
  }

  :disabled {
    color: var(--text-disabled);
  }
`;

export const StyledNumber = styled(Input.Number)`
  fieldset {
    background: var(--input-background);
  }

  :disabled {
    color: var(--text-disabled);
  }
`;
