import styled from 'styled-components';

import { theme, Box, Text } from '@gympass/yoga';

export const AvatarBulletRoot = styled(Box).attrs({
  display: 'flex',
  borderRadius: 'circle',
})`
  background-color: var(--secondary-peach);
  width: ${theme.spacing.xxlarge}px;
  height: ${theme.spacing.xxlarge}px;
`;
export const AvatarBulletText = styled(Text.Bold).attrs({
  size: 'xlarge',
})`
  color: var(--secondary);
  margin: auto;
`;
