import styled from 'styled-components';

import { theme, Card, Text } from '@gympass/yoga';
import media from 'theme/media';

import AvatarBullet from 'components/AvatarBullet';

export const RescueCardRoot = styled(Card)`
  text-align: center;

  ${media.desktop`
    text-align: initial;
    display: flex;
    align-items: flex-start;
  `};
`;

export const RescueCard = styled.div`
  flex: 1;
`;

export const RescueCardHeaderBullet = styled(AvatarBullet)`
  margin: ${theme.spacing.zero} auto ${theme.spacing.small}px auto;

  ${media.desktop`
    margin: initial;
    display: inline-flex;
  `}
`;

export const RescueCardHeaderText = styled(Text.Bold)`
  ${media.desktop`
    margin-left: ${theme.spacing.small}px;
    display: inline-block;
  `}
`;

export const RescueCardContent = styled(Card.Content)`
  margin-top: ${theme.spacing.small}px;
  margin-bottom: ${theme.spacing.small}px;

  ${media.desktop`
    margin-bottom: initial;
    padding-left: ${theme.spacing.xxxlarge}px;
  `}
`;

export const RescueCardContentHelper = styled(Text.Tiny)`
  margin-top: ${theme.spacing.xxsmall}px;
`;

export const RescueCardContentHelperLink = styled.button`
  background-color: transparent;
  padding: ${theme.spacing.zero};
  font-size: inherit;
  font-weight: bold;

  &:not(:hover) {
    text-decoration: underline;
  }
`;
