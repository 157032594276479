import styled from 'styled-components';

import { LoadingProps } from './types';

export const Center = styled.div<LoadingProps>`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100vh;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${({ containerOpacity }) =>
    `rgba(255, 255, 255, ${containerOpacity || '0.7'})`};
  z-index: 9999;
`;

export const WrapperLottie = styled.div`
  width: 128px;
  height: 128px;
`;
