import { datadogLogs } from '@datadog/browser-logs';
import { QueryFunctionContext } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { getLogger } from 'libraries/logger';

import { getAuthorizationTokens } from 'services/utils';

import { PatientIntakeForm } from 'pages/PatientIntake';

import { api, careMatrix, wellzcareSms } from '../api';

import {
  ActivitiesTopicsSection,
  CarePathProps,
  EndUserSpecialistInfo,
  FeedbackProps,
  LinkTCMessageType,
  ReasonsGroup,
  MatchSurvey,
  MatchSurveyStatus,
  GetCareProfileResultsResponse,
  ReassessmentStatus,
  AssessmentResultsProps,
  GetUserRecommendedAndJourneyTopicsResponse,
  CareMatrixTaskExecutionsResponse,
  TopicSection,
  ActivitiesTrackingParams,
  ReminderConfigResponse,
  Preferences,
} from './types';

import {
  ProgramsResponse,
  NextBooking,
  NextSession,
  Booking,
  Session,
  JoinSession,
  Feedback,
  FeedbackV2,
  TopicPage,
  Program,
  User,
  UserData,
  UserLink,
} from '.';

const logger = getLogger();

async function getUserTask(ohMyFormID: string) {
  try {
    const { data } = await api.post('/v1/users/assessments/tasks', {
      ohMyFormID,
    });
    return data;
  } catch (err) {
    logger.error('userTasks e', err);
    return { error: (err as AxiosError)?.response?.status !== 404 };
  }
}

async function getUserAssessmentResults(): Promise<AssessmentResultsProps> {
  try {
    const { data } = await api.get(`/v2/users/assessments/results`);
    return data;
  } catch (err) {
    logger.error('getUserAssessmentResults e', err);
    return [];
  }
}

async function getUserReassessmentStatus(): Promise<ReassessmentStatus> {
  try {
    const { data } = await api.get(`/v1/users/workflows/reassessment/status`);

    return data as ReassessmentStatus;
  } catch (err) {
    logger.error('getUserReassessmentStatus e', err);
    return {
      status:
        (err as AxiosError)?.response?.status === 404 ? 'not-found' : 'error',
    } as ReassessmentStatus;
  }
}

function nextBookingToSession(item: NextBooking): NextSession {
  return {
    timekit_id: item?.specialist?.timekit_id,
    name: item?.specialist?.full_name,
    avatar: item?.specialist?.avatar?.length
      ? item.specialist.avatar[0].url
      : undefined,
    start: item?.booking?.event?.start,
    end: item?.booking?.event?.end,
    booking_id: item?.booking?.id,
    new_booking_allowed: item?.new_booking_allowed,
    next_booking_from: item?.next_booking_from,
    credit_left: item.credit_left,
    total_credits: item.total_credits,
    credits_used: item.credits_used,
  };
}

function bookingToSession(item: Booking, idx: number): Session {
  return {
    start: item?.start_at,
    number: `${idx}`,
    status: item?.status,
  };
}

async function getUserNextSession(): Promise<NextSession> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(
        `/v1/users/next-session?timezone=${encodeURIComponent(
          Intl.DateTimeFormat().resolvedOptions().timeZone,
        )}`,
      );
      resolve(nextBookingToSession(data));
    } catch (err) {
      if ((err as AxiosError)?.response?.status !== 404) {
        return reject(err);
      }
      resolve(
        nextBookingToSession({ new_booking_allowed: true } as NextBooking),
      );
    }
  });
}

async function getUserSessionHistory(): Promise<Session[]> {
  try {
    const { data } = await api.get(
      `/v1/users/sessions?timezone=${encodeURIComponent(
        Intl.DateTimeFormat().resolvedOptions().timeZone,
      )}`,
    );

    return data
      .map((b: Booking, idx: number) => bookingToSession(b, idx))
      .reverse();
  } catch (err) {
    logger.error('getUserSessionHistory error', err);
    return [];
  }
}

async function getJoinSessionUrl(): Promise<JoinSession> {
  try {
    const { data } = await api.get(
      `/v1/users/join-session?timezone=${encodeURIComponent(
        Intl.DateTimeFormat().resolvedOptions().timeZone,
      )}`,
    );
    return data as JoinSession;
  } catch (err) {
    logger.error('getJoinSessionUrl error', err);
    return {};
  }
}

function cancelSession(bookingId: string): Promise<void> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      await api.delete(`/v1/users/bookings/${bookingId}`);
      resolve();
    } catch (err) {
      logger.error('cancelSession error', err);
      reject();
    }
  });
}

async function createPatientIntake(
  patientIntake: PatientIntakeForm,
): Promise<void> {
  try {
    const { data } = await api.post(`/v1/users/patient-intake`, patientIntake);
    return data;
  } catch (err) {
    logger.error('getJoinSessionUrl error', err);
  }
}

async function registerJourneySelectionPageAccess(): Promise<void> {
  try {
    await api.post(`/v1/users/action/journey-selection-visited`);
  } catch (err) {
    logger.error('registerJourneySelectionPageAccess error', err);
  }
}

async function saveCarePath(carePath: CarePathProps): Promise<void> {
  try {
    const { data } = await api.post(`/v1/users/care-path`, carePath);
    return data;
  } catch (err) {
    logger.error('saveCarePath error', err);
  }
}

async function getUserCarePath(): Promise<User | void> {
  try {
    const { data } = await api.get('/v1/users/care-path');
    return data;
  } catch (err) {
    logger.error('getUserCarePath', err);
  }
}

async function getUserCriticality(): Promise<User | void> {
  try {
    const { data } = await api.get('/v1/users/criticality');
    return data;
  } catch (err) {
    logger.error('getUserCriticality', err);
  }
}

async function enrollUserToProgram(): Promise<void> {
  try {
    await api.post(`/v1/users/enrollments`);
  } catch (err) {
    logger.error('enrollUserToProgram error', err);
  }
}

async function createFeedback(feedback: FeedbackProps): Promise<void> {
  try {
    const { data } = await api.post(`/v1/users/feedbacks`, feedback);
    return data;
  } catch (err) {
    logger.error('createFeedback error', err);
  }
}

/**
 * @returns {Promise<Feedback | 404>} error 404 happens when users have no pending feedback
 */
async function getPendingFeedback(): Promise<Feedback | 404> {
  const { data, status } = await api.get(`/v1/users/feedbacks/pending`, {
    validateStatus: status => (status >= 200 && status < 300) || status === 404,
  });

  if (status === 404) {
    return 404;
  }

  return data as Feedback;
}

async function getPendingFeedbackV2(): Promise<FeedbackV2> {
  try {
    const { data } = await api.get(`/v2/users/feedbacks/pending`);
    return data as FeedbackV2;
  } catch (err) {
    logger.error('getPendingFeedbackv2 error', err);
    return {} as FeedbackV2;
  }
}

async function getJourney(): Promise<ProgramsResponse> {
  return getUserPrograms(true, 500, true);
}

async function getRecommendedTopics(): Promise<ProgramsResponse> {
  return getUserPrograms(false, 500, true, false, 'deliver_date', 'desc');
}

async function getRecommendedTopicsHistory(
  limit = 4,
): Promise<ProgramsResponse> {
  return getUserPrograms(false, limit, true, true, 'deliver_date', 'desc');
}

async function getUserPrograms(
  enrolled: boolean,
  limit: number,
  delivered = true,
  ack?: boolean,
  sort?: string,
  order?: string,
): Promise<ProgramsResponse> {
  try {
    const params = new URLSearchParams({
      enrolled: enrolled.toString(),
      limit: limit.toString(),
      delivered: delivered.toString(),
    });

    if (typeof ack === 'boolean') params.set('ack', ack.toString());
    if (sort) params.set('sort', sort);
    if (order) params.set('order', order);

    const { data } = await api.get('/v1/users/programs', {
      params,
    });
    return data as ProgramsResponse;
  } catch (err) {
    return {} as ProgramsResponse;
  }
}

async function getPagesTopic(topicId: number): Promise<TopicPage[]> {
  try {
    const { data } = await api.get(`/v1/users/topics/${topicId}/pages`);
    return data as TopicPage[];
  } catch (err) {
    return [] as TopicPage[];
  }
}

async function getPagesByTopic({ queryKey }: QueryFunctionContext) {
  const [, topicId] = queryKey;

  const { data, status } = await api.get(`/v1/users/topics/${topicId}/pages`, {
    validateStatus: status => (status >= 200 && status < 300) || status === 404,
  });

  if (status === 404) return [] as TopicPage[];

  return data as TopicPage[];
}

async function getUserRecommendedAndJourneyTopics(): Promise<ActivitiesTopicsSection> {
  try {
    const {
      data: { recommendedTopics, journeyTopic },
    } = await api.get<GetUserRecommendedAndJourneyTopicsResponse>(
      '/v1/users/topics/next-topics',
    );

    const recommendedTopicsNotByCareMatrix = recommendedTopics?.filter(
      topic => {
        return topic.origin !== 'carematrix';
      },
    );

    return {
      recommendedTopics: recommendedTopicsNotByCareMatrix,
      journeyTopic,
    } as ActivitiesTopicsSection;
  } catch (err) {
    return {} as ActivitiesTopicsSection;
  }
}

async function getUserRecommendedByCareMatrix(): Promise<TopicSection[]> {
  try {
    const { accessToken } = await getAuthorizationTokens();
    const { data } = await careMatrix.get<CareMatrixTaskExecutionsResponse[]>(
      'v1/task-executions',
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    const filteredTasks = data
      .filter(tasks => tasks.type === 'strapi-content')
      .filter(task => !task.finished);

    return filteredTasks.map(task => ({
      topicId: Number(task.attributes.external_task_id),
      deliverTopicId: Number(task.attributes.external_task_execution_id),
      titleKey: task.attributes.title_key,
      progress: task.attributes.progress,
      origin: 'carematrix',
      page: {
        title: task.attributes.activity.title,
        pageType: task.attributes.activity.type,
        id: task.attributes.activity.activity_id,
      },
    }));
  } catch (err) {
    return {} as TopicSection[];
  }
}

async function getProgramById(programId: number | string): Promise<Program> {
  try {
    const { data } = await api.get(`/v1/users/programs/${programId}`);
    return data as Program;
  } catch (err) {
    return {} as Program;
  }
}

async function getUserProgramsHistory(): Promise<Program[]> {
  try {
    const { data } = await api.get('/v1/users/programs/history');
    return data as Program[];
  } catch (err) {
    return {} as Program[];
  }
}

async function getUserInfo() {
  try {
    const { data } = await api.get<UserData>('/v1/users/me');
    return data;
  } catch (err) {
    throw new Error('ERROR.GET.USER');
  }
}

async function patchUser(userData: User): Promise<void> {
  try {
    return await api.patch('/v1/users/', userData);
  } catch (err) {
    throw new Error('ERROR.UPDATE.USER');
  }
}

async function getNewBookedSessions(): Promise<Number | void> {
  try {
    const { data } = await api.get('/v1/users/sessions/count/confirmed');
    return data.sessions_count;
  } catch (err) {
    logger.error((err as Error).message);
  }
}

async function getEndUserActiveSpecialistInfo(): Promise<EndUserSpecialistInfo> {
  try {
    const { data } = await api.get('v1/users/me/specialist');
    return data as EndUserSpecialistInfo;
  } catch (err) {
    return {} as EndUserSpecialistInfo;
  }
}

async function assignTherapyCompanion(
  message?: LinkTCMessageType,
): Promise<number> {
  try {
    const { status } = await api.post('v1/users/specialist/link-tc', null, {
      params: { 'message-type': message },
    });
    return status;
  } catch (err) {
    throw new Error('ERROR.ASSIGN.TC');
  }
}

async function hasUserpermissionToAccessAgenda(
  toolkitID: string,
): Promise<number> {
  try {
    const { status } = await api.get(
      `v1/users/booking/available/specialists/${toolkitID}`,
    );
    return status;
  } catch (err) {
    throw new Error('ERROR.CHECK.USER');
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- day zero of no errors, remove and fix me when you see me in a code review
async function updateUserNationalId(nationalId: string): Promise<any> {
  try {
    return await api.patch('/v1/users/profile/national-id', {
      national_id: nationalId,
    });
  } catch (err) {
    const { response } = err as AxiosError;
    return response;
  }
}

async function postEventAudioPlayer(): Promise<void> {
  try {
    const { data } = await api.post(
      `/v1/users/audio-tracking/events/last-played`,
    );
    return data;
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

async function getDischargeReasons(): Promise<ReasonsGroup[]> {
  try {
    const { data } = await api.get(`v1/userlink/discharge-reasons`);
    return data as ReasonsGroup[];
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

async function updateUserLink({
  discharge_reason,
  discharge_description,
}: UserLink): Promise<void> {
  try {
    await api.patch(`/v1/userlink/users/unlink`, {
      discharge_reason,
      ...(discharge_description ? { discharge_description } : undefined),
    });
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

async function getMatchSurvey(): Promise<MatchSurvey> {
  try {
    const { data } = await api.post(
      '/v1/users/cp-match-preferences/assessments',
    );
    return data;
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

async function getMatchSurveyStatus(
  assessment_id: string | null,
): Promise<boolean | undefined> {
  try {
    await api.get(`/v1/users/cp-match-preferences/${assessment_id}/status`);

    return true;
  } catch (err) {
    logger.error('getMatchSurveyStatus', err);
  }
}

async function getMatchUserStatus(): Promise<MatchSurveyStatus> {
  try {
    const { data } = await api.get('/v1/users/cp-match-preferences/status');
    return data;
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

async function getCareProfileResults(): Promise<GetCareProfileResultsResponse> {
  try {
    const { data } = await api.get('/v1/users/workflows/profile');
    return data;
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

type GetNextPagesByTopicProps = {
  topicId?: number;
  currentPage: number;
};

async function getNextPageByTopic({
  topicId,
  currentPage,
}: GetNextPagesByTopicProps) {
  try {
    const { data } = await api.get<TopicPage[]>(
      `/v1/users/topics/${topicId}/pages`,
    );

    const nextPage = data.find(page => {
      return page.acknowledge === false && page.id !== Number(currentPage);
    });

    if (!nextPage) {
      return null;
    }

    return nextPage;
  } catch (err) {
    datadogLogs.logger.error(
      `[getNextPageByTopic] Error while trying to get next page`,
      {
        currentPage,
        topicId,
      },
      err as Error,
    );
  }
}
async function postRequestAccountDelete() {
  try {
    await api.post('/v1/users/request/delete', {
      date: new Date().toISOString(),
      deviceBrand: navigator.platform,
      deviceName: navigator.appVersion,
      osVersion: navigator.platform,
    });
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

interface GetTherapyCompanionOptionResponse {
  type: 'tc_journey' | 'tc_auto_journey';
}

async function getTherapyCompanionOption() {
  try {
    const { data } = await api.get<GetTherapyCompanionOptionResponse>(
      '/v1/users/therapy-companion/option',
    );

    return data;
  } catch (error) {
    throw new Error((error as Error).message);
  }
}

async function createLinkWithTherapyCompanion(message: string) {
  try {
    const { data } = await api.post('v1/users/therapy-companion/link', {
      user_chat_message: message,
    });

    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data);
    }
  }
}

async function registerPhone(value: string) {
  await api.patch('/v3/users/phone-number', {
    phone_number: value,
  });
}

async function verifyPhoneNumber(code: string) {
  await api.post('/v3/users/phone-number/verify', {
    code,
  });
}

async function postUserActivitiesTracking(
  trackingParams: ActivitiesTrackingParams,
) {
  await api.post(
    `/v1/user-activities/tracking${
      trackingParams?.first_play ? '?first_play=true' : ''
    }`,
    trackingParams,
  );
}

async function getReminderConfig() {
  try {
    const { data, status } = await wellzcareSms.get<ReminderConfigResponse>(
      '/v1/users/engagement-reminder-config',
    );

    data.status = status;

    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        return { status: error.response.status } as ReminderConfigResponse;
      }
      throw new Error(error.response?.data);
    }
  }
}

async function updateReminderConfig({
  remind_limit_days,
  content,
}: {
  remind_limit_days?: number;
  content?: string;
}) {
  try {
    await wellzcareSms.put('/v1/users/engagement-reminder-config', {
      remind_limit_days,
      content,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data);
    }
  }
}

async function createReminderConfig(limitDays: number) {
  try {
    await wellzcareSms.post('/v1/users/engagement-reminder-config', {
      remind_limit_days: limitDays,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data);
    }
  }
}

async function getUserPreferences() {
  const { data } = await api.get<Preferences>('/v2/users/preferences');
  return data;
}

export {
  createFeedback,
  getUserTask,
  getUserAssessmentResults,
  getUserNextSession,
  getUserSessionHistory,
  getJoinSessionUrl,
  getPendingFeedback,
  getPendingFeedbackV2,
  cancelSession,
  createPatientIntake,
  registerJourneySelectionPageAccess,
  saveCarePath,
  getUserCarePath,
  getUserCriticality,
  enrollUserToProgram,
  getJourney,
  getPagesTopic,
  getUserRecommendedAndJourneyTopics,
  getRecommendedTopics,
  getRecommendedTopicsHistory,
  getUserPrograms,
  getProgramById,
  getUserProgramsHistory,
  getUserInfo,
  patchUser,
  getNewBookedSessions,
  getEndUserActiveSpecialistInfo,
  assignTherapyCompanion,
  hasUserpermissionToAccessAgenda,
  updateUserNationalId,
  postEventAudioPlayer,
  getDischargeReasons,
  updateUserLink,
  getMatchSurvey,
  getMatchSurveyStatus,
  getMatchUserStatus,
  getCareProfileResults,
  getUserReassessmentStatus,
  getPagesByTopic,
  getUserRecommendedByCareMatrix,
  getNextPageByTopic,
  postRequestAccountDelete,
  getTherapyCompanionOption,
  createLinkWithTherapyCompanion,
  registerPhone,
  verifyPhoneNumber,
  postUserActivitiesTracking,
  getReminderConfig,
  updateReminderConfig,
  createReminderConfig,
  getUserPreferences,
};
