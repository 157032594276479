import { events, properties, writableProperties } from 'atoms/audio/constants';
import { Getter, Setter, atom, getDefaultStore } from 'jotai';
import isWebview from 'utils/isWebview';
import triggerWebviewEvent from 'utils/triggerWebviewEvent';
import { v4 as uuidv4 } from 'uuid';

import { ActivitiesTrackingOrigin, TopicPage } from 'services/user';
import { postUserActivitiesTracking } from 'services/user/user';

import { finishedAudioCardTodayAtom } from 'components/DailyTaskCards/atoms';

// eslint-disable-next-line import-helpers/order-imports
import { AudioTypes, ControlsAtom, ControlsStateAtom } from 'atoms/audio/types';
// eslint-disable-next-line import-helpers/order-imports
import { isAudioOrMeditation } from '../../components/Player/utils';

const srcToControlsAtom = atom({} as Record<string, ControlsAtom>);

async function handlePlayAudioDailyTask() {
  if (window.location.pathname.includes('home')) {
    const today = new Date();
    getDefaultStore().set(finishedAudioCardTodayAtom, today.toISOString());
  }
}

export const audioPlayerAtom = atom(
  undefined as AudioTypes | undefined,
  (get, set, payload: AudioTypes | TopicPage | undefined) => {
    if (isWebview()) {
      return triggerWebviewEvent('audioPlayer', payload);
    }
    const payloadWithSession = payload
      ? { ...payload, session_id: uuidv4() }
      : undefined;
    const previewAudio = get(audioPlayerAtom);
    const origin = get(audioOriginAtom);
    const lastControls = previewAudio ? get(controlsAtom) : undefined;
    if (
      previewAudio &&
      payload !== undefined &&
      isAudioOrMeditation(previewAudio)
    ) {
      postUserActivitiesTracking({
        page_id: String(previewAudio?.documentId ?? previewAudio?.id),
        name: previewAudio?.title,
        type: previewAudio?.activity_type || previewAudio?.page_type,
        progress: lastControls && get(lastControls?.currentTimeAtom),
        total: lastControls && get(lastControls?.durationAtom),
        session_id: previewAudio.session_id,
        origin,
      });
    }

    set(audioSrcAtom, payload?.audio_file?.url || '');
    if (!payload) {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.hash.slice(1));
      if (params.has('audioId')) {
        params.delete('audioId');
        url.hash = params.toString();
        window.history.replaceState({}, '', url);
      }

      set(isOpenPlayerAtom, false);
    } else {
      const url = new URL(window.location.href);
      const id = String(payload.documentId ?? payload.id);
      if (!url.hash) {
        url.hash = `audioId=${id}`;
      } else {
        const params = new URLSearchParams(url.hash.slice(1));
        params.set('audioId', id);
        url.hash = params.toString();
      }
      window.history.replaceState({}, '', url);
    }

    set(audioPlayerAtom, payloadWithSession);
    handlePlayAudioDailyTask();
  },
);
export const isOpenPlayerAtom = atom(false);

export const audioSrcAtom = atom(
  undefined as unknown as string,
  (get, set, newSrc: string, { resetCurrentTime = true } = {}) => {
    const hasPreviousAudio = get(audioSrcAtom) !== undefined;

    const lastControls = hasPreviousAudio ? get(controlsAtom) : undefined;

    if (lastControls && !lastControls.audio.paused) {
      lastControls.audio.pause();
    }

    set(audioSrcAtom, newSrc);

    const controls =
      get(srcToControlsAtom)[newSrc] ?? createControls(newSrc, { set, get });

    const previousVolume =
      lastControls?.audio.volume ??
      parseFloat(localStorage.getItem('volume') ?? '1');

    const previousMuted =
      lastControls?.audio.muted ??
      (JSON.parse(localStorage.getItem('muted') ?? 'false') as boolean);

    set(controls.volumeAtom, previousVolume);

    set(controls.mutedAtom, previousMuted);

    if (resetCurrentTime) {
      set(controls.currentTimeAtom, 0);
    }
  },
);

function createControls(
  src: string,
  { set, get }: { set: Setter; get: Getter },
) {
  const audio = new Audio(src);

  const newControlStateAtom = {} as ControlsStateAtom;
  for (let i = 0; i < properties.length; i += 1) {
    const property = properties[i];
    // @ts-expect-error
    newControlStateAtom[`${property}Atom`] = atom(
      audio[property],
      (_get, set, newValue, { event }: { event?: Event } = {}) => {
        // @ts-expect-error
        set(newControlStateAtom[`${property}Atom`], newValue);
        if (
          writableProperties.has(property) &&
          audio[property] !== newValue &&
          !event // event is not present when we set a new value from atom hook, for example `setVolume(0.5)`
        ) {
          // @ts-expect-error
          audio[property] = newValue;
        }
      },
    );
  }

  // .play() returns a promise that is resolve as soon as there are enough data to play
  // when .pause() is called and the promise returned has't been resolved yet
  // the promise will be rejected with an AbortError which is okay for us
  // https://goo.gl/LdLk22
  function newPlay(this: HTMLAudioElement) {
    return HTMLAudioElement.prototype.play.call(this).catch(e => {
      if (e.name === 'AbortError') {
        return;
      }
      throw e;
    });
  }
  audio.play = newPlay;

  // the logic implementation came from https://stackoverflow.com/a/46117824
  const isLoadingToPlayAtom = atom(
    get =>
      !get(newControlStateAtom.pausedAtom) &&
      !get(newControlStateAtom.endedAtom) &&
      get(newControlStateAtom.readyStateAtom) <=
        HTMLMediaElement.HAVE_CURRENT_DATA,
  );
  const isSoundingAtom = atom(
    get =>
      !get(newControlStateAtom.pausedAtom) &&
      !get(newControlStateAtom.endedAtom) &&
      get(newControlStateAtom.readyStateAtom) >
        HTMLMediaElement.HAVE_CURRENT_DATA,
  );
  const newControlsAtom: ControlsAtom = {
    audio,
    isLoadingToPlayAtom,
    isSoundingAtom,
    ...newControlStateAtom,
  };
  set(srcToControlsAtom, {
    ...get(srcToControlsAtom),
    [src]: newControlsAtom,
  });

  for (let i = 0; i < events.length; i += 1) {
    const eventName = events[i];
    audio.addEventListener(eventName, event => {
      for (let i = 0; i < properties.length; i += 1) {
        const property = properties[i];
        // @ts-expect-error
        set(newControlStateAtom[`${property}Atom`], audio[property], { event });
      }
    });
  }

  audio.addEventListener('volumechange', () => {
    localStorage.setItem('volume', JSON.stringify(audio.volume));
    localStorage.setItem('muted', JSON.stringify(audio.muted));
  });

  return newControlsAtom;
}

export const controlsAtom = atom(
  get => get(srcToControlsAtom)[get(audioSrcAtom)],
);

if (typeof jest === 'undefined') {
  // set controlsAtom to an empty state
  getDefaultStore().set(audioSrcAtom, '');
}

export const audioOriginAtom = atom<undefined | ActivitiesTrackingOrigin>(
  undefined,
);
