import styled, { css } from 'styled-components';

import { Card, Text, theme } from '@gympass/yoga';
import { ChevronUp, ChevronDown } from '@gympass/yoga-icons';

import { Button } from '..';

import media from '../../theme/media';

export const Container = styled.div`
  justify-content: center;
  margin-bottom: ${theme.spacing.large}px;
`;

export const Content = styled(Card)<{
  topSpecialist?: boolean;
}>`
  min-width: 100%;
  max-width: 100%;
  padding: ${theme.spacing.zero};
  box-shadow: var(--shadow-header);
  border-radius: ${theme.spacing.xxsmall}px;
  border: ${({ topSpecialist }) =>
    topSpecialist
      ? css`
          ${theme.spacing.xxsmall}px solid var(--primary)
        `
      : 'none'};
`;

export const TopSpecialist = styled.div`
  margin: ${theme.spacing.zero};
  background-color: var(--primary);
  padding: ${theme.spacing.xxsmall}px ${theme.spacing.xxsmall}px
    ${theme.spacing.small}px ${theme.spacing.xxsmall}px;
  display: flex;
  align-items: center;
  outline: 1px solid var(--primary);
`;

export const StarIcon = styled.img`
  margin-right: ${theme.spacing.xxxsmall}px;
  width: 29px;
  height: 26px;
`;

export const TopSpecialistText = styled(Text.H5)`
  color: var(--white);
  text-transform: uppercase;
`;

export const Main = styled.div`
  padding: ${theme.spacing.large}px;
`;

export const BioPresentationContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.small}px;
  flex-wrap: wrap;
`;

export const Specialist = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${media.desktop`
    width: 30%;
  `}

  ${media.mobile`
    flex-direction: row;
    text-align: left;
  `}
`;

export const Info = styled.div`
  margin: ${theme.spacing.zero};

  ${media.mobile`
    padding-left: ${theme.spacing.small}px;
  `}
`;

export const Name = styled(Text)`
  padding-top: ${theme.spacing.xxsmall}px;
  font-size: ${theme.fontSizes.large}px;
  font-weight: ${theme.fontWeights.medium};
`;

export const Introduction = styled.div`
  padding-top: ${theme.spacing.xxxsmall}px;
`;

export const Professional = styled(Text)`
  color: var(--text-secondary);
  font-size: ${theme.fontSizes.small}px;
`;

export const Localization = styled(Text)`
  color: var(--text-secondary);
  font-size: ${theme.fontSizes.small}px;
`;

export const Experience = styled.div`
  ${media.desktop`
    width: 60%;
    align-self: flex-end;
  `}

  ${media.mobile`
    padding-top: ${theme.spacing.medium}px;
  `}
`;

export const ExperienceList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.xsmall}px;
`;

export const Badge = styled.div<{
  topSpeciality?: boolean;
}>`
  display: flex;
  align-items: center;
  background-color: ${({ topSpeciality }) =>
    topSpeciality ? 'var(--divider)' : 'var(--progress-base-color)'};
  padding: ${theme.spacing.xxxsmall}px ${theme.spacing.xxsmall}px;
  border-radius: ${theme.spacing.xxxsmall}px;
  font-size: ${theme.fontSizes.xsmall}px;
  color: var(--text-secondary);
`;

export const MoreBadges = styled.div`
  display: flex;
  align-items: center;
  background-color: transparent;
  font-size: ${theme.fontSizes.xsmall}px;
  color: var(--text-secondary);
`;

export const StarIconBadge = styled.img`
  margin-right: ${theme.spacing.xxxsmall}px;
  width: 14.64px;
  height: 13.33px;
`;

export const MoreInformationsContent = styled.div<{
  toggle?: boolean;
}>`
  display: ${({ toggle }) => (toggle ? 'flex' : 'none')};
  padding-top: ${theme.spacing.large}px;
  gap: ${theme.spacing.small}px;
  flex-wrap: wrap;
`;

export const Biography = styled.div`
  ${media.desktop`
    width: 30%;
  `}
`;

export const Education = styled.div`
  ${media.desktop`
    width: 60%;
  `}
`;

export const SectionTitle = styled(Text)`
  color: var(--primary);
  font-size: ${theme.fontSizes.medium}px;
  font-weight: ${theme.fontWeights.medium};
  padding-bottom: ${theme.spacing.xxxsmall}px;
`;

export const SectionText = styled(Text)`
  color: var(--text-primary);
  font-size: ${theme.fontSizes.small}px;
  font-weight: ${theme.fontWeights.regular};
  padding-bottom: ${theme.spacing.small}px;

  p {
    margin: ${theme.spacing.zero};
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${theme.spacing.xsmall}px;
  padding: ${theme.spacing.zero} ${theme.spacing.large}px
    ${theme.spacing.large}px ${theme.spacing.large}px;

  ${media.mobile`
    flex-direction: column-reverse;
    justify-content: center;
  `}
`;

export const MoreInformationsButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: ${theme.spacing.xlarge}px;
  font-weight: ${theme.fontWeights.medium};
  color: var(--primary);

  ${media.mobile`
    padding-top: ${theme.spacing.xsmall}px;
  `}
`;

export const StyledChevronUp = styled(ChevronUp)`
  fill: ${theme.colors.deepPurple};
  height: ${theme.spacing.small}px;
  width: ${theme.spacing.small}px;
`;

export const StyledChevronDown = styled(ChevronDown)`
  fill: ${theme.colors.deepPurple};
  height: ${theme.spacing.small}px;
  width: ${theme.spacing.small}px;
`;

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${media.mobile`
    width: 100%;
  `}
`;

export const NextSlot = styled(Text)`
  color: var(--text-secondary);
  font-size: ${theme.fontSizes.xsmall}px;
  padding-bottom: ${theme.spacing.xxxsmall}px;

  ${media.mobile`
    text-align: center;
  `}
`;

export const CalendarButton = styled(Button)`
  height: 54px;
  width: 100%;

  ${media.mobile`
    height: ${theme.spacing.xxlarge}px;
    width: 100%;
  `}
`;
