import styled from 'styled-components';

import { Text, theme } from '@gympass/yoga';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';

import media from '../../theme/media';

export const List = styled.div``;

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${theme.spacing.small}px 0;

  ${media.desktop`
    padding: 26px ${theme.spacing.medium}px;
  `}
`;

export const SessionLabel = styled.span`
  display: flex;
  flex-direction: column;

  ${media.desktop`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `}
`;

export const IconChecked = styled.i`
  color: var(--success);
  font-size: 20px;
  padding: 2px;
  margin-right: ${theme.spacing.xxxsmall}px;
  align-self: flex-start;
`;

export const IconPause = styled(PauseCircleOutlineIcon)`
  color: var(--secondary);
  font-size: 20px;
  padding: 2px;
  margin-right: ${theme.spacing.xxxsmall}px;
  align-self: flex-start;
`;

export const IconClose = styled(HighlightOffIcon)`
  color: var(--secondary);
  font-size: 20px;
  padding: 2px;
  margin-right: ${theme.spacing.xxxsmall}px;
  align-self: flex-start;
`;

export const Label = styled(Text)`
  color: var(--text-primary);
  text-transform: uppercase;
  font-weight: ${theme.fontWeights.medium};
`;

export const SessionLabelContainer = styled.span`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const DateText = styled(Text.Small)`
  color: var(--text-primary);
`;

export const StatusText = styled(Text.Tiny)`
  color: var(--text-secondary);
`;

export const Divider = styled.div`
  border-top: 1px solid var(--divider);
`;

export const DividerPlaceholder = styled(Divider)`
  margin: 16px 0;
`;
