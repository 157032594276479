import styled from 'styled-components';

import media from 'theme/media';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
`;

export const CustomStyleHeader = styled.div<{
  withCustomStyleHeader?: boolean;
}>`
  ${props =>
    props.withCustomStyleHeader &&
    media.desktop`
 `}
`;
