import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { ThemeProvider } from '@gympass/yoga';
import { wellzTheme } from '@wellz-theme/mui';
import WithAuthAmplify from 'hocs/AmplifyAuthentication/withAuthAmplify';
import FlagsSync from 'hocs/UnleashFlagsSync/FlagsSync';
import {
  IntlShape,
  RawIntlProvider,
  createIntl,
  createIntlCache,
} from 'react-intl';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  matchPath,
} from 'react-router-dom';

import { UserInfo } from 'services/user';

import { WebviewAware } from 'components/WebviewAware';

import { AlertBar, TabsMenu } from './components';
import { AppProvider } from './components/AppProvider/AppProvider';
import { AlertEvent, AlertProvider } from './context/AlertContext';
import { ExportsConfigProvider } from './context/ExportsConfigContext';
import { NotificationProvider } from './context/NotificationContext';
import { SessionProvider } from './context/SessionContext';
import { UserProvider } from './context/UserContext';
import { useVerifyMobile } from './hooks';
import { useAudioDeepLink } from './hooks/useAudioDeepLink';
// eslint-disable-next-line import-helpers/order-imports
import useTranslations from './hooks/useTranslation/useTranslation';

/* Global Styles */
import './assets/styles/fonts.css';
import './assets/styles/global.css';
import './assets/styles/normalize.css';

/* Theme variables */
import { Route as RouteProps, routes } from './routes';
import { Config, getEndpointExports } from './services/api';
import { yogaTheme } from './theme';
import media from './theme/media';
import './theme/variables.css';
import { DatadogInitRum, init, setDatadogRumUser } from './utils/datadogRum';
import isWebview from './utils/isWebview';
import { initializeHotjar, setupUserIdHotjar } from './utils/tracker';

// eslint-disable-next-line import/order
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

window.addEventListener('beforeinstallprompt', event => {
  event.preventDefault();
});

const intlCache = createIntlCache();

const App: React.FC = () => {
  const [user, setUser] = useState<UserInfo | null>(null);
  const [loadingUser, isLoadingUser] = useState(true);
  const [session, setSession] = useState(null);
  const [alert, setAlert] = useState<AlertEvent | null>(null);
  const [hasNotification, setHasNotification] = useState(false);
  const [showTabsMenu, setShowTabsMenu] = useState(false);
  const [exportsConfig, setExportsConfig] = useState<Config | null>(null);
  const [isMobile] = useVerifyMobile();
  const isFromWebview = isWebview();

  const { getTranslation, getDefaultTranslations, language } =
    useTranslations('pt');
  const [intl, setIntl] = useState<IntlShape>(
    createIntl(
      {
        locale: language,
        onError() {},
      },
      intlCache,
    ),
  );

  useEffect(() => {
    const getMessages = async () => {
      const messages = await getTranslation();
      setIntl(
        createIntl(
          {
            locale: language,
            messages,
          },
          intlCache,
        ),
      );
    };

    setDefaultTranslation();
    getMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDefaultTranslation = async () => {
    const messages = await getDefaultTranslations();
    setIntl(
      createIntl(
        {
          locale: language,
          messages,
        },
        intlCache,
      ),
    );
  };

  useEffect(() => {
    function verifyShowTabsMenu(userData: UserInfo, allRoutes: RouteProps[]) {
      const matchRoute = allRoutes.find(route => {
        return matchPath(window.location.pathname, {
          path: route.path,
          exact: true,
          strict: false,
        });
      });

      return !!userData?.name && matchRoute?.withTabsMenu;
    }

    setShowTabsMenu(verifyShowTabsMenu(user as UserInfo, routes) as boolean);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, window.location.pathname]);

  useEffect(() => {
    async function initDatadogRum() {
      const {
        ddAppId,
        ddClientId,
        env,
        caishenUrl,
        apiUrl,
        careMatrixUrl,
        luliskitApiUrl,
        strapiUrl,
        wellzAccountsUrl,
      } = await getEndpointExports();
      const datadogParams: DatadogInitRum = {
        ddAppId,
        ddClientId,
        env,
        caishenUrl,
        apiUrl,
        careMatrixUrl,
        luliskitApiUrl,
        strapiUrl,
        wellzAccountsUrl,
      };
      init(datadogParams);
    }

    initDatadogRum();
  }, []);

  useEffect(() => {
    async function initEndpointExports() {
      const config: Config = await getEndpointExports();
      setExportsConfig(config);
    }

    initEndpointExports();
  }, []);

  useEffect(() => {
    setDatadogRumUser(user?.username, user?.specialist);
  }, [user]);

  useEffect(() => {
    initializeHotjar();

    if (user?.username) {
      setupUserIdHotjar(user);
    }
  }, [user]);

  useAudioDeepLink();

  return (
    <UserProvider value={{ user, loadingUser, setUser, isLoadingUser }}>
      <SessionProvider value={{ session, setSession }}>
        <NotificationProvider value={{ hasNotification, setHasNotification }}>
          <AlertProvider value={{ alert, setAlert }}>
            <ExportsConfigProvider value={{ exportsConfig, setExportsConfig }}>
              <RawIntlProvider value={intl}>
                <MuiThemeProvider theme={wellzTheme}>
                  <ThemeProvider theme={yogaTheme}>
                    <Content
                      withTabsMenu={showTabsMenu && isMobile && !isFromWebview}
                    >
                      <WebviewAware>
                        <Switch>
                          {routes.map((r: RouteProps, idx: number) => (
                            <Route
                              key={`${r.path}-${idx}`}
                              exact={r.exact}
                              path={r.path}
                              render={r.render}
                              component={r.component}
                            />
                          ))}
                        </Switch>
                      </WebviewAware>
                    </Content>
                    {showTabsMenu && <TabsMenu />}
                    <AlertBar />
                  </ThemeProvider>
                </MuiThemeProvider>
              </RawIntlProvider>
            </ExportsConfigProvider>
          </AlertProvider>
        </NotificationProvider>
      </SessionProvider>
    </UserProvider>
  );
};

interface ContentProps {
  withTabsMenu: boolean;
}

const Content = styled.div<ContentProps>`
  display: flex;
  height: ${({ withTabsMenu }) =>
    withTabsMenu ? 'calc(100vh - var(--tab-menu-height))' : '100vh'};

  margin-bottom: ${({ withTabsMenu }) =>
    withTabsMenu && 'var(--tab-menu-height)'};

  ${media.desktop`
      margin-bottom: unset;
  `}
`;

// eslint-disable-next-line react/display-name
export default () => (
  <AppProvider>
    <Router>
      <WithAuthAmplify>
        <FlagsSync>
          <App />
        </FlagsSync>
      </WithAuthAmplify>
    </Router>
  </AppProvider>
);
