import styled from 'styled-components';

import { Text, theme } from '@gympass/yoga';

import { Button } from '../../ButtonLegacy';

export const Container = styled.div`
  margin: ${theme.spacing.zero};
  width: 100%;
`;

export const Image = styled.img`
  margin-top: ${theme.spacing.xlarge}px;
  margin-bottom: ${theme.spacing.medium}px;
  width: ${theme.spacing.xhuge}px;
  height: ${theme.spacing.xhuge}px;
`;

export const Title = styled(Text.H5)`
  font-weight: ${theme.fontWeights.bold};
  margin-top: ${theme.spacing.zero};
  margin-bottom: ${theme.spacing.medium}px;
`;

export const Description = styled(Text.Small)`
  color: var(--text-primary);
  margin-bottom: ${theme.spacing.medium}px;
  font-weight: ${theme.fontWeights.regular};
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin-bottom: ${theme.spacing.xsmall}px;
`;

export const HoursInfo = styled.div`
  margin-bottom: ${theme.spacing.medium}px;
`;

export const Hour = styled(Text.Medium)`
  display: flex;
  justify-items: center;
  font-weight: ${theme.fontWeights.regular};
  margin-bottom: ${theme.spacing.xxsmall}px;
`;

export const HourIcon = styled.img`
  width: ${theme.spacing.medium}px;
  height: ${theme.spacing.medium}px;
  margin-right: ${theme.spacing.xsmall}px;
`;

export const Days = styled(Text.Medium)`
  display: flex;
  justify-items: center;
  margin: ${theme.spacing.zero};
  font-weight: ${theme.fontWeights.regular};
`;

export const DaysIcon = styled.img`
  width: ${theme.spacing.medium}px;
  height: ${theme.spacing.medium}px;
  margin-right: ${theme.spacing.xsmall}px;
`;
