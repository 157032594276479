import styled from 'styled-components';

import { theme } from '@gympass/yoga';
import { ChevronLeft } from '@gympass/yoga-icons';

export const ArrowIcon = styled(ChevronLeft)`
  fill: var(--primary);
  height: 100%;
  width: 100%;
`;

export const Container = styled.button`
  background-color: white;
  fill: var(--primary);
  height: 56px;
  width: 56px;
  cursor: pointer;
  margin-right: ${theme.spacing.xsmall}px;
`;
