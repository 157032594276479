import styled from 'styled-components';

import media from '../../../../theme/media';

export const PlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  ${media.desktop`
    height: 64px;
    flex-direction: row;
    align-items: center;
  `}
`;

export const ShimmerCategoryItemWrapper = styled.div`
  width: 100%;
  height: 100%;

  ${media.desktop`
    width: 50%;
  `}
`;
