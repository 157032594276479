import styled from 'styled-components';

import { theme } from '@gympass/yoga';

export const SrOnly = styled.span`
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(
    ${theme.spacing.zero}px ${theme.spacing.zero}px 99.9% 99.9%
  );
  clip-path: inset(${theme.spacing.zero}px ${theme.spacing.zero}px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: ${theme.spacing.zero};
  border: ${theme.spacing.zero};
  top: 50%;
`;

/** @alias */ // TODO: day zero adding knip to this project, remove me
export default SrOnly;
