import { createGlobalStyle } from 'styled-components';

const AmplifyStyle = createGlobalStyle`
  .amplify-button[data-variation='primary'] {
    background: #710252;
  }

  [data-amplify-authenticator] {
    background: #EEEDF4;
    height: 100vh;
    width: 100vw;
  }

  .amplify-button--small {
    color: #710252;
    font-weight: 700;
    font-size: 1rem;

    &:hover {
      background: #fff;
      color: #710252;
    }
  }
`;

export default AmplifyStyle;
