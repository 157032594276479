import { atom } from 'jotai';

import {
  IStep,
  IOptionQuestionWorkflow,
  IRuleQuestionWorkflow,
} from 'types/workflow';

export const currentStepAtom = atom({} as IStep);

export const currentAnswerAtom = atom([] as IOptionQuestionWorkflow[]);

export const currentRuleMatcherAtom = atom(
  {} as IRuleQuestionWorkflow | undefined,
);

export const isContinuosOnboardingAtom = atom(false);
