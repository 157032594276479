import { createContext } from 'react';

type ContextProps = {
  hasNotification: Boolean;
  setHasNotification: Function;
};

const NotificationContext = createContext<ContextProps>({
  hasNotification: false,
  setHasNotification: () => {},
});

const NotificationProvider = NotificationContext.Provider;
const NotificationConsumer = NotificationContext.Consumer;

/**
 * @public
 */
export { NotificationContext, NotificationProvider, NotificationConsumer };
