import styled from 'styled-components';

import { theme } from '@gympass/yoga';
import media from 'theme/media';

import { Button } from 'components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${media.desktop`
    justify-content: center;
    align-items: center;
    max-width: 840px;
    max-height: 636px;
    height: 80vh;
    margin: ${theme.spacing.xlarge}px auto;
    background-color: transparent;
    overflow: hidden;
    border-radius: ${theme.spacing.small}px;
    box-shadow: ${theme.elevations.medium};
  `}
`;

export const Content = styled.div<{ backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  height: 90vh;
  background-color: ${({ backgroundColor }) => backgroundColor};

  ${media.desktop`
    height: 100%;
    flex-direction: row;
    width: 100%;
  `}
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;

  ${media.desktop`
    justify-content: flex-end;
    width: 100%;
  `}
`;

export const FooterContainer = styled.div`
  position: fixed;
  left: ${theme.spacing.zero};
  bottom: ${theme.spacing.zero};
  width: 100%;

  ${media.desktop`
    position: relative;
  `}
`;

export const ButtonContent = styled(Button)<{ isInvertedColors: boolean }>`
  border-radius: ${theme.radii.circle}px;
  min-width: 200px;
  max-height: 48px;

  ${props =>
    props.isInvertedColors &&
    `
     background-color: white;
    color: var(--primary);
    `}
`;
