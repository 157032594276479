import clevertap from 'clevertap-web-sdk';
import { hotjar } from 'react-hotjar';

import { User, UserInfo } from 'services/user/types';

import { birthDateToDate } from './date';
import { firstWord } from './strings';

declare global {
  interface Window {
    dataLayer: { event: string }[];
    'app-config': {
      apiUrl: string;
      debug: boolean;
      hotjarSnippetVersion: number;
      hotjarId: number;
    };
  }
}

function sendEventCleverTap(eventName: string) {
  clevertap.event.push(eventName);
}

async function setupTrackerUser(user: User) {
  if (!user) return;
  clevertap.onUserLogin.push({
    Site: hydrateClevertapUser(user),
  });
}

function hydrateClevertapUser(user: UserInfo) {
  const birthday = birthDateToDate(user?.birthdate as string);

  return {
    Name: user?.name,
    Identity: user?.username,
    Email: user?.email,
    DOB: birthday || null,
    FirstName: firstWord(user?.name),
  };
}

function initializeHotjar() {
  const { hotjarId, hotjarSnippetVersion } = window?.['app-config'];

  if (hotjarId && hotjarSnippetVersion && !hotjar.initialized()) {
    hotjar.initialize(hotjarId, hotjarSnippetVersion);
  }
}

function setupUserIdHotjar(user: UserInfo) {
  if (hotjar.initialized() && user.username) {
    hotjar.identify(user.username, { userId: user.username });
  }
}

export {
  initializeHotjar,
  setupUserIdHotjar,
  setupTrackerUser,
  hydrateClevertapUser,
  sendEventCleverTap,
};
