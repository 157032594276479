import styled from 'styled-components';

import { Snackbar } from '@gympass/yoga';
import media from 'theme/media';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const AlertBar = styled(Snackbar)`
  ${media.desktop`
      left: auto;
      right: auto;
      min-width: initial; 
  `};

  svg {
    fill: ${({ theme }) => theme.yoga.colors.stamina};
  }
`;
