import React from 'react';

import * as S from './styles';

const AvatarBullet: React.FC = ({ children, ...props }) => (
  <S.AvatarBulletRoot {...props}>
    <S.AvatarBulletText>{children}</S.AvatarBulletText>
  </S.AvatarBulletRoot>
);

export default AvatarBullet;
