import React, { ReactNode, useState, useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import {
  useUnleashClient,
  useUnleashContext,
} from '@unleash/proxy-client-react';
import { Auth } from 'aws-amplify';
import logger from 'libraries/logger';

import { UserData, getUserInfo } from 'services/user';

import Loading from 'components/Loading';

const FlagsSync: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const client = useUnleashClient();
  const updateContext = useUnleashContext();

  const { refetch: userRefetch } = useQuery({
    queryKey: ['getInitialUserInfo'],
    queryFn: () => getUserInfo(),
    refetchOnWindowFocus: false,
    onSuccess: async (data: UserData) => {
      const { username } = await Auth.currentAuthenticatedUser();
      await updateContext({
        userId: username,
        properties: {
          companyId: data.companyId as string,
        },
      });
      await client.stop();
      await client.start();
      setIsLoading(false);
    },
    onError: err => {
      logger.error('[ReadFlagsSync][getUserInfo]', err);
      setIsLoading(false);
    },
    enabled: false,
  });

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(async () => {
        await userRefetch();
      })
      .catch(async () => {
        await client.stop();
        await client.start();
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{isLoading ? <Loading /> : children}</>;
};

export default FlagsSync;
