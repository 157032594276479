import styled from 'styled-components';

import { theme } from '@gympass/yoga';

import { ProgressBarProps } from './types';

const ProgressBarColors = {
  default: {
    startColor: theme.colors.medium,
    endColor: 'rgba(35, 27, 34, 0.15)',
  },
  progress: {
    startColor: 'var(--progress-bar-background)',
    endColor: 'rgba(35, 27, 34, 0.15)',
  },
};

const getProgressBarColors = (navBarStyle: 'default' | 'progress') => {
  return ProgressBarColors[navBarStyle] || ProgressBarColors.default;
};

export const ProgressBarWrapper = styled.div<ProgressBarProps>`
  position: relative;
  width: 100%;
  height: ${theme.spacing.xxxsmall}px;
  background-color: ${props =>
    getProgressBarColors(props.navBarStyle).endColor};

  ::before {
    position: absolute;
    width: ${props => props.progress}%;
    background-color: ${props =>
      getProgressBarColors(props.navBarStyle).startColor};
    transition: width 0.5s ease-out;
  }
`;

export const Progress = styled.div<ProgressBarProps>`
  width: ${props => props.progress}%;
  background-color: ${props =>
    getProgressBarColors(props.navBarStyle).startColor};
  height: 100%;
  border-radius: ${theme.radii.small}px;
  transition: width 0.5s ease-out;
`;

export const Ball = styled.img<ProgressBarProps>`
  position: absolute;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  left: ${props => props.progress - 2}%;
  background-color: ${props =>
    props.navBarStyle === 'progress'
      ? 'var(--progress-bar-background)'
      : 'transparent'};
  z-index: 10;
  transition: left 0.5s ease-out;
`;

export const BallTextWrapper = styled.div<ProgressBarProps>`
  position: absolute;
  top: 100%;
  left: ${props => props.progress}%;
  transform: ${props => {
    if (props.progress < 10) return 'translateX(0)';
    if (props.progress > 90) return 'translateX(-100%)';
    return 'translateX(-50%)';
  }};
  white-space: nowrap;
  margin-top: ${theme.spacing.xxsmall}px;
  transition: left 0.5s ease-out;
`;

export const BallText = styled.span<ProgressBarProps>`
  display: inline-block;
  font-size: ${theme.fontSizes.xsmall}px;
  color: ${theme.colors.white};
  text-align: ${props =>
    // eslint-disable-next-line no-nested-ternary
    props.progress < 10 ? 'left' : props.progress > 90 ? 'right' : 'center'};
  margin: ${props =>
    // eslint-disable-next-line no-nested-ternary
    props.progress < 10
      ? '0 0 0 -8px'
      : props.progress > 90
      ? '0 -8px 0 0'
      : 0};
`;
