import { Auth } from 'aws-amplify';

async function getAuthorizationTokens() {
  try {
    const currentSession = await Auth.currentSession();

    return {
      accessToken: await currentSession.getAccessToken().getJwtToken(),
      idToken: await currentSession.getIdToken().getJwtToken(),
      refreshToken: await currentSession.getRefreshToken().getToken(),
    };
  } catch (e) {
    return {
      accessToken: null,
      idToken: null,
      refreshToken: null,
    };
  }
}

export { getAuthorizationTokens };
