import styled from 'styled-components';

import { theme } from '@gympass/yoga';

export const Dots = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--primary);
  color: var(--primary);
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  margin: 0 ${theme.spacing.medium}px;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--primary);
    color: var(--primary);
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--primary);
    color: var(--primary);
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: var(--primary);
    }
    50%,
    100% {
      background-color: #ebe6ff;
    }
  }
`;
