import React, { ReactNode, useEffect, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { PUBLICS_URLS } from 'utils/constants';

import Loading from 'components/Loading';

const WithAuthAmplify: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const [isLoading, setIsLoading] = useState(true);
  const isDev = import.meta.env.DEV;

  const unauthenticated = () => {
    setIsLoading(false);
    if (PUBLICS_URLS.includes(window.location.pathname)) {
      return;
    }

    if (!isDev) {
      window.location.replace('/identity');
    }
  };

  useEffect(() => {
    const handleAuthStatus: {
      [key: string]: () => void;
      default: () => void;
    } = {
      unauthenticated: () => unauthenticated(),
      configuring: () => setIsLoading(true),
      authenticated: () => setIsLoading(false),
      default: () => unauthenticated(),
    };

    (handleAuthStatus[authStatus] || handleAuthStatus.default)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus]);

  return <>{isLoading ? <Loading /> : children}</>;
};

export default WithAuthAmplify;
