import styled from 'styled-components';

import media from 'theme/media';

import Link from 'components/Link';

import { sharedStyledLink } from '../style';

export const ChatButtonLink = styled(Link)`
  ${sharedStyledLink}

  min-width: 100%;

  ${media.desktop`
    min-width: 200px;
  `}
`;
