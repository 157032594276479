import styled from 'styled-components';

import { theme, Text, Button as ButtonYoga } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';

export const ActivityContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: ${theme.spacing.xxsmall}px;
  flex-wrap: nowrap;
  outline: none;
  padding: ${theme.spacing.medium}px;

  ${media.not.md`
    padding: ${theme.spacing.small}px;
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.spacing.xxsmall}px;
  `};
`;

export const ActivityContentRight = styled.div`
  display: flex;
  justify-content: start;
  gap: ${theme.spacing.xxsmall}px;
  align-items: center;
  flex-wrap: nowrap;

  ${media.not.md`
    flex-wrap: wrap;
    width: auto;
    align-items: center;
  `};

  ${media.not.sm`
    flex-wrap: wrap;
  `};
  ${media.max('md')`
    width: 300px;
    justify-content: flex-start;
  `}
`;

export const RightItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-right: ${theme.spacing.xxxsmall}px;
`;

export const TypeIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;

  svg {
    margin-right: ${theme.spacing.xxxsmall}px;
    width: ${theme.fontSizes.medium}px;
    height: ${theme.fontSizes.medium}px;
    fill: var(--text-secondary);
  }
`;

export const TypeText = styled(Text.Tiny)`
  font-weight: ${theme.fontWeights.regular};
  color: var(--text-disabled);
`;

export const BreakRow = styled.div`
  flex-basis: 100%;
  height: 0;
`;

export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: no-wrap;
`;

export const Button = styled(ButtonYoga).attrs({
  small: true,
  inverted: true,
})`
  background-color: transparent;
  border: ${theme.borders.small}px solid ${theme.colors.primary};

  p {
    color: ${theme.colors.primary};
    font-weight: ${theme.fontWeights.medium};
  }
`;
