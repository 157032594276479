import { useState, useEffect } from 'react';

export const MOBILE_WIDTH = 769;

function useVerifyMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // @ts-expect-error -- day zero of no errors, remove and fix me when you see me in a code review
    function onResizeWindow(event) {
      if (event.target.screen.width < MOBILE_WIDTH) {
        if (!isMobile) {
          setIsMobile(true);
        }
      } else if (isMobile) {
        setIsMobile(false);
      }
    }

    window.addEventListener('resize', onResizeWindow);

    if (window.screen.width < MOBILE_WIDTH) {
      setIsMobile(true);
    }
    return () => {
      window.removeEventListener('resize', onResizeWindow);
    };
  }, [isMobile]);

  return [isMobile];
}

export default useVerifyMobile;
