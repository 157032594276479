import React from 'react';
import '@total-typescript/ts-reset';

import { FlagProvider } from '@unleash/proxy-client-react';
import { Amplify } from 'aws-amplify';
import clevertap from 'clevertap-web-sdk';
// eslint-disable-next-line import-helpers/order-imports
import ReactDOM from 'react-dom';

// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
// eslint-disable-next-line import-helpers/order-imports
import { Authenticator } from '@aws-amplify/ui-react';
import AmplifyStyle from 'theme/AmplifyStyle';

import App from './App';
import { getAwsExports, getEndpointExports } from './services/api';
// Vite workaround
import 'setimmediate';

interface AWSExports {
  aws_project_region: string;
  aws_cognito_identity_pool_id: string;
  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  oauth: {
    domain: string;
  };
}

getAwsExports().then((json: AWSExports) => {
  Amplify.configure({
    Auth: {
      region: json.aws_cognito_region,
      userPoolId: json.aws_user_pools_id,
      userPoolWebClientId: json.aws_user_pools_web_client_id,
      aws_cognito_identity_pool_id: json.aws_cognito_identity_pool_id,
    },
  });

  getEndpointExports().then(config => {
    clevertap.init(config.clevertapAccountId, config.clevertapRegion);
    clevertap.privacy.push({ optOut: false });
    clevertap.privacy.push({ useIP: true });
    clevertap.spa = true;

    const unleashInitialConfig = {
      url: config?.unleashApiUrl,
      clientKey: config?.unleashApiKey,
      refreshInterval: config?.unleashApiRefreshInterval || 15,
      appName: 'Wellz',
      environment: 'production',
    };

    ReactDOM.render(
      <Authenticator.Provider>
        <AmplifyStyle />
        <FlagProvider config={unleashInitialConfig} startClient={false}>
          <App />
        </FlagProvider>
      </Authenticator.Provider>,

      document.getElementById('root'),
    );
  });
});
