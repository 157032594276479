import styled from 'styled-components';

import { NavbarContainerProps, NavbarStyles } from './types';

export const NavbarContainer = styled.div<NavbarContainerProps>`
  background: ${({ navBarStyle }) => NavbarStyles[navBarStyle].background};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  width: 100%;
  z-index: 10;
`;
