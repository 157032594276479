import styled from 'styled-components';

import { Text, Card, theme } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';

import { Button } from '../ButtonLegacy';

export const StyledCard = styled(Card)`
  width: 320px;
  height: 264px;
  padding-left: ${theme.spacing.large}px !important;
  margin: auto;
  position: relative;
  border-radius: ${theme.radii.small}px;

  display: flex;
  flex-direction: column;
  justify-content: initial;
  overflow: hidden;

  ${media.not.max('md')`
      width: 100%;
      max-width: var(--content-page-width);
      height: 120px;
      flex-direction: row;
      align-items: center;
      padding: ${theme.spacing.medium}px ${theme.spacing.small}px;
    `}
`;

export const TryASessionImgContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing.xxsmall}px;

  ${media.not.max('md')`
        margin-right: 18px;
    `}

  img + img {
    margin-left: -35px;
  }

  img:last-child {
    margin-left: -15px;
  }
`;

export const TryASessionInfoContainer = styled.div`
  max-width: 370px;
  margin-bottom: ${theme.spacing.xxsmall}px;

  ${media.not.max('md')`
        margin-right: 65px;
    `}
`;

export const InfoTitle = styled(Text.Tiny)`
  color: ${theme.colors.deep};
  margin-bottom: ${theme.spacing.xxsmall}px;
  text-transform: uppercase;
  font-weight: ${theme.fontWeights.medium};
`;

export const Info = styled(Text)`
  margin: 0;
  color: var(--text-primary);
`;

export const StyledButton = styled(Button)`
  padding: ${theme.spacing.xxsmall}px ${theme.spacing.small}px;
  height: ${theme.spacing.xlarge}px;
  font-size: ${theme.fontSizes.small}px;
  line-height: ${theme.lineHeights.xsmall}px;
  white-space: nowrap;
  ${media.not.max('md')`
        max-width: 187px;
    `};
`;
