import styled from 'styled-components';

export const Content = styled.div<{ timer: number; isFadeInd: boolean }>`
  opacity: ${props => (props.isFadeInd ? 0 : 1)};

  transition: opacity ${props => props.timer}s linear;

  &.animation {
    opacity: ${props => (props.isFadeInd ? 1 : 0)};
  }
`;
