import styled from 'styled-components';

import { Text, theme } from '@gympass/yoga';

import media from '../../theme/media';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${media.desktop`
    max-width: var(--content-width);
    margin: 0 auto;
  `}
`;

interface SectionContainerProps {
  light?: boolean;
}

export const SectionContainer = styled.div<SectionContainerProps>`
  width: 100%;
  background-color: ${props =>
    props.light ? 'var(--background-light)' : 'var(--background)'};
`;

export const Title = styled(Text.Tiny)`
  text-transform: uppercase;
  font-weight: ${theme.fontWeights.medium};
  color: var(--text-primary);
  margin-bottom: ${props =>
    props.hasSubtitle ? theme.spacing.xxxsmall : theme.spacing.medium}px;
`;

export const Subtitle = styled(Text.Small)`
  color: var(--text-secondary);
  margin-bottom: ${theme.spacing.medium}px;
`;
