import styled from 'styled-components';

import { Tag, theme } from '@gympass/yoga';

export const Container = styled.span`
  display: flex;
  justify-content: center;
  margin-bottom: ${theme.spacing.xsmall}px;
`;

export const Error = styled(Tag)`
  border-color: transparent;
  color: var(--error);
`;
