import logger from 'libraries/logger';

import { strapi, getEndpointExports } from './api';
import { getAuthorizationTokens } from './utils';

// eslint-disable-next-line import/order
import { AudioTypes, TranscriptionType } from 'atoms/audio/types';

export interface Topic {
  id: string;
  title_key: string;
  description_key?: string;
}

export interface StrapiTopics {
  topic: StrapiTopic;
}

/** @knipignore */ // TODO: day zero adding knip to this project, remove me
export interface DeliverTopic {
  id: string;
  topic: Topic;
  pages_ack: number;
  pages_total: number;
}

export interface StrapiTopic {
  id: number;
  order: number;
  title_key?: string;
}

export interface StrapiProgram {
  id: number;
  title_key: string;
  description: string;
  program_topics: StrapiTopic[];
}

export interface StrapiAckPage {
  page: { id: number };
  deliver_topic: { id: number };
  last_activity_finished_type: 'audio' | 'text';
}

async function getStrapiToken(): Promise<string> {
  try {
    const { idToken, accessToken, refreshToken } =
      await getAuthorizationTokens();

    const { data } = await strapi.post(
      '/cognito/callback_user',
      {
        id_token: idToken,
        access_token: accessToken,
        refresh_token: refreshToken,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    return data.jwt;
  } catch (e) {
    return '';
  }
}

export type Tag = {
  slug: string;
};

export type Page = {
  id: number;
  title: string;
  content: string;
  page_type: string;
  duration?: '0' | string;
  cover: { url: string } | null;
  audio_file: { url: string } | null;
  transcription_page?: TranscriptionType;
  tags?: Tag[];
};

async function getPageContent(token: string, pageId: string) {
  try {
    const { data } = await strapi.get(`/pages/${pageId}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (e) {
    logger.log('error', 'getPageContent error', e);
    return {};
  }
}

async function finishPageContent(
  token: string,
  pageId: string | number,
  deliverTopicId: number,
  last_activity_finished_type: 'text' | 'audio',
): Promise<StrapiAckPage | {}> {
  try {
    const body = {
      page: {
        id: pageId,
      },
      deliver_topic: {
        id: deliverTopicId,
      },
      last_activity_finished_type,
    };

    const { data } = await strapi.post(`/ack-pages`, body, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (e) {
    logger.log('error', 'finishPageContent error', e);
    return {};
  }
}

async function creditsSignup() {
  const token = await getStrapiToken();
  const { productId } = await getEndpointExports();

  try {
    const body = {
      product_id: productId,
    };

    const { data } = await strapi.post(`/credits/signup`, body, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    return data;
  } catch (e) {
    logger.log('error', 'creditsSignup error', e);
    return {};
  }
}

async function getProgressPage(pageId: number): Promise<number> {
  try {
    const token = await getStrapiToken();
    const { data } = await strapi.get(`/pages/${pageId}/progress`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return data?.progress || 0;
  } catch (e) {
    return 0;
  }
}

async function setProgressPage(pageId: number, progress: number) {
  try {
    const token = await getStrapiToken();
    const { data } = await strapi.patch(
      `/pages/${pageId}/progress`,
      {
        progress,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  } catch (e) {
    return {};
  }
}

const getStrapiProgramById = async (
  programId: string,
): Promise<StrapiProgram | undefined> => {
  try {
    const token = await getStrapiToken();

    const { data } = await strapi.get(`/programs/${programId}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    return data;
  } catch (e) {
    return undefined;
  }
};

const getStrapiTopicsByProgramTopicId = async (
  topics: string,
): Promise<StrapiTopics[] | undefined> => {
  try {
    const token = await getStrapiToken();
    const { data } = await strapi.get(`/program-topics?${topics}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (e) {
    return undefined;
  }
};

async function getAudios(): Promise<AudioTypes[] | undefined> {
  try {
    const token = await getStrapiToken();
    const { data } = await strapi.get(
      '/pages?page_type=audio&page_type=meditation',
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );
    return data;
  } catch (e) {
    return undefined;
  }
}

export {
  getStrapiToken,
  getPageContent,
  finishPageContent,
  creditsSignup,
  getProgressPage,
  setProgressPage,
  getStrapiProgramById,
  getStrapiTopicsByProgramTopicId,
  getAudios,
};
