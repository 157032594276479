import styled from 'styled-components';

import { Text, theme } from '@gympass/yoga';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${theme.spacing.xsmall}px;
`;

export const Icon = styled.i`
  color: var(--text-disabled);
  font-size: 64px;

  :before {
    color: var(--text-disabled);
  }
`;

export const Label = styled(Text.Small)`
  margin-top: ${theme.spacing.medium}px;
  color: var(--text-disabled);
`;
