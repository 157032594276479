import styled from 'styled-components';

import { Text, theme, Button as ButtonYoga } from '@gympass/yoga';

import { Button } from 'components/Button';

export const Container = styled.div`
  margin: ${theme.spacing.zero};
  padding: ${theme.spacing.small}px;
`;

export const Title = styled(Text.H4)`
  font-weight: ${theme.fontWeights.bold};
  margin-top: ${theme.spacing.xxsmall}px;
  margin-bottom: ${theme.spacing.large}px;
`;

export const Description = styled(Text.Regular).attrs({
  color: 'deep',
})`
  margin: ${theme.spacing.zero};
  line-height: ${theme.lineHeights.medium}px;
`;

export const FormContainer = styled.div`
  margin-top: ${theme.spacing.large}px;
`;

export const Check = styled.div`
  margin: ${theme.spacing.zero};
`;

export const StyledButtonContainer = styled.div`
  margin-top: ${theme.spacing.xxlarge}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  border-radius: ${theme.radii.circle}px;
`;

export const StyledLink = styled(ButtonYoga.Link)`
  display: initial;
  margin: ${theme.spacing.zero};
  font-size: ${theme.fontSizes.small}px;
  font-weight: ${theme.fontWeights.medium};
  text-decoration: none;
`;

export const StyledLinkDescription = styled(ButtonYoga.Link).attrs({
  color: 'deep',
})`
  display: initial;
`;

export const StyledLabel = styled(Text.Small).attrs({
  color: 'deep',
})`
  margin: ${theme.spacing.zero};
`;
