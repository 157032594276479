import { createTheme } from '@gympass/yoga';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const yogaTheme = createTheme((tokens: any) => ({
  colors: {
    primary: tokens.colors.deepPurple,
    error: '#ce3242',
    feedback: {
      attention: ['#7068D4', '#ce3242'],
    },
    grey: {
      light: '#c2c2d1',
      dark: '#9898a6',
    },
  },
}));

export default yogaTheme;
