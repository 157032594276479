import React, { useContext, useEffect, useRef, useState } from 'react';

import { AlertContext } from 'context/AlertContext';

import * as S from './styles';

const DEFAULT_DURATION = 5000;

const AlertBar: React.FC = () => {
  const { alert, setAlert } = useContext(AlertContext);
  const alertRef = useRef<HTMLElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const handleOnClose = () => {
    setIsOpen(false);
    setAlert(null);
  };

  useEffect(() => {
    setIsOpen(!!alert?.message);

    if (alertRef) {
      setTimeout(() => {
        alertRef.current?.focus();
      }, 100);
    }
  }, [alert]);

  return (
    <S.Container>
      <S.AlertBar
        role="alert"
        open={isOpen}
        hideCloseButton
        aria-live="assertive"
        onClose={handleOnClose}
        message={alert?.message || ''}
        variant={alert?.type || 'info'}
        duration={alert?.duration || DEFAULT_DURATION}
      />
    </S.Container>
  );
};

export default AlertBar;
