import styled from 'styled-components';

import { theme } from '@gympass/yoga';
import media from 'theme/media';

export const Container = styled.div`
  width: 100%;
`;

export const Section = styled.div`
  margin-bottom: ${theme.spacing.medium}px;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.small}px;
`;

/** @knipignore */ // TODO: day zero adding knip to this project, remove me
export const TopicsContainer = styled.div`
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;

  ${media.desktop`
    flex-wrap: wrap;
  `}
`;
