import styled from 'styled-components';

import { theme } from '@gympass/yoga';

import media from '../../../../theme/media';

export const NPSRating = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  text-align: center;

  align-items: center;
  justify-content: center;

  padding: ${theme.spacing.xxxlarge}px ${theme.spacing.zero}px
    ${theme.spacing.xxlarge}px ${theme.spacing.zero}px;

  ${media.desktop`
  padding: ${theme.spacing.small}px ${theme.spacing.zero} ${theme.spacing.xxxlarge}px ${theme.spacing.zero};

  flex-direction: row;

`}
`;

export const NPSRatingPack = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  text-align: center;

  ${media.desktop`
  justify-content: space-around;
`}
`;

export const NPSRatingItem = styled.button`
  background-color: unset;

  width: 40px;
  height: 40px;

  display: flex;
  flex-direction: row;
  text-align: center;

  align-items: center;
  justify-content: center;

  padding: 10px;
  margin: 0 5px 0 5px;
  border: 1px solid var(--divider);
  border-radius: 50px;
  box-sizing: border-box;

  &:hover,
  &.selected {
    background-color: var(--primary-light);
    color: white;
  }
`;
