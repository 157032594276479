import { useState, useContext } from 'react';

import OtaClient from '@crowdin/ota-client';
import logger from 'libraries/logger';
import { MessageFormatElement } from 'react-intl';
import { getTranslationFile } from 'utils/language';

import { getEndpointExports } from 'services/api';

import { ExportsConfigContext } from '../../context/ExportsConfigContext';
import crowdinClient from '../../services/crowdin';

import {
  AcceptedLanguages,
  AcceptedLanguagesShort,
  UseTranslationsState,
} from './types';

const useTranslations = (
  selectLanguage: AcceptedLanguagesShort,
): UseTranslationsState => {
  const { exportsConfig } = useContext(ExportsConfigContext);
  const fileName = exportsConfig?.crowdinFileName;
  const [language, setLanguage] =
    useState<AcceptedLanguagesShort>(selectLanguage);

  const getDefaultTranslations = async () => {
    setLanguage(language);
    const translation = getTranslationFile(language);
    return translation as unknown as Promise<
      Record<string, MessageFormatElement[]>
    >;
  };

  const getTranslation = async (): Promise<
    Record<string, MessageFormatElement[]>
  > => {
    const defaultStrings = await getDefaultTranslations();
    try {
      const endpoints = await getEndpointExports();
      const crowdin: OtaClient = await crowdinClient(
        language,
        endpoints?.crowdinHash,
      );
      if (crowdin) {
        const otaStrings = await crowdin.getStringsByLocale(
          fileName,
          AcceptedLanguages[language],
        );
        if (otaStrings && Object.keys(otaStrings).length) {
          return {
            ...defaultStrings,
            ...otaStrings,
          };
        }
      }
    } catch (error) {
      logger.error(
        `[HOOK][useTranslations][MSG]: Fail to Get Crowdin messages, use default`,
      );
    }
    return {
      ...defaultStrings,
    };
  };

  return {
    getDefaultTranslations,
    getTranslation,
    language,
  };
};

export default useTranslations;
