import styled from 'styled-components';

export const Badge = styled.div`
  position: absolute;
  top: -6px;
  right: -10px;

  width: 10px;
  height: 10px;

  border-radius: 10px;
  border: 1px solid var(--secondary);
  background-color: var(--secondary);
`;
