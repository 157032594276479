import styled from 'styled-components';

import { Text, theme } from '@gympass/yoga';

import media from '../../theme/media';
import * as Styles from './style';

import { ContainerProps } from './types';

interface TitleProps {
  small?: boolean;
}

export const Container = styled(Styles.Container)<ContainerProps>`
  ${props =>
    !props.disabledShadowTitle &&
    (props.withTitle || !props.onlyTitleBackground)
      ? `
    box-shadow: 0px 4px 12px rgba(152, 152, 167, 0.25);
  `
      : ``}

  ${props =>
    props.hideMobile
      ? `
  display: none;

  @media (min-width: 768px) {
    display: flex;
  }`
      : ``}
`;

export const HeaderContainer = styled(Styles.HeaderContainer)`
  justify-content: flex-start;
`;

export const Logo = styled.i`
  color: var(--primary);
  font-size: ${theme.fontSizes.xxxlarge}px;
  padding: ${theme.spacing.xsmall}px 0;

  ${media.desktop`
    font-size: ${theme.fontSizes.huge}px;
  `}
`;

export const Icon = styled.i`
  color: var(--primary);
  font-size: ${theme.fontSizes.xlarge}px;
`;

export const Title = styled(Text.H4)<TitleProps>`
  margin: ${theme.spacing.zero}px;
  color: ${props => (props.small ? 'var(--text-primary)' : 'var(--primary)')};
  font-weight: ${props =>
    props.small ? theme.fontWeights.medium : theme.fontWeights.light};
  font-size: ${props =>
    props.small ? theme.fontSizes.small : theme.fontSizes.xlarge}px;

  position: absolute;
  left: 50%;
  right: 0;
  transform: translateX(-50%);
  text-align: center;
  margin-top: 2px;

  ${media.desktop`
    font-size: ${theme.fontSizes.large};
    padding-right: ${theme.spacing.zero}px;
  `}
`;
