import enTranslations from 'assets/translations/en-US/translations.json';
import ptTranslations from 'assets/translations/pt-PT/translations.json';
import logger from 'libraries/logger';

import { TranslationNormalize } from './types';

const normalizeLanguage = (language: string): string => {
  const getNormalizedLanguage: TranslationNormalize = {
    pt: 'pt-PT',
    en: 'en-US',
  };

  if (getNormalizedLanguage[language]) {
    return getNormalizedLanguage[language];
  }

  return 'pt-PT';
};

const getTranslationFile = (language: string) => {
  switch (normalizeLanguage(language)) {
    case 'pt-PT':
      return ptTranslations;
    case 'en-US':
      return enTranslations;
    default:
      logger.error(`getTranslationFile: Unknown language ${language}`);
      return ptTranslations;
  }
};
export default getTranslationFile;
