import styled from 'styled-components';

import { theme } from '@gympass/yoga';

import media from '../../theme/media';

export const Container = styled.div`
  height: 100%;

  & > div {
    height: 100%;
  }

  ${media.desktop`
    & > div {
      padding: ${theme.spacing.medium}px;
    }
  `};
`;

export const AuthContent = styled.div`
  flex: 1;
  align-items: flex-start;
  padding: ${theme.spacing.medium}px;
  padding-top: ${theme.spacing.zero}px;

  ${media.desktop`
    padding-top: ${theme.spacing.xxlarge}px;
  `}
`;
