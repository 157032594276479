import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { EndUserSpecialistInfo } from 'services/user';

const PRODUCTION_ENV = 'new-ventures';

export interface DatadogInitRum {
  ddAppId: string;
  ddClientId: string;
  env: string;
  caishenUrl: string;
  apiUrl: string;
  careMatrixUrl: string;
  luliskitApiUrl: string;
  strapiUrl: string;
  wellzAccountsUrl: string;
}

async function init({
  ddAppId,
  ddClientId,
  env,
  apiUrl,
  caishenUrl,
  careMatrixUrl,
  luliskitApiUrl,
  strapiUrl,
  wellzAccountsUrl,
}: DatadogInitRum) {
  const buildTag = import.meta.env.VITE_BUILD_TAG;

  const datadogAttributes = {
    applicationId: ddAppId,
    clientToken: ddClientId,
    site: 'datadoghq.com',
    service: 'new-ventures--asylum',
    version: buildTag || '0.0.1',
    env,
    sessionSampleRate: 100,
  };

  if (ddAppId && ddClientId && env === PRODUCTION_ENV) {
    datadogRum.init({
      ...datadogAttributes,
      trackUserInteractions: true,
      sessionReplaySampleRate: 100,
      defaultPrivacyLevel: 'mask',

      allowedTracingUrls: [
        apiUrl,
        caishenUrl,
        luliskitApiUrl,
        strapiUrl,
        wellzAccountsUrl,
        careMatrixUrl,
      ],
    });
  }

  datadogLogs.init({
    ...datadogAttributes,
    forwardErrorsToLogs: true,
  });
}

function setDatadogRumUser(
  userId?: string,
  specialist?: EndUserSpecialistInfo,
) {
  if (userId && specialist?.specialistType)
    datadogRum.setUser({
      id: userId,
      specialistType: specialist?.specialistType,
    });
}

export { init, setDatadogRumUser };
