import styled from 'styled-components';

import { theme, Card, Text } from '@gympass/yoga';

export const BannerCard = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  background: var(--secondary-mellow-apricot);
  margin-bottom: ${theme.spacing.medium}px;
  padding: ${theme.spacing.xxsmall}px ${theme.spacing.medium}px;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
`;

export const BannerButton = styled.a``;

export const BannerText = styled(Text.Small)``;

export const BoldBannerText = styled.p`
  font-size: ${theme.fontSizes.xsmall}px;
  font-weight: ${theme.fontWeights.medium};
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.div`
  margin-right: ${theme.spacing.xxsmall}px;
`;
