import styled from 'styled-components';

import { theme } from '@gympass/yoga';
import media from 'theme/media';

export const BalloonContainer = styled.li<{ role: string }>`
  display: flex;
  flex-direction: ${({ role }) =>
    role === 'specialist' ? 'row' : 'row-reverse'};
  margin-bottom: ${theme.spacing.xsmall}px;
`;

export const BalloonAvatar = styled.div`
  &.specialist {
    margin-right: ${theme.spacing.small}px;

    ${media.mobile`
      display: none;
    `}
  }

  &.me {
    display: none;
    margin-left: ${theme.spacing.small}px;
  }
`;

export const Balloon = styled.div`
  padding: ${theme.spacing.medium}px;
  border-radius: ${theme.spacing.small}px;
  margin-bottom: 2px;
  font-size: ${theme.fontSizes.small}px;
  max-width: var(--chat-balloon-desktop-width);
  text-align: initial;

  a {
    color: inherit;
    text-decoration: underline;
  }

  ${media.mobile`
    max-width: var(--chat-balloon-mobile-width);
  `}

  &.specialist {
    color: var(--text-primary);
    background: var(--progress-base-color);
    border-top-left-radius: ${theme.spacing.xxxsmall}px;
  }

  &.me {
    background: var(--primary-light);
    color: white;
    border-top-right-radius: ${theme.spacing.xxxsmall}px;
  }
`;
