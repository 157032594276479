import styled from 'styled-components';

import { Text, theme } from '@gympass/yoga';
import media from 'theme/media';

export const Content = styled.div`
  flex: 1;
  align-items: center;
  text-align: center;
`;

export const Title = styled(Text.H5)`
  color: var(--text-primary);
  margin-bottom: ${theme.spacing.small}px;
`;

export const Description = styled(Text.Small)`
  color: var(--text-secondary);
`;

export const IconContainer = styled.div`
  padding-top: ${theme.spacing.xhuge}px;
  padding-bottom: ${theme.spacing.large}px;
`;

export const Image = styled.img``;

export const WrapperContent = styled.div`
  padding-top: ${theme.spacing.large}px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WrapperContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: var(--content-width);
  margin: ${theme.spacing.zero} auto;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--background-light);
  height: 100%;

  ${media.desktop`
  height: auto;
  background-color: white;
    box-shadow: rgba(152, 152, 167, 0.25) 0px 4px 12px;
  `}
`;
