import styled from 'styled-components';

import { Text, Card, theme } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';

import { Button } from '../ButtonLegacy';

export const StyledCard = styled(Card)`
  width: 320px;
  height: 264px;
  padding: ${theme.spacing.medium}px !important;
  margin: auto;
  position: relative;
  border-radius: ${theme.radii.small}px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  ${media.not.max('md')`
      width: 100%;
      max-width: var(--content-page-width);
      height: 120px;
      flex-direction: row;
      align-items: center;
      padding: ${theme.spacing.large}px ${theme.spacing.medium}px !important;
    `}
`;

export const VikiContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing.xxsmall}px;
  order: 2;

  ${media.not.max('md')`
        order: 1;
        margin-bottom: 0px;
    `}
`;

export const InfoContainer = styled.div`
  max-width: 387px;
  order: 1;
  flex-grow: 1;

  ${media.not.max('md')`
        order: 2;
    `}
`;

export const InfoTitle = styled(Text.Tiny)`
  color: ${theme.colors.deep};
  margin-bottom: ${theme.spacing.medium}px;
  text-transform: uppercase;
  font-weight: ${theme.fontWeights.medium};

  ${media.not.max('md')`
    margin-bottom: ${theme.spacing.xxsmall}px;
  `}
`;

export const Info = styled(Text)`
  margin-top: ${theme.spacing.xxsmall}px;
  color: var(--text-primary);

  ${media.not.max('md')`
    margin: 0;
  `}
`;

export const StyledButton = styled(Button)`
  padding: ${theme.spacing.xxsmall}px ${theme.spacing.small}px;
  height: ${theme.spacing.xlarge}px;
  font-size: ${theme.fontSizes.small}px;
  line-height: ${theme.lineHeights.xsmall}px;
  white-space: nowrap;
  flex-grow: 1;
  ${media.not.max('md')`
        width: auto;
    `};
`;

export const ButtonContainer = styled.div`
  max-width: 370px;
  order: 3;
  display: flex;
  z-index: 5;
`;

export const Viki = styled.div`
  background-repeat: no-repeat;
  background-image: url(/assets/vitalk/vitalk-banner.svg);
  background-size: 125px 112px;
  width: 125px;
  height: 112px;
  bottom: ${theme.spacing.xxlarge}px;
  position: absolute;
  z-index: 1;
  right: 0px;

  ${media.not.max('md')`
    position: relative;
    background-size: 129px 130px;
    width: 129px;
    height: 130px;
    bottom: -1px;
    right: auto;
  `};
`;

export const Comma = styled.div`
  position: absolute;
  background-repeat: no-repeat;
  background-image: url(/assets/vitalk/vitalk-banner-comma2.svg);
  background-size: 106px 171px;
  width: 106px;
  height: 171px;
  right: 0px;
  left: auto;
  bottom: -8px;

  ${media.not.max('md')`
    background-image: url(/assets/vitalk/vitalk-banner-comma.svg);
    background-size: 122px 120px;
    width: 122px;
    height: 120px;
    left: 22px;
    top: 0px;
  `};
`;
