import styled from 'styled-components';

import { Text, theme } from '@gympass/yoga';

import media from '../../../theme/media';

export const Banner = styled.section`
  width: 100%;
  background-color: var(--secondary-mellow-apricot);
  padding: ${theme.spacing.zero} ${theme.spacing.medium}px;
`;

export const Content = styled.div`
  max-width: var(--content-width);
  margin: 0px auto;
  padding: ${theme.spacing.medium}px ${theme.spacing.zero};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled(Text.Small)`
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes.xsmall}px;
  font-weight: ${theme.fontWeights.medium};
  display: flex;
  align-items: center;
  text-transform: uppercase;

  img {
    width: 13px;
    height: 13px;
    margin-right: ${theme.spacing.xxxsmall}px;
    margin-bottom: 2px;
  }
`;

export const Chevron = styled.div<{ isCollapsed: boolean }>`
  cursor: pointer;
  transform: rotate(${({ isCollapsed }) => (isCollapsed ? 270 : 90)}deg);
`;

export const Icon = styled.i`
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes.small}px;
  width: 14px;
  height: 14px;
`;

export const CollapsibleContent = styled.div<{ isCollapsed: boolean }>`
  overflow: hidden;
  display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'block')};
`;

export const Description = styled(Text.Tiny)<{ isCollapsed: boolean }>`
  padding-top: ${theme.spacing.small}px;
  font-size: ${theme.fontSizes.xsmall}px;
  font-weight: ${theme.fontWeights.small};

  ${media.desktop`
    font-size: ${theme.fontSizes.small}px;
  `};

  a {
    color: ${theme.colors.primary};
    font-size: ${theme.fontSizes.xsmall}px;
    font-weight: ${theme.fontWeights.medium};

    ${media.desktop`
      font-size: ${theme.fontSizes.small}px;
    `};
  }

  a.internal-link {
    color: inherit;
    text-decoration: none;
    font-weight: bold;
  }
`;
