import styled from 'styled-components';

export const PageTitle = styled.p`
  width: 100%;
  height: 0px;
  overflow: hidden;
  color: transparent;
  padding: 0;
  margin: 0;
`;
