import styled from 'styled-components';

import { theme, Card, Text } from '@gympass/yoga';
import { AlertTriangle } from '@gympass/yoga-icons';
import media from 'theme/media';

import AvatarBullet from 'components/AvatarBullet';

import SimpleBanner from '../SimpleBanner';

export const DownloadCardRoot = styled(Card)`
  ${media.mobile`
    text-align: center;
  `};
`;

export const DownloadCardHeaderBullet = styled(AvatarBullet)`
  ${media.mobile`
    margin: 0 auto ${theme.spacing.small}px auto;
  `}
  ${media.desktop`
    display: inline-flex;
  `}
`;

export const DownloadCardHeaderText = styled(Text.Bold)`
  ${media.desktop`
    margin-left: ${theme.spacing.small}px;
    display: inline-block;
  `}
`;

export const DownloadCardContent = styled(Card.Content)`
  ${media.desktop`
    padding-left: ${theme.spacing.xxxlarge}px;
    flex: 1;
  `}
`;

export const DownloadCardContentText = styled(Text).attrs({
  color: 'medium',
})`
  margin-bottom: ${theme.spacing.small}px;
`;

/** @knipignore */ // TODO: day zero adding knip to this project, remove me
export const DownloadCardContentBanner = styled.div`
  background-color: var(--background-mellow);
  height: ${theme.spacing.xlarge}px;
  padding: ${theme.spacing.zero} ${theme.spacing.xxsmall}px;
  margin: ${theme.spacing.zero};

  & svg {
    fill: var(--text-primary);
  }
  & p {
    margin: initial;
    font-size: ${theme.fontSizes.xsmall}px;
    font-weight: ${theme.fontWeights.bold};
  }
`;

/** @knipignore */ // TODO: day zero adding knip to this project, remove me
export const DownloadCardActionButton = styled.button`
  padding: ${theme.spacing.zero};
  background-color: transparent;
`;

export const DownloadCardActions = styled.div`
  display: flex;
  align-items: center;

  ${media.desktop`
    padding-left: ${theme.spacing.medium}px;
    align-items: flex-start !important;
    flex-direction: column;
  `}
`;

export const StyledTriangle = styled(AlertTriangle)`
  fill: ${theme.colors.primary};
  height: ${theme.spacing.small}px;
  width: ${theme.spacing.small}px;
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${media.desktop`
    flex-direction: row;
  `}
`;

/** @knipignore */ // TODO: day zero adding knip to this project, remove me
export const StoreContainer = styled.div`
  & + & {
    ${media.mobile`
      margin-left: ${theme.spacing.xxsmall}px;
    `}
  }
`;

export const StyledBanner = styled(SimpleBanner)`
  ${media.desktop`
    margin-bottom: ${theme.spacing.zero}px !important;
  `}
`;
