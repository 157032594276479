import styled from 'styled-components';

import { Text, theme } from '@gympass/yoga';
import media from 'theme/media';

export const Container = styled.div`
  width: 100%;
  background-color: white;
  padding: ${theme.spacing.zero} ${theme.spacing.medium}px;
`;

export const Content = styled.div`
  padding: ${theme.spacing.small}px ${theme.spacing.zero};

  ${media.desktop`
    width: var(--content-width);
    margin: ${theme.spacing.zero} auto;
  `}
`;

export const Header = styled.div`
  display: flex;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${theme.spacing.small}px;
  justify-content: center;
`;

export const Name = styled(Text)`
  color: var(--text-primary);
  font-weight: ${theme.fontWeights.medium};
`;

export const Area = styled(Text)`
  color: var(--text-secondary);
  font-size: ${theme.fontSizes.small}px;
`;

export const Hours = styled(Text)`
  color: var(--text-secondary);
  font-size: ${theme.fontSizes.small}px;
  text-decoration: underline;
  cursor: pointer;
`;

export const InfoIcon = styled.img`
  width: 10px;
  height: 10px;
  margin-left: ${theme.spacing.xxxsmall}px;
`;
