import styled from 'styled-components';

import { Checkbox } from '@gympass/yoga';

export const StyledCheckbox = styled(Checkbox)`
  & > div {
    &:focus {
      outline: 0;
    }

    &:focus-within,
    &:hover {
      & > label {
        span {
          background-color: rgba(113, 2, 82, 0.5) !important;
          box-shadow: 0 0 0 8px rgb(113 2 82 / 50%) !important;
        }
      }
    }
  }

  div label {
    & > div {
      border-color: var(--primary);
      flex-shrink: 0;
    }

    & > div:not(:empty) {
      background-color: var(--primary);
    }
  }
`;
