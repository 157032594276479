import styled from 'styled-components';

import { Text, theme } from '@gympass/yoga';

import media from '../../theme/media';

interface SectionContainerProps {
  light?: boolean;
}

export const Grid = styled.div`
  padding: ${theme.spacing.zero};
  width: 100%;
  overflow-y: auto;
`;

export const Row = styled.div<SectionContainerProps>`
  width: 100%;
  background-color: ${props =>
    props.light ? 'var(--background-light)' : 'var(--background)'};
`;

export const Content = styled.div`
  padding: ${theme.spacing.zero};
  max-width: var(--content-width);
  margin: ${theme.spacing.zero} auto;
`;

export const SectionContainer = styled.div<SectionContainerProps>`
  background-color: ${props =>
    props.light ? 'var(--background-light)' : 'var(--background)'};
  padding: ${theme.spacing.xxlarge}px ${theme.spacing.zero};
`;

export const MainSection = styled(SectionContainer)`
  padding-bottom: ${theme.spacing.xxlarge}px;

  ${media.desktop`
    padding-top: ${theme.spacing.xxlarge}px ;
    padding-bottom: 48px;
  `}
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${media.desktop`
    max-width: var(--content-width);
    margin: ${theme.spacing.zero} auto;
  `}
`;

export const Title = styled(Text.H4)`
  font-weight: ${theme.fontWeights.medium};
  color: var(--primary);
  z-index: 10;
  margin-right: ${theme.spacing.medium}px;

  ${media.desktop`
    max-width: 65%;
  `}
`;
