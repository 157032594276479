import styled from 'styled-components';

import { theme } from '@gympass/yoga';
import media from 'theme/media';

export const Content = styled.div<{ backgroundColor: string }>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: ${theme.spacing.small}px ${theme.spacing.medium}px
    ${theme.spacing.xlarge}px ${theme.spacing.medium}px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  ${media.desktop`
    justify-content: flex-end;
  `}
`;
