import React from 'react';

import { Close } from '@gympass/yoga-icons';
import { FormattedMessage } from 'react-intl';

import useStateWithLocalStorage from '../../hooks/useStateWithLocalStorage';
import * as S from './style';

export interface SimpleBannerProps {
  textId: string;
  isCloseable: boolean;
  localStorageStateItem?: string;
  icon?: JSX.Element;
  boldText?: boolean;
  className?: string;
}

const SimpleBanner: React.FC<SimpleBannerProps> = ({
  textId,
  isCloseable = true,
  localStorageStateItem,
  icon: Icon,
  boldText = false,
  className,
}) => {
  const { value, setValue } = useStateWithLocalStorage(
    `${localStorageStateItem}`,
  );

  if (value === 'false') return null;

  return (
    <S.BannerCard data-testid="simpleBannerCard" className={className}>
      <S.ContentContainer>
        <S.IconContainer>{Icon && Icon}</S.IconContainer>
        {boldText ? (
          <S.BoldBannerText data-testid="boldBannerText">
            <FormattedMessage id={`${textId}`} />
          </S.BoldBannerText>
        ) : (
          <S.BannerText data-testid="simpleBannerText">
            <FormattedMessage id={`${textId}`} />
          </S.BannerText>
        )}
      </S.ContentContainer>
      {isCloseable && (
        <S.BannerButton
          data-testid="simpleBannerButton"
          onClick={() => {
            setValue('false');
          }}
        >
          <Close />
        </S.BannerButton>
      )}
    </S.BannerCard>
  );
};

export default SimpleBanner;
