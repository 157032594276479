/* eslint-disable no-param-reassign */
import { GraphQLRequest, HttpLink, execute } from '@apollo/client';
import axios, { AxiosError } from 'axios';
import logger from 'libraries/logger';
import logoutUser from 'utils/logoutUser';

import { getAuthorizationTokens } from './utils';

export interface Config {
  crowdinFileName: string;
  crowdinHash: string;
  strapiUrl: string;
  cmsUrl: string;
  apiUrl: string;
  wellzAccountsUrl: string;
  wellzAccountsJwt: string;
  eligibilityUrl: string;
  timekitAppKey: string;
  timekitProjectID: string;
  timekitApiUrl: string;
  luliskitProjectID: string;
  luliskitApiUrl: string;
  unleashApiUrl: string;
  unleashApiKey: string;
  unleashApiRefreshInterval: number;
  productId: string;
  programId: string;
  moduleZeroProgramId: string;
  moduleZeroTopicId: string;
  supportContact: string;
  authorizationJwt: string;
  clevertapAccountId: string;
  clevertapRegion: string;
  ddAppId: string;
  ddClientId: string;
  env: string;
  careMatrixUrl: string;
  caishenUrl: string;
  wellzcareSmsUrl: string;
}

// @ts-expect-error -- day zero of no errors, remove and fix me when you see me in a code review
const defaultExportsConfig: Config = {
  crowdinFileName: '',
  crowdinHash: '',
  strapiUrl: '',
  cmsUrl: '',
  // @ts-expect-error -- day zero of no errors, remove and fix me when you see me in a code review
  apiUrl: '',
  eligibilityUrl: '',
  timekitAppKey: '',
  timekitProjectID: '',
  unleashApiUrl: '',
  unleashApiKey: '',
  unleashApiRefreshInterval: 15,
  productId: '',
  programId: '',
  moduleZeroProgramId: '',
  moduleZeroTopicId: '',
  supportContact: '',
  authorizationJwt: '',
  clevertapAccountId: '',
  clevertapRegion: '',
  ddAppId: '',
  ddClientId: '',
  env: '',
  careMatrixUrl: '',
  caishenUrl: '',
  wellzcareSmsUrl: '',
  // @ts-ignore
  ...window['app-config'],
};

async function getAwsExports() {
  try {
    const { data } = await axios.get('/assets/aws-exports.json');
    return data;
  } catch (e) {
    logger.log('error', 'Error getting aws-exports.json', e);
    return {};
  }
}

async function getEndpointExports(): Promise<Config> {
  return defaultExportsConfig;
}

const api = axios.create({
  timeout: 20000,
});

api.interceptors.request.use(
  async config => {
    const endpoints = await getEndpointExports();
    config.baseURL = endpoints.apiUrl;

    if (!config?.headers.Authorization) {
      try {
        const { accessToken } = await getAuthorizationTokens();
        if (accessToken) {
          config.headers = {
            Authorization: `Bearer ${accessToken}`,
          };
        }
      } catch (e) {
        logger.log('error', 'Error getting authorization token', e);
      }
    }
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

api.interceptors.response.use(
  async response => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 307) {
      window.location.href = '/logout';
    }
    if (error.response?.status === 401) {
      logoutUser();
    }

    return Promise.reject(error);
  },
);

const wellzAccounts = axios.create();

wellzAccounts.interceptors.request.use(async config => {
  const { wellzAccountsJwt, wellzAccountsUrl } = await getEndpointExports();

  config.baseURL = wellzAccountsUrl;

  if (!config?.headers.Authorization) {
    try {
      if (wellzAccountsJwt) {
        config.headers = {
          Authorization: `Bearer ${wellzAccountsJwt}`,
        };
      }
    } catch (e) {
      logger.log('error', 'Error getting wellz account jwt', e);
    }
  }
  return config;
});

const strapi = axios.create({
  timeout: 5000,
});
strapi.interceptors.request.use(
  async config => {
    const endpoints = await getEndpointExports();
    config.baseURL = endpoints.strapiUrl;

    return config;
  },
  error => {
    Promise.reject(error);
  },
);

export const cms = axios.create({
  timeout: 5000,
});
cms.interceptors.request.use(
  async config => {
    const endpoints = await getEndpointExports();
    config.baseURL = endpoints.cmsUrl;

    if (!config?.headers.Authorization) {
      try {
        const { accessToken } = await getAuthorizationTokens();
        if (accessToken) {
          config.headers = {
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
            Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            'Content-Type': 'application/json',
          };
        }
      } catch (e) {
        logger.log('error', 'Error getting authorization token', e);
      }
    }
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

async function executeApollo(token: string, operation: GraphQLRequest) {
  const endpoints = await getEndpointExports();

  const uri = `${endpoints.strapiUrl}/graphql`;
  const link = new HttpLink({ uri });

  return execute(link, {
    ...operation,
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });
}

const eligibilityApi = axios.create({ timeout: 5000 });

eligibilityApi.interceptors.request.use(
  async config => {
    const endpoints = await getEndpointExports();
    config.baseURL = endpoints.eligibilityUrl;
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

const careMatrix = axios.create({ timeout: 5000 });
careMatrix.interceptors.request.use(
  async config => {
    const { careMatrixUrl } = await getEndpointExports();
    config.baseURL = careMatrixUrl;

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

const subscription = axios.create({ timeout: 5000 });
subscription.interceptors.request.use(
  async config => {
    const { caishenUrl } = await getEndpointExports();
    config.baseURL = caishenUrl;

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

const wellzcareSms = axios.create({ timeout: 5000 });
wellzcareSms.interceptors.request.use(
  async config => {
    const { wellzcareSmsUrl } = await getEndpointExports();
    config.baseURL = wellzcareSmsUrl;

    if (!config?.headers.Authorization) {
      try {
        const { accessToken } = await getAuthorizationTokens();
        if (accessToken) {
          config.headers = {
            Authorization: `Bearer ${accessToken}`,
          };
        }
      } catch (e) {
        logger.log('error', 'Error getting authorization token', e);
      }
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export {
  api,
  strapi,
  eligibilityApi,
  getAwsExports,
  executeApollo,
  getEndpointExports,
  defaultExportsConfig,
  wellzAccounts,
  careMatrix,
  subscription,
  wellzcareSms,
};

if (import.meta.env.VITE_NODE_ENV !== 'production') {
  (async () => {
    // eslint-disable-next-line import/no-self-import
    Object.assign(window, { ...(await import('./api')) });
  })();
}
