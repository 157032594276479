import styled from 'styled-components';

import { theme } from '@gympass/yoga';

export const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${theme.radii.small}px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: 52px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: baseline;
  align-items: center;
  padding: 10px ${theme.spacing.small}px;

  svg {
    margin: ${theme.spacing.zero} ${theme.spacing.xxsmall}px 2px
      ${theme.spacing.zero};
    min-height: ${theme.spacing.medium}px;
    min-width: ${theme.spacing.medium}px;
    max-width: ${theme.spacing.medium}px;
    max-height: ${theme.spacing.medium}px;
  }
`;

export const Text = styled.span`
  color: var(--text-primary);
  font-size: ${theme.fontSizes.small}px;
  font-weight: ${theme.fontWeights.regular};
  line-height: ${theme.lineHeights.small}px;
  padding: 6px ${theme.spacing.zero};
`;
