import React from 'react';

import Spinner from '../Spinner';
import * as Styles from './style';

export type ButtonProps = {
  id?: string;
  full?: boolean;
  disabled?: boolean;
  loading?: boolean;
  md?: boolean;
  sm?: boolean;
  onClick?: Function;
  icon?: React.ReactNode;
  href?: string;
};

const Button: React.FC<ButtonProps> = ({
  loading,
  disabled,
  children,
  ...props
}) => (
  <Styles.Button disabled={disabled || loading} {...props}>
    {loading ? <Spinner data-testid="spinner" /> : children}
  </Styles.Button>
);

const OutlineButton: React.FC<ButtonProps> = ({
  loading,
  disabled,
  children,
  ...props
}) => (
  <Styles.OutlineButton disabled={disabled || loading} {...props}>
    {loading ? <Spinner data-testid="spinner" /> : children}
  </Styles.OutlineButton>
);

export { Button, OutlineButton };
