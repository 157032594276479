import { format, Locale } from 'date-fns';

function formatDate(date: string) {
  const locale = 'pt-BR';
  const d = new Date(date);

  if (!isValidDate(d)) {
    return '';
  }

  const ye = new Intl.DateTimeFormat(locale, { year: 'numeric' }).format(d);
  const mo = toTitleCase(
    new Intl.DateTimeFormat(locale, { month: 'long' }).format(d),
  );
  const da = new Intl.DateTimeFormat(locale, { day: 'numeric' }).format(d);
  const ho = withLeadingZeroes(
    new Intl.DateTimeFormat(locale, {
      hour: '2-digit',
      hour12: false,
    }).format(d),
  );
  const mi = withLeadingZeroes(
    new Intl.DateTimeFormat(locale, { minute: '2-digit' }).format(d),
  );

  return `${mo} ${da}, ${ye} - ${ho}:${mi}`;
}

function isDateNearNow(date: string) {
  const nextBookingDate = new Date(date);
  nextBookingDate.setMinutes(nextBookingDate.getMinutes() - 5);

  const now = new Date();

  return now >= nextBookingDate;
}

// returns true if the current time is in the forbidden 4 hours before the session date
function isDateWithinForbidden(date: string) {
  const nextBookingDate = new Date(date);
  nextBookingDate.setHours(nextBookingDate.getHours() - 4);

  const now = new Date();

  return now >= nextBookingDate;
}

// isDateBeforeDays returns true if a given date is less than <day> days from now.
function isDateBeforeDays(date: string, day = 1) {
  const nextBookingDate = new Date(date);
  nextBookingDate.setDate(nextBookingDate.getDate() - day);

  const now = new Date();

  return now < nextBookingDate;
}

function isDateInFuture(date: string) {
  const nextBookingDate = new Date(date);
  const now = new Date();

  return nextBookingDate > now;
}

function getNearTime(date: string) {
  const nextBookingDate = new Date(date);
  nextBookingDate.setMinutes(nextBookingDate.getMinutes() - 5);

  const now = new Date();

  return nextBookingDate.getTime() - now.getTime();
}

function toTitleCase(str: string) {
  // eslint-disable-next-line func-names -- day zero of no errors, remove and fix me when you see me in a code review
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function withLeadingZeroes(str: string) {
  return `0${str}`.slice(-2);
}

function isValidDate(d: Date) {
  // eslint-disable-next-line no-restricted-globals
  return d instanceof Date && !isNaN(d.getTime());
}

function birthDateToDate(date: string) {
  if (date) {
    const parts = date.split('/');
    if (parts.length === 3)
      return new Date(
        parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10),
      );
  }
}

function formatDateNextSession(date: string) {
  const dateLocal = new Date(date);
  const locale = window.navigator.language;

  if (!isValidDate(dateLocal)) {
    return date;
  }
  const dateFormatted = new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(dateLocal);
  const timeFormatted = new Intl.DateTimeFormat(locale, {
    hour: '2-digit',
    hour12: false,
    minute: '2-digit',
  }).format(dateLocal);
  return `${dateFormatted} - ${timeFormatted}`;
}

function formatDateNextSlotAvailability(date: string) {
  const dateLocal = new Date(date);
  const locale = window.navigator.language;

  if (!isValidDate(dateLocal)) {
    return date;
  }

  const dateFormatted = new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: '2-digit',
  }).format(dateLocal);
  return `${dateFormatted}`;
}

// Format date to ISO8601: e.g. 2021-09-01T00:00:00.000Z
const formatDateISO8601 = (inputDate: string, locale: Locale) => {
  const date = new Date(inputDate);
  if (Number.isNaN(date.getTime())) {
    return { day: '', month: '' };
  }
  return {
    day: format(date, 'dd', { locale }),
    month: format(date, 'MMMM', { locale }),
  };
};

export {
  birthDateToDate,
  formatDate,
  formatDateNextSession,
  formatDateNextSlotAvailability,
  isDateNearNow,
  isDateWithinForbidden,
  isDateBeforeDays,
  isDateInFuture,
  getNearTime,
  formatDateISO8601,
};
