import process from 'node:process';
import { createLogger, format, Logger, transports } from 'winston';

import config from './config';

const { combine, timestamp, json, prettyPrint } = format;

let loggerInstance: Logger | null = null;

// we are using node process because this method is not available in the browser so we need use the node process
// https://stackoverflow.com/questions/56116521/how-to-fix-process-nexttick-is-not-a-function-with-calgolia-places-js
// https://nodejs.org/api/process.html
window.process = {
  ...window.process,
  on: process.on,
  removeAllListeners: process.removeAllListeners,
  nextTick: process.nextTick,
};

export const getLogger = () => {
  const { nodeEnv } = config();
  if (loggerInstance) return loggerInstance;

  const formatters = [json(), timestamp()];

  if (nodeEnv !== 'production') {
    formatters.push(prettyPrint({ colorize: true }));
  }

  loggerInstance = createLogger({
    level: 'error',
    exitOnError: false,
    format: combine(...formatters),
    transports: [new transports.Console()],
    exceptionHandlers: [new transports.Console()],
  });

  return loggerInstance;
};

export default getLogger();
