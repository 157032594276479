import styled from 'styled-components';

import { Button, Hide, theme } from '@gympass/yoga';

export const Menu = styled(Hide)`
  display: flex;
  align-items: center;
  width: 100%;
`;

interface MenuLinkProps {
  active: boolean;
}

export const MenuLink = styled(Button.Link)<MenuLinkProps>`
  color: ${props =>
    props.active ? 'var(--primary)' : 'var(--text-secondary)'};
  margin: 0;
  font-weight: ${props =>
    props.active ? theme.fontWeights.medium : theme.fontWeights.regular};
  font-size: ${theme.fontSizes.medium}px;
  padding-right: ${theme.spacing.xlarge}px;
  padding-top: ${theme.spacing.xxxsmall}px;
  position: relative;
  text-decoration: none;

  :active,
  :not([disabled]):hover,
  :not([disabled]):focus {
    color: var(--primary-light);
  }
`;

export const BadgeContent = styled.div`
  position: relative;
`;

export const BadgeNew = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.zero} ${theme.spacing.xxsmall}px;
  background-color: var(--secondary);
  border-radius: ${theme.radii.regular}px;
  font-size: ${theme.fontSizes.xxsmall}px;
  font-weight: bold;
  margin-left: ${theme.spacing.xxxsmall}px;
  align-self: flex-start;
  margin-top: -${theme.spacing.xxxsmall}px;
  text-transform: uppercase;
  color: ${theme.colors.white};
`;
