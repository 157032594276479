import { KINDs } from 'components/DailyTaskCard/constants';
import { Kind } from 'components/DailyTaskCard/types';

export function firstWord(str?: string) {
  return str ? str.split(' ')[0] : '';
}

export function capitalize(str?: string) {
  // eslint-disable-next-line func-names
  return str?.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
}
/** @internal */
export const isTrueSet = (value: string | null | undefined) => value === 'true';

export const getContentIntlId = (type: Kind) => {
  if (!type) return;
  if (type in KINDs) return KINDs[type];
  // eslint-disable-next-line no-console
  console.error(`Unknown kind ${type}`, new Error(type));

  return 'CONTENTINFO.LABEL.ARTICLE';
};
