import styled from 'styled-components';

import { Text, theme } from '@gympass/yoga';
import { FormControl, FormGroup } from '@mui/material';
import media from 'theme/media';

export const BoxItem = styled.div`
  border: ${theme.borders.small}px solid var(--btn-disabled-color);
  border-radius: 8px;
  margin-bottom: ${theme.spacing.xxsmall}px;
  padding: ${theme.spacing.xxxsmall}px ${theme.spacing.small}px;
`;

export const Title = styled(Text.Small)`
  color: var(--text-secondary);
  margin-bottom: ${theme.spacing.xxsmall}px;
`;

export const Container = styled.div`
  height: 100%;
  overflow: hidden;
`;

export const FormControlCustom = styled(FormControl)`
  height: 100%;
  overflow: hidden;

  ${media.desktop`
    max-height: 464px;
  `}
`;

export const FormGroupCustom = styled(FormGroup)`
  overflow-y: scroll;
  margin-right: ${theme.spacing.xxxsmall}px;
  padding-right: ${theme.spacing.xxsmall}px;
  margin-top: ${theme.spacing.small}px;

  ::-webkit-scrollbar {
    width: ${theme.spacing.xxxsmall}px;
    height: ${theme.spacing.xxxsmall}px;
    background-color: ${theme.colors.white};
  }

  scrollbar-width: thin;

  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: ${theme.radii.small}px;
  }
`;

export const WrapperItens = styled.div<{
  isChangeRule: boolean;
  hasBanner: boolean;
}>`
  width: 100%;
  margin-bottom: ${props =>
    props.hasBanner ? theme.spacing.xhuge : theme.spacing.xlarge}px;
  ${({ isChangeRule }) =>
    isChangeRule &&
    media.desktop`
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: ${theme.spacing.zero} ${theme.spacing.xlarge}px;
  `}

  &:last-child {
    padding-bottom: ${theme.spacing.xxxlarge}px;
  }
`;
