import { ReactNode } from 'react';

import { theme } from '@gympass/yoga';

export interface NavbarContainerProps {
  navBarStyle: NavbarStyleKey;
}

export interface NavbarProps {
  navBarStyle: NavbarStyleKey;
  children: ReactNode;
}

export const NavbarStyles = {
  default: {
    background: theme.colors.white,
  },
  progress: {
    background: theme.colors.deepPurple,
  },
};

export type NavbarStyleKey = keyof typeof NavbarStyles;
