import { CheckedFull, CheckedHalf } from '@gympass/yoga-icons';

import { ProgramStatusEnum } from './types';

export const PROGRAM_STATUS_DATA = {
  [ProgramStatusEnum.COMPLETED]: {
    background: 'var(--success-light)',
    icon: CheckedFull,
    text: 'LIBRARY.PROGRAM.STATUS.COMPLETED',
  },
  [ProgramStatusEnum.INITIALIZED]: {
    background: 'var(--secondary-peach)',
    icon: CheckedHalf,
    text: 'LIBRARY.PROGRAM.STATUS.INITIALIZED',
  },
};
