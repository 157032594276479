import React, { lazy, Suspense } from 'react';

import { RouteProps } from 'react-router';
import { Redirect } from 'react-router-dom';

import { Loading } from './components';

const AuthenticatedHome = lazy(() => import('pages/Home'));
const Assessment = lazy(() => import('pages/Assessment'));
const AssessmentIntroduction = lazy(
  () => import('pages/AssessmentIntroduction'),
);
const AssessmentResults = lazy(() => import('pages/AssessmentResults'));
const CareProfileResults = lazy(() => import('pages/CareProfileResults'));
const CorporateJourney = lazy(() => import('pages/CorporateJourney'));
const EligibilityError = lazy(() => import('pages/EligibilityError'));
const Explore = lazy(() => import('pages/Explore'));
const FaqAndSupport = lazy(() => import('pages/FaqAndSupport'));
const LandingPagePromotionApp = lazy(
  () => import('pages/LandingPagePromotionApp'),
);
const MatchSurvey = lazy(() => import('pages/MatchSurvey'));
const Onboarding = lazy(() => import('pages/Onboarding'));
const OnboardingProcessing = lazy(() => import('pages/OnboardingProcessing'));
const PollingManager = lazy(() => import('pages/PollingManager'));
const StreamWithoutAccessibility = lazy(
  () => import('pages/StreamWithoutAccessibility'),
);
const UnderstandAssessmentResults = lazy(
  () => import('pages/UnderstandAssessmentResults'),
);
const UpdateNationalId = lazy(() => import('pages/UpdateNationalId'));
const UpdatePhoneNumber = lazy(() => import('pages/UpdatePhoneNumber'));
const VitalkPartner = lazy(() => import('pages/VitalkPartner'));

const AccessibleJourney = lazy(() => import('pages/AccessibleJourney'));
const BookingSuccess = lazy(() => import('pages/BookingSuccess'));
const ChangePassword = lazy(() => import('pages/ChangePassword'));
const Chat = lazy(() => import('pages/Chat'));
const ChooseSpecialist = lazy(() => import('pages/ChooseSpecialist'));
const ConfirmAccount = lazy(() => import('pages/ConfirmAccount'));
const EligibilityWarning = lazy(() => import('pages/EligibilityWarning'));
const FeedbackCancel = lazy(() => import('pages/FeedbackCancel'));
const Journey = lazy(() => import('pages/Journey'));
const JourneySelection = lazy(() => import('pages/JourneySelection'));
const LibraryHistory = lazy(() => import('pages/LibraryHistory'));
const PageNotFound = lazy(() => import('pages/NotFound'));
const PageContent = lazy(() => import('pages/PageContent'));
const PatientIntake = lazy(() => import('pages/PatientIntake'));
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy'));
const Profile = lazy(() => import('pages/Profile'));
const Program = lazy(() => import('pages/Program'));
const Questionnaire = lazy(() => import('pages/Questionnaire'));
const QuestionnaireProcessing = lazy(
  () => import('pages/QuestionnaireProcessing'),
);
const Sessions = lazy(() => import('pages/Sessions'));
const SignOut = lazy(() => import('pages/SignOut'));
const SpecialistCalendar = lazy(() => import('pages/SpecialistCalendar'));
const TermsConditions = lazy(() => import('pages/TermsConditions'));
const TermsPayrollDeduction = lazy(() => import('pages/TermsPayrollDeduction'));
const UpdateUser = lazy(() => import('pages/UpdateUser'));
const SubscriptionManager = lazy(() => import('pages/SubscriptionManager'));
const Subscription = lazy(() => import('pages/Subscription'));
const Offer = lazy(() => import('pages/Offer'));
const RescueMe = lazy(() => import('pages/RescueMe'));
const SuccessPage = lazy(() => import('pages/SuccessPage'));

export interface Route extends RouteProps {
  withTabsMenu: boolean;
}

export const routes: Route[] = [
  {
    exact: true,
    path: '/',
    render: () => <Redirect to="/home" />,
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/home',
    render: () => (
      <Suspense fallback={<Loading />}>
        <AuthenticatedHome />
      </Suspense>
    ),
    withTabsMenu: true,
  },
  {
    exact: true,
    path: '/logout',
    render: () => (
      <Suspense fallback={<Loading />}>
        <SignOut />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/my-profile',
    render: () => (
      <Suspense fallback={<Loading />}>
        <Profile />
      </Suspense>
    ),
    withTabsMenu: true,
  },
  {
    exact: true,
    path: '/update-profile',
    render: () => (
      <Suspense fallback={<Loading />}>
        <UpdateUser />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/update-nationalid',
    render: () => (
      <Suspense fallback={<Loading />}>
        <UpdateNationalId />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/eligiblity-error',
    render: () => (
      <Suspense fallback={<Loading />}>
        <EligibilityError />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/change-password',
    render: () => (
      <Suspense fallback={<Loading />}>
        <ChangePassword />
      </Suspense>
    ),
    withTabsMenu: true,
  },
  {
    exact: true,
    path: '/onboarding/processing',
    render: () => (
      <Suspense fallback={<Loading />}>
        <OnboardingProcessing />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/care-profile/results',
    render: () => (
      <Suspense fallback={<Loading />}>
        <CareProfileResults />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/onboarding',
    render: () => (
      <Suspense fallback={<Loading />}>
        <Onboarding />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/journey-selection',
    render: () => (
      <Suspense fallback={<Loading />}>
        <JourneySelection />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/questionnaire',
    render: () => (
      <Suspense fallback={<Loading />}>
        <Questionnaire />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/questionnaire-processing',
    render: () => (
      <Suspense fallback={<Loading />}>
        <QuestionnaireProcessing />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/sessions',
    render: () => (
      <Suspense fallback={<Loading />}>
        <Sessions />
      </Suspense>
    ),
    withTabsMenu: true,
  },
  {
    exact: true,
    path: '/choose-specialist',
    render: () => (
      <Suspense fallback={<Loading />}>
        <ChooseSpecialist />
      </Suspense>
    ),
    withTabsMenu: true,
  },
  {
    exact: true,
    path: '/choose-specialist/:id',
    render: () => (
      <Suspense fallback={<Loading />}>
        <SpecialistCalendar />
      </Suspense>
    ),
    withTabsMenu: true,
  },
  {
    exact: true,
    path: '/offer',
    render: () => (
      <Suspense fallback={<Loading />}>
        <Offer />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/booking-success',
    render: () => (
      <Suspense fallback={<Loading />}>
        <BookingSuccess />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/pages/:pageId',
    render: () => (
      <Suspense fallback={<Loading />}>
        <PageContent />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/patient-intake',
    render: () => (
      <Suspense fallback={<Loading />}>
        <PatientIntake />
      </Suspense>
    ),
    withTabsMenu: true,
  },
  {
    exact: true,
    path: '/confirm-account',
    render: () => (
      <Suspense fallback={<Loading />}>
        <ConfirmAccount />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/eligibility-warning',
    render: () => (
      <Suspense fallback={<Loading />}>
        <EligibilityWarning />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/feedback-cancel',
    render: () => (
      <Suspense fallback={<Loading />}>
        <FeedbackCancel />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/terms-payroll-deduction',
    render: () => (
      <Suspense fallback={<Loading />}>
        <TermsPayrollDeduction />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/terms-conditions',
    render: () => (
      <Suspense fallback={<Loading />}>
        <TermsConditions />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/privacy-policy',
    render: () => (
      <Suspense fallback={<Loading />}>
        <PrivacyPolicy />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/chat',
    render: () => (
      <Suspense fallback={<Loading />}>
        <Chat />
      </Suspense>
    ),
    withTabsMenu: true,
  },
  {
    exact: true,
    path: '/journey',
    render: () => (
      <Suspense fallback={<Loading />}>
        <Journey />
      </Suspense>
    ),
    withTabsMenu: true,
  },
  {
    exact: true,
    path: '/library-history',
    render: () => (
      <Suspense fallback={<Loading />}>
        <LibraryHistory />
      </Suspense>
    ),
    withTabsMenu: true,
  },
  {
    exact: true,
    path: '/program/:id',
    render: () => (
      <Suspense fallback={<Loading />}>
        <Program />
      </Suspense>
    ),
    withTabsMenu: true,
  },
  {
    exact: true,
    path: '/vitalk-partner',
    render: () => (
      <Suspense fallback={<Loading />}>
        <VitalkPartner />
      </Suspense>
    ),
    withTabsMenu: true,
  },
  {
    exact: true,
    path: '/wellz-app-landing',
    render: () => (
      <Suspense fallback={<Loading />}>
        <LandingPagePromotionApp />
      </Suspense>
    ),
    withTabsMenu: true,
  },
  {
    exact: false, // we can have `/explore/music` and `/explore/meditation`, see https://gympass.atlassian.net/browse/CUP-1898
    path: '/explore',
    render: () => (
      <Suspense fallback={<Loading />}>
        <Explore />
      </Suspense>
    ),
    withTabsMenu: true,
  },
  {
    exact: true,
    path: '/corporate-journey/:journey',
    render: () => (
      <Suspense fallback={<Loading />}>
        <CorporateJourney />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/assessment',
    render: () => (
      <Suspense fallback={<Loading />}>
        <Assessment />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/assessment/results',
    render: () => (
      <Suspense fallback={<Loading />}>
        <AssessmentResults />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/understand-assessment-results',
    render: () => (
      <Suspense fallback={<Loading />}>
        <UnderstandAssessmentResults />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/assessment/introduction',
    render: () => (
      <Suspense fallback={<Loading />}>
        <AssessmentIntroduction />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    path: '/contact',
    component: () => {
      window.location.href = 'https://wellz.zendesk.com/hc/pt-br/requests/new';
      return null;
    },
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/faq-support',
    render: () => (
      <Suspense fallback={<Loading />}>
        <FaqAndSupport />
      </Suspense>
    ),
    withTabsMenu: true,
  },
  {
    exact: true,
    path: '/subscription/:step',
    render: () => (
      <Suspense fallback={<Loading />}>
        <Subscription />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/subscription-manager/:step',
    render: () => (
      <Suspense fallback={<Loading />}>
        <SubscriptionManager />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    path: '/accessible-journey',
    render: () => (
      <Suspense fallback={<Loading />}>
        <AccessibleJourney />
      </Suspense>
    ),
    withTabsMenu: true,
  },
  {
    exact: true,
    path: '/rescue-me',
    render: () => (
      <Suspense fallback={<Loading />}>
        <RescueMe />
      </Suspense>
    ),
    withTabsMenu: true,
  },
  {
    path: '/stream-without-accessibility',
    render: () => (
      <Suspense fallback={<Loading />}>
        <StreamWithoutAccessibility />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    path: '/polling-manager',
    render: () => (
      <Suspense fallback={<Loading />}>
        <PollingManager />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    path: '/match-survey',
    render: () => (
      <Suspense fallback={<Loading />}>
        <MatchSurvey />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    path: '/update-phone-number',
    render: () => (
      <Suspense fallback={<Loading />}>
        <UpdatePhoneNumber />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    path: '/success-pages/:page',
    render: () => (
      <Suspense fallback={<Loading />}>
        <SuccessPage />
      </Suspense>
    ),
    withTabsMenu: false,
  },
  {
    exact: true,
    render: () => (
      <Suspense fallback={<Loading />}>
        <PageNotFound />
      </Suspense>
    ),
    withTabsMenu: false,
  },
].filter(Boolean);
