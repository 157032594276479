import isWebview from './isWebview';
import triggerWebviewEvent from './triggerWebviewEvent';

function logoutUser() {
  const isFromWebview = isWebview();

  if (isFromWebview) {
    triggerWebviewEvent('logout', null);
  }
  localStorage.clear();
  window.location.href = '/logout';
}

export default logoutUser;
