import styled from 'styled-components';

import media from '../../theme/media';

interface ActionsProps {
  light?: boolean;
}

export const Actions = styled.div<ActionsProps>`
  background-color: ${({ light }) => light && 'var(--background-light)'};
  text-align: center;
  border-radius: 16px;
  width: 100%;
  padding: 20px;

  ${media.desktop`
    display: flex;
    max-width: 442px;
  `}
`;
