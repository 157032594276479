import styled from 'styled-components';

import { theme, Text, Box } from '@gympass/yoga';
import media from 'theme/media';

import { Button } from 'components/ButtonLegacy';

export const QrCodeBackdropText = styled(Text.Tiny).attrs({
  color: 'inherit',
})`
  margin-top: ${theme.spacing.xxsmall}px;
  text-align: center;
  font-weight: ${theme.fontWeights.bold};
  white-space: pre-line;
`;

export const QrCodeBackdropImage = styled.svg`
  width: ${theme.spacing.xlarge}px;
  height: ${theme.spacing.xlarge}px;
  fill: currentColor;
`;

export const QrCodeBackdrop = styled(Box).attrs({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
})`
  inset: ${theme.spacing.zero};
`;

export const QrCodeButton = styled(Button)`
  margin: ${theme.spacing.zero} auto;

  ${media.desktop`
    margin: initial;
  `}
`;

export const QrCodeRoot = styled(Box).attrs(props => ({
  display: 'inherit',
  as: 'button',
  ...(!(props.$loading || props.$error) && { 'aria-hidden': 'true' }),
}))`
  color: var(${props => (props.$error ? '--error' : '--text-disabled')});
  background-color: ${theme.colors.white};
  border-radius: ${theme.radii.small}px;
  border: ${theme.borders.small}px solid currentColor;
  padding: ${theme.spacing.xxsmall}px;
  margin-left: ${theme.spacing.small}px;
  position: relative;
  overflow: hidden;
  transition: color ${theme.transition.duration[0]}ms ease-in-out;

  & > ${QrCodeBackdrop} {
    transition: background-color ${theme.transition.duration[0]}ms ease-in-out;
    background-color: rgb(
      255 255 255 / ${props => (props.$loading || props.$error ? '92%' : '0%')}
    );

    & > ${Box} {
      transition: opacity ${theme.transition.duration[0]}ms ease-in-out;
      opacity: ${props => (props.$loading || props.$error ? 1 : 0)};
    }
  }
`;
