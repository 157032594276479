import { HttpClient } from '@crowdin/ota-client';
import axios, { AxiosInstance } from 'axios';

export class CustomHttpClient implements HttpClient {
  private axios: AxiosInstance = axios.create({
    timeout: 1000,
  });

  async get<T>(url: string): Promise<T> {
    return (await this.axios.get(url)).data;
  }
}

/** @alias */ // TODO: day zero adding knip to this project, remove me
export default CustomHttpClient;
