import styled from 'styled-components';

import { theme, Button, Text } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';

export const BannerContent = styled.div`
  width: 100%;
  max-width: var(--content-page-width);
  max-height: 118px;
  background-color: ${theme.colors.white};
  box-shadow: 0px 4px 12px rgba(152, 152, 166, 0.25);
  border-radius: ${theme.radii.small}px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  padding: 0 ${theme.spacing.medium}px;
  overflow: hidden;
  ${media.not.md`
    position: relative;
    width: 320px;
    height: 264px;
    max-height: 264px;
    flex-direction: column;
    padding: ${theme.spacing.medium}px;
    justify-content: space-between;
  `}
`;

export const BannerDivText = styled.div`
  max-width: 427px;
  padding: ${theme.spacing.large}px ${theme.spacing.medium}px;
  ${media.not.md`
    padding: 0px;
    position: relative;
    z-index: 1;
  `}
`;

export const Img = styled.img`
  width: 122px;
  ${media.not.md`
    width: 110px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 0;
  `}
`;

export const BannerTitle = styled(Text.SectionTitle)`
  color: ${theme.colors.text.secondary};
`;

export const BannerText = styled(Text.Regular)`
  margin-top: ${theme.spacing.xxsmall}px;
`;

export const BannerButton = styled(Button)`
  border-radius: ${theme.radii.small}px;
  height: ${theme.spacing.xlarge}px;
  font-size: ${theme.fontSizes.small}px;
  white-space: nowrap;
  padding: ${theme.spacing.xxsmall}px ${theme.spacing.small}px;
  z-index: 1;
  ${media.max('sm')`
    width: 100%;
  `}
`;
