import React, { useContext, useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { NotificationContext } from '../../context/NotificationContext';
import useVerifyMobile from '../../hooks/useVerifyMobile';
import isWebview from '../../utils/isWebview';
import * as S from './style';

const TabsMenu = () => {
  const { hasNotification } = useContext(NotificationContext);
  const [activeItem, setActiveItem] = useState('');
  const history = useHistory();
  const [isMobile] = useVerifyMobile();
  const isFromWebview = isWebview();

  const handleMenuItemClick = (item: string) => {
    history.push(`/${item}`);
    setActiveItem(item);
  };

  useEffect(() => {
    const startingPath = window.location.pathname.slice(1);

    setActiveItem(startingPath);
  }, [activeItem]);

  if (!isMobile || isFromWebview) return null;

  return (
    <S.Container className="tabsmenu-container">
      <ul>
        <li>
          <S.TabItem
            data-testid="tabsmenu-home-tab"
            className={activeItem === 'home' ? 'active' : ''}
            onClick={() => handleMenuItemClick('home')}
          >
            <S.Icon
              id="btn-home"
              className={
                activeItem === 'home' ? 'icon-home-filled' : 'icon-home'
              }
              active={activeItem === 'home'}
            />
            <S.Label active={activeItem === 'home'}>
              <FormattedMessage id="HEADER.HOME" />
            </S.Label>
          </S.TabItem>
        </li>
        <li>
          <S.TabItem
            data-testid="tabsmenu-sessions-tab"
            onClick={() => handleMenuItemClick('sessions')}
            className={activeItem === 'sessions' ? 'active' : ''}
          >
            <S.Icon
              id="btn-sessions"
              className={
                activeItem === 'sessions'
                  ? 'icon-booking-filled'
                  : 'icon-booking'
              }
              active={activeItem === 'sessions'}
            />
            <S.Label active={activeItem === 'sessions'}>
              <FormattedMessage id="HEADER.SESSIONS" />
            </S.Label>
          </S.TabItem>
        </li>
        <li>
          <S.TabItem
            data-testid="tabsmenu-journey-tab"
            onClick={() => handleMenuItemClick('journey')}
            className={activeItem === 'journey' ? 'active' : ''}
          >
            <S.Icon
              id="btn-journey"
              className={
                activeItem === 'journey'
                  ? 'icon-journey-filled'
                  : 'icon-journey'
              }
              active={activeItem === 'journey'}
            />
            <S.Label active={activeItem === 'journey'}>
              <FormattedMessage id="HEADER.JOURNEY" />
            </S.Label>
          </S.TabItem>
        </li>
        <li>
          <S.TabItem
            data-testid="tabsmenu-chat-tab"
            onClick={() => handleMenuItemClick('chat')}
            className={activeItem === 'chat' ? 'active' : ''}
          >
            <S.IconWrapper>
              <S.Icon
                id="btn-chat"
                className={
                  activeItem === 'chat' ? 'icon-chat-filled' : 'icon-chat'
                }
                active={activeItem === 'chat'}
              />
              {Boolean(hasNotification) && <S.StyledBadge />}
            </S.IconWrapper>
            <S.Label active={activeItem === 'chat'}>
              <FormattedMessage id="HEADER.CHAT" />
            </S.Label>
          </S.TabItem>
        </li>
        <li>
          <S.TabItem
            data-testid="tabsmenu-explore-tab"
            onClick={() => handleMenuItemClick('explore')}
            className={activeItem === 'explore' ? 'active' : ''}
          >
            <S.IconWrapper>
              <img
                src={
                  activeItem === 'explore'
                    ? 'assets/icon/compass-filled.svg'
                    : 'assets/icon/compass.svg'
                }
                alt="compass"
              />
            </S.IconWrapper>
            <S.Label active={activeItem === 'explore'}>
              <FormattedMessage id="HEADER.EXPLORE" />
            </S.Label>
          </S.TabItem>
        </li>
      </ul>
    </S.Container>
  );
};

export default TabsMenu;
