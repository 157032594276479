import styled, { css } from 'styled-components';

import { Card, Hide, Text, theme } from '@gympass/yoga';
import media from 'theme/media';

import { Button } from 'components';

export const sharedStyledLink = css`
  width: 100%;
  margin-top: ${theme.spacing.small}px;

  ${media.desktop`
    margin-bottom: ${theme.spacing.zero}px;
  `}
`;

/** @knipignore */ // TODO: day zero adding knip to this project, remove me
export const SessionsCount = styled(Text.Small)`
  color: var(--text-secondary);
  margin-top: 0;

  strong {
    font-weight: ${theme.fontWeights.medium};
  }

  ${media.desktop`
    position: absolute;
    right: 0;
    top: 0;
    font-size: ${theme.fontSizes.xsmall}px;
    line-height: ${theme.lineHeights.xsmall}px;
  `}
`;

export const SessionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  ${media.desktop`
    flex-direction: revert;
    justify-content: space-between;
    align-items: center;
  `}
`;

export const SessionTitle = styled(Text.SectionTitle)`
  color: ${theme.colors.text.secondary};
  padding-bottom: ${theme.spacing.xsmall}px;
`;

export const SessionMetadata = styled.div`
  display: flex;
  flex-direction: row;
`;

interface CardProps {
  clickable: boolean;
}

export const SessionCard = styled(Card)<CardProps>`
  position: relative;
  max-width: var(--content-page-width);
  max-height: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${theme.spacing.medium}px;
  cursor: ${props => props.clickable && 'pointer'};
  ${media.mobile`
    width: 320px;
    height: 264px;
    max-height: 264px;
    flex-direction: column;
    padding: ${theme.spacing.xlarge}px
      ${theme.spacing.small}px
      ${theme.spacing.xlarge}px
      ${theme.spacing.large}px;
  `}

  &::before {
    content: '';
    width: ${theme.spacing.xxsmall}px;
    height: 100%;
    background-color: var(--primary-light);
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: ${theme.radii.regular}px;
    border-bottom-left-radius: ${theme.radii.regular}px;
  }
`;

export const SessionDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${theme.spacing.xxxsmall}px;
`;

export const ButtonsContainer = styled.div`
  flex-direction: column-reverse;
  ${media.mobile`
    display: flex;
  `}
`;

export const SessionButton = styled(Button)`
  width: 100%;
  padding: ${theme.spacing.xxsmall}px ${theme.spacing.small}px;
  height: 32px;
  font-size: ${theme.fontSizes.small}px;
  border-radius: ${theme.radii.small}px;
`;

export const TitleText = styled(Text.Medium)`
  color: var(--text-primary);
  margin-bottom: ${theme.spacing.xxsmall}px;
`;

export const DateText = styled(Text.Regular)``;

/** @knipignore */ // TODO: day zero adding knip to this project, remove me
export const ButtonContainer = styled(Hide)`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;
