import styled from 'styled-components';

import { Text, theme, Button as ButtonYoga } from '@gympass/yoga';
import { media } from '@gympass/yoga-helpers';

import Link from '../Link';

export const Container = styled.ul<{
  isCard?: boolean;
}>`
  list-style: none outside none;
  margin-block: 0;
  padding-inline-start: 0;
  background-color: ${({ isCard }) => isCard && theme.colors.white};
`;

export const Activity = styled.li`
  display: flex;
  width: 100%;
  :nth-child(odd) {
    background-color: var(--whisper);
  }
`;

export const ActivityLink = styled(Link)`
  width: 100%;
  margin: ${theme.spacing.zero};
  display: flex;
  align-items: left;
  justify-content: flex-start;

  ${media.not.md`
    align-items: flex-start;
  `};
`;

export const ActivityContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: ${theme.spacing.xxsmall}px;
  flex-wrap: nowrap;
  outline: none;
  padding: ${theme.spacing.medium}px;

  ${media.not.md`
    padding: ${theme.spacing.small}px;
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.spacing.xxsmall}px;
  `};
`;

export const ActivityTitle = styled(Text.Small).attrs({
  numberOfLines: 1,
})`
  text-align: left;
  font-weight: ${theme.fontWeights.medium};
  color: ${theme.colors.primary};

  ${media.not.md`
    -webkit-line-clamp: unset;
  `};
`;

export const ActivityContentRight = styled.div`
  display: flex;
  justify-content: start;
  gap: ${theme.spacing.xxsmall}px;
  align-items: center;
  flex-wrap: nowrap;

  ${media.not.md`
    flex-wrap: wrap;
    width: auto;
    align-items: center;
  `};

  ${media.not.sm`
    flex-wrap: wrap;
  `};
  ${media.max('md')`
    width: 300px;
    justify-content: flex-start;
  `}
`;

export const RightItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: ${theme.spacing.xxxsmall}px;
`;

export const LeftIcon = styled(RightItem)`
  ${media.max('md')`
    display: none;
  `}
`;

export const RightItemCheck = styled(RightItem)`
  display: none;
  ${media.max('md')`
    display: flex;
  `}
`;

export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: no-wrap;
`;

export const Button = styled(ButtonYoga).attrs({
  small: true,
  inverted: true,
})`
  background-color: transparent;
  border: ${theme.borders.small}px solid ${theme.colors.primary};

  p {
    color: ${theme.colors.primary};
    font-weight: ${theme.fontWeights.medium};
  }
`;

/** @knipignore */ // TODO: day zero adding knip to this project, remove me
export const SuccessText = styled(Text.Tiny)`
  font-weight: ${theme.fontWeights.medium};
  color: var(--success);
`;

export const TypeText = styled(Text.Tiny)`
  font-weight: ${theme.fontWeights.regular};
  color: var(--text-disabled);
`;

export const Icon = styled.img`
  height: auto;
  max-width: ${theme.spacing.small}px;
`;

export const TypeIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;

  svg {
    margin-right: ${theme.spacing.xxxsmall}px;
    width: ${theme.fontSizes.medium}px;
    height: ${theme.fontSizes.medium}px;
    fill: #6b6b78;
  }
`;

export const BreakRow = styled.div`
  flex-basis: 100%;
  height: 0;
`;
