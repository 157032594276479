import styled from 'styled-components';

import { Button, Text, theme } from '@gympass/yoga';
import media from 'theme/media';

import { SimpleBannerCardProps } from './types';

export const Container = styled.div<SimpleBannerCardProps>`
  min-height: 92px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${theme.spacing.medium}px;
  justify-content: ${props =>
    !!props.icon && !!props.actionCard ? 'space-between' : 'initial'};

  background: var(--background);
  box-shadow: ${theme.spacing.zero} ${theme.spacing.xxxsmall}px
    ${theme.spacing.xsmall}px rgba(152, 152, 166, 0.25);
  border-radius: ${theme.radii.small}px;
  gap: ${theme.spacing.large}px;

  ${media.mobile`
    flex-direction: column;
    justify-content: initial;
    align-items: initial;
    gap: ${theme.spacing.small}px;
  `}
`;

export const IconContainer = styled.div`
  display: flex;
  padding: ${theme.spacing.zero} ${theme.spacing.medium}px;
`;

export const Icon = styled.img`
  margin: ${theme.spacing.zero};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Text.Tiny)`
  text-transform: uppercase;
  color: var(--text-secondary);
`;

export const Description = styled(Text.Small)`
  margin: ${theme.spacing.zero};
`;

export const CardButton = styled(Button)`
  border-radius: ${theme.radii.small}px;
  height: ${theme.spacing.xlarge}px;
  font-size: ${theme.fontSizes.small}px;
  padding: ${theme.spacing.xxsmall}px ${theme.spacing.small}px;
`;
