import styled from 'styled-components';

import { theme, Text } from '@gympass/yoga';

import { PROGRAM_STATUS_DATA } from './constants';

import { ProgramStatusEnum } from './types';

export const ProgramStatusContainer = styled.div`
  display: flex;
`;

export const ProgramStatus = styled.div<{ status: ProgramStatusEnum }>`
  align-items: center;
  border-radius: ${theme.radii.xsmall}px;
  display: flex;
  height: 24px;
  padding: ${theme.spacing.xxxsmall}px ${theme.spacing.xxsmall}px;
  color: var(--primary);
  background-color: ${({ status }) => PROGRAM_STATUS_DATA[status].background};

  svg {
    width: 16px;
    height: 16px;

    fill: var(--primary);
  }
`;

export const ProgramStatusText = styled(Text.Tiny)`
  padding-left: ${theme.spacing.xxxsmall}px;
  color: ${theme.colors.primary};
  font-weight: ${theme.fontWeights.medium};
`;

/** @knipignore */ // TODO: day zero adding knip to this project, remove me
export const IconStatus = styled.img``;
